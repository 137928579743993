.login-body .login {
  display: grid;
  grid-template-columns: minmax(400px, 1fr) 3fr;
  height: 100vh;
}

.login-body .login--redirect {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-lighter);
}

.login__form-hold {
  background-color: var(--light-gray-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login--redirect .login__form-hold {
  background-color: var(--blue-lighter);
}

.login__form {
  width: 90%;
  max-width: 240px;
  text-align: center;
}

.login--redirect .login__form {
  width: 100%;
  max-width: 240px;
}

.login-redirect-msg {
  font-size: 20px;
  margin-bottom: 45px;
}

.login__video-hold {
  background-color: var(--blue-lighter);
}

.login__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
}

.login-body {
  margin-top: 0;
}

.login__form .btn-login {
  width: 100%;
  background: #fff;
  border-radius: 2px;
  color: var(--font-color);
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 8px 10px;
  margin-bottom: 1rem;
}

.login__form .btn-login:hover {
  color: var(--font-color);
  background-color: #abd6ec;
}

.login__form .btn-login span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
}

.login__form .btn-login span::before {
  content: " ";
  width: 15px;
  height: 15px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
  margin-top: -3px;
}

.login__form .btn-login-google span::before {
  background-image: url(../../../../../../../assets/img/provider-google-2.png);
}

.login__form .btn-login-microsoft span::before {
  background-image: url(../../../../../../../assets/img/provider-microsoft.png);
}

.login__line {
  display: block;
  position: relative;
  font-size: 18px;
}

.login--redirect .login__line span {
  background-color: var(--blue-lighter);
}

.login__line::before {
  content: ' ';
  display: block;
  width: 100%;
  height: 1px;
  background: var(--font-color);
  top: 50%;
  position: absolute;
}

.login__line span {
  background-color: var(--light-gray-bg);
  display: inline-block;
  padding: 0 15px;
  position: relative;
}

.login__form .login__logo {
  width: 240px;
  height: 47px;
  background: url(../../../../../../../assets/img/login-logo.png) no-repeat;
  background-size: contain;
  margin-bottom: 90px;
}

.login--redirect .login__form .login__logo {
  margin-bottom: 45px;
}

.login__form .login-link .control-group {
  padding-left: 0;
}

.login__form .login-link .btn,
.login__form .login-link-email {
  width: 100%;
}

.login__form .btn:focus {
  outline: none;
  box-shadow: none;
}

.login__form .btn:focus-visible {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.login__form .login-error-msg {
  color: var(--danger-color);
  font-size: 10px;
  text-align: left;
  margin-top: -10px;
  padding-bottom: 10px;
}

.login-link .btn[data-disabled="true"],
.login-link .btn[data-disabled="true"]:hover,
.login-link .btn[data-disabled="true"]:focus-visible,
.login-link .btn[data-disabled="true"]:active {
  opacity: 0.5;
  cursor: auto;
}

/* Overrides apply to mobile */
@media (max-width: 768px) {
  .login-body .login {
    display: block;
  }

  .login__video-hold {
    display: none;
  }

  .login__line span {
    background-color: var(--blue-lighter);
  }

  .login__form-hold {
    background-color: var(--blue-lighter);
    width: 100vw;
    height: 100vh;
  }
}
