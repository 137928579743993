.settings-area a:not(.btn-settings-area) {
  letter-spacing: 0;
  text-decoration: none;
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight-bold);
}

.admin-settings a {
  color: var(--primary-color);
}

.settings-area.admin-settings,
.admin-settings .tab-content {
  background-color: var(--light-gray-bg);
}

.settings-area li span {
  font-size: var(--font-size);
}

.settings-area,
.settings-area .row-inset {
  display: flex;
  background-color: #fff;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

.settings-area h3 {
  border: none;
  padding: 20px;
  margin: 0;
  font-size: var(--h3-size);
  font-weight: var(--font-weight-bold);
}

.settings-area h4 {
  border: none;
  font-size: 1em;
  font-weight: var(--font-weight-bold);
  margin: 20px;
}

.settings-area .top h4 {
  margin: 0;
}

.settings-area .settings-info {
  padding: 20px;
}

.settings-area .settings-title {
  border-bottom: 1px solid #eee;
}

.settings-area .settings-title .btn {
  text-transform: uppercase;
}

.settings-area .settings-title .settings-title-content .btn-group {
  margin-top: 15px;
  margin-right: 10px;
  float: right;
}

.settings-area .settings-content {
  min-height: 300px;
}

.settings-area .settings-content h3 {
  margin-top: 0;
}

.settings-area .settings-content > div {
  padding-top: 20px;
}

.settings-area .settings-content > div.settings-content-no-padding {
  padding-top: 0;
}

.settings-area .settings-content .links {
  margin: 0 0 40px 20px;
}

.settings-area .settings-content .links li {
  list-style-type: none;
  margin-bottom: 15px;
}

.settings-area .settings-content .links li a {
  color: var(--primary-color);
  font-weight: bold;
}

.settings-area .settings-sidebar {
  border-right: 1px solid var(--light-gray);
  background: #fff;
}

.settings-area .settings-fields {
  padding: 10px;
}

.settings-area .settings-fields .control-label {
  font-weight: 200;
}

.settings-area .settings-table {
  padding: 10px 20px;
}

.settings-area .settings-table .table th {
  padding-bottom: 30px;
  text-transform: uppercase;
}

.settings-area .settings-table .table td {
  border: none;
  vertical-align: middle;
}

.settings-area .settings-table .settings-value {
  min-height: 20px;
  padding: 7px;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.settings-area .setting-content-padded {
  padding-top: 20px;
}

.settings-area .description {
  font-size: 0.9em;
  color: var(--font-help-text);
  line-height: 1.5em;
}

.settings-area .description a {
  font-size: 1em;
  font-weight: var(--font-weight);
}

.settings-area .code {
  background-color: #eeeff2;
  border-radius: 3px;
  border: 1px #eeeff2 solid;
  display: inline-block;
  font-family: courier, serif;
  padding: 8px;
}

.settings-area .col-12.description,
.settings-area .span12.description {
  padding: 0 20px 20px 20px;
}

/* ========= receipt disbursement configuration ============= */

.form-receipt-disbursement-configuration .line-items {
  margin: 20px 0 0 20px !important;
  border: 1px #eee solid;
  border-radius: 5px;
}

.form-receipt-disbursement-configuration .collection-item {
  border-left: 8px #eee solid;
  border-bottom: 1px #eee solid;
  margin-top: 1px;
  padding: 10px;
}

.form-receipt-disbursement-configuration .collection-item.ui-sortable-helper {
  border-top: 1px #eee solid;
}

.form-receipt-disbursement-configuration .line-item-move,
.form-receipt-disbursement-configuration .line-item-label,
.form-receipt-disbursement-configuration .line-item-widget {
  display: inline;
  vertical-align: middle;
}

.form-receipt-disbursement-configuration .line-item-move {
  display: inline-block;
}

.form-receipt-disbursement-configuration .line-item-label {
  padding-left: 5px;
}

.form-receipt-disbursement-configuration .collection-item:first-child {
  margin-top: 0;
}

.form-receipt-disbursement-configuration .collection-item:last-child {
  border-bottom: none;
}

.form-receipt-disbursement-configuration .control-group {
  margin-bottom: 0;
}

.form-receipt-disbursement-configuration p:first-child {
  margin-top: 0;
}

.form-receipt-disbursement-configuration p:last-child {
  padding-top: 10px;
}
