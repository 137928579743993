
#htmlInputForm .CodeMirror {
  height: 600px;
}

#htmlInputForm:has(textarea.error) #htmlInputValidationErrors,
#htmlInputForm:has(textarea.is-invalid) #htmlInputValidationErrors {
  font-size: 80%;
  display: block !important;

  & #errorMessage {
    display: inline-block !important;
    color: var(--danger-color);
  }
}
