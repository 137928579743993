/* =========== move handles ================ */

.move-handle {
  display: inline-block;
  cursor: grab;
  margin-right: 6px;
  width: 20px;
  height: 20px;
}

.move-handle-bar {
  width: 17px;
  height: 4px;
  background-color: #ddd;
  margin-bottom: 2px;
}

.move-handle:hover .move-handle-bar {
  background-color: #555;
}

.move-handle-bar:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.move-handle-bar:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

/* =============== multi select =============== */

.multi-select {
  position: fixed;
  bottom: -70px;
  left: 0;
  width: 100%;
  background-color: #3bb2ed;
  max-height: 50%;
  display: flex;
  justify-content: space-between;
}

.multi-select-open {
  margin-bottom: 15%;
}

.multi-select,
.multi-select-visible {
  transition: 0.5s bottom;
}

.multi-select-visible {
  bottom: 0;
}

.multi-select .items,
.multi-select .actions {
  padding: 10px;
}

.multi-select .items {
  margin: 0 0 0 5%;
}

.multi-select .actions {
  margin: 0 0 0 0;
}

.multi-select .items {
  color: #fff;
  font-weight: 500;
}

.multi-select .items label,
.multi-select .items span {
  display: inline-block;
  padding: 10px;
}

.multi-select .items label,
.multi-select .items .message,
.multi-select .items span {
  line-height: 1.2em;
}

.multi-select .items label,
.multi-select .items .message {
  vertical-align: top;
}

.multi-select .items span {
  vertical-align: middle;
}

.multi-select .items span {
  max-width: 80%;
}

.multi-select .actions {
  white-space: nowrap;
}

.multi-select .btn i {
  margin-top: -3px;
  margin-right: 4px;
}

.multi-select .message {
  display: none;
  position: absolute;
  top: 20px;
  left: 25px;
}

.multi-select-success {
  background-color: #4ecaab;
}

.multi-select-error {
  background-color: #eb5767; /* red */
}

.multi-select-success .message {
  display: block;
}

.multi-select-success .items label,
.multi-select-success .items span,
.multi-select-success .actions {
  visibility: hidden;
  pointer-events: none;
}

/* =============== date picker =============== */

.hide-calendar .ui-datepicker-calendar {
  display: none;
}

/* =============== Collapsed element =============== */

.collapse-button {
  text-align: center;
  font-size: 22px;
  font-family: "Gotham Book", "Helvetica Neue", "Arial", sans-serif;
  letter-spacing: -1px;
  color: #2c99d0;
  padding: 20px;
}

.collapse-button-func {
  cursor: pointer;
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Safari */
  transform: rotate(180deg);
}

.nocoll .collapse-button-func {
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari */
  transform: rotate(0deg);
}
