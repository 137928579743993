.template-replacement {
  background: var(--white-bg);
  border: 1px solid var(--light-gray);
  position: relative;
}

.template-replacement__toggle {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  text-align: right;
}

.template-replacement__toggle > svg {
  transition: var(--transition);
}

.template-replacement.is-collapsed > .template-replacement__toggle > svg {
  transform: rotate(180deg);
}

.template-replacement:not(.is-collapsed) .template-replacement__title {
  font-weight: bold;
}
