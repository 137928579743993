
.box-policy-status .body .content {
  display: table;
  width: 100%;
}

.box-policy-status ul {
  display: table-row;
  list-style-type: none;
}

.box-policy-status li {
  display: table-cell;
  border-bottom: 1px #eee solid;
  padding: 3% 0 3% 3%;
}

.box-policy-status ul:last-child li {
  border-bottom: none;
}

.box-policy-status span {
  display: block;
  margin-bottom: 5px;
}

.box-policy-status label {
  font-weight: bold;
}

.box-policy-status span {
  font-size: 1.2em;
}

.box-policy-status .finance span {
  font-size: 1.8em;
}

.box-policy-status .total label { color: #3bb2ed; }
.box-policy-status .paid label { color: #4ecaab; }
.box-policy-status .outstanding label { color: #eb5767; }

.box-policy-status .field-loading::after {
  content: "~";
}

.policy-additional-insureds .box {
  display: inline-block;
  width: 32%;
}

.itc-pending,
.noc-pending {
  color: #eb5767;
}

/* =========== claims ============ */

.modal-claims .lawsuits .controls {
  margin-left: 0;
}

form.claim .lawsuits .delete {
  text-align: right;
}

form.claim .lawsuits .delete a {
  color: #eb5767;
}

form.claim .lawsuits .add {
  margin: 0 0 0 20px;
}

form.claim .help-inline {
  display: block;
}

form.claim .text-error {
  color: #f00;
}

/* ==================== accounting ========================== */

.listing-transaction-groups .name-val label {
  word-break: break-word;
}
