
/* =============== post entry box ============ */

.box-user-post-new {
  margin-bottom: 0;
}

.box-user-post-new .footer {
  text-align: right;
  position: relative;
}

.box-user-post-new .arrow-down {
  position: absolute;
  top: 0;
  left: 53px;
}

.box-user-post-new .control-group {
  padding-left: 0;
  margin-bottom: 0;
}

.box-user-post-new .redactor_wysiwyg {
  border: none;
  min-height: 100px;
}

.box-user-post-new .redactor_placeholder {
  font-size: 1.4em;
  color: #aaa;
  font-family: Gotham, sans-serif;
}

.box-user-post-new .pinned {
  display: inline-block;
  margin-right: 15px;
}

/* ========= post types =========== */

.user-post-type {
  background-color: #3bb2ed;
}

/* ============ user posts ============= */

.social .user-posts {
  padding-top: 40px;
  padding-bottom: 40px;
  list-style-type: none;
}

.social .listing li {
  background-color: #fff;
  border: none;
  margin-bottom: 40px;
  border-radius: 5px;
  position: relative;
}

.modal-user-post .subheading,
.modal-user-post .location,
.social .listing .subheading,
.social .listing .location {
  color: #aaa;
}

.social .listing .body {
  padding: 0 0 20px 0;
}

.modal-user-post .fragment {
  padding-top: 20px;
}

.modal-user-post .fragment,
.social .listing .content {
  font-size: 1.2em;
  word-break: break-word;
}

.modal-user-post .fragment iframe,
.social .listing .body iframe {
  width: calc(100% - 2px);
  margin-left: 1px;
}

.modal-user-post .fragment p iframe,
.modal-user-post .fragment img,
.social .listing .body p iframe,
.social .listing .body img {
  width: calc(100% + 39px);
  margin-left: -19px;
}

.modal-user-post .fragment iframe,
.social .listing .body iframe {
  min-height: 350px;
}

.modal-user-post .avatar-wrap,
.social .listing .avatar-wrap {
  border-radius: 5px;
  vertical-align: top;
}

.social .listing h3 {
  font-size: 1.3em;
  color: #000;
}

.modal-user-post .fragment p,
.social .listing .body .content p {
  margin: 0 20px 10px 20px;
}

.modal-user-post .fragment ol,
.social .listing .body .content ol {
  margin: 20px 20px 20px 40px;
}

.modal-user-post ul,
.social .listing .body .content ul {
  list-style-type: none;
  margin: 20px;
}

.social .listing .inner {
  margin-left: 5px;
  line-height: 15px;
  padding-top: 15px;
}

.social .listing .arrow-left {
  position: absolute;
  top: 20px;
  left: -10px;
}

.social .listing .user-post-type {
  position: absolute;
  top: 15px;
  left: -57px;
}

.modal-user-post .location,
.social .listing .location {
  margin: 20px 20px 0 20px;
  font-size: 0.7em;
}

.social .title .remove {
  color: #eb5767;
  opacity: 0.6;
  font-size: 0.8em;
  text-decoration: none;
}

.social .title .remove:hover {
  opacity: 1;
  text-decoration: underline;
}

.social .listing .social-actions {
  padding: 0 0 5px 20px;
}

/* =========== likes ============ */

.modal-user-post .likes,
.social .likes {
  padding: 20px 30px 0 20px;
  font-size: 0.9em;
}

.social.widget-social .likes {
  padding: 0;
  font-size: 16px;
}

.modal-user-post .likes a,
.social .likes a {
  display: inline-block;
  height: 30px;
  line-height: 34px;
  text-decoration: none;
  color: #aaa;
}

.modal-user-post .likes a.liked i,
.social .likes a.liked i {
  color: #f63733;
}

.modal-user-post .likes .word,
.social .likes .word {
  font-weight: bold;
}

/* =========== comments ============ */

.modal-user-post .comments,
.social .comments {
  border-top: 1px #eee solid;
  display: table;
}

.modal-user-post .no-comments,
.social .no-comments {
  display: none;
}

.modal-user-post .modal-body .comments,
.social .listing .body .content .comments {
  margin-bottom: 10px;
  padding-top: 10px;
}

.modal-user-post .modal-body .comments img,
.social .listing .comments img {
  width: auto;
  margin-left: 0;
}

.modal-user-post .comment .info,
.modal-user-post .comment .message,
.social .comment .info,
.social .comment .message {
  font-size: 0.8em;
}

.modal-user-post .comment .user,
.social .comment .user {
  font-weight: bold;
  white-space: nowrap;
}

.modal-user-post .comment .date,
.social .comment .date {
  color: #aaa;
}

/* =========== post comment ============ */

.social .user-posts .footer {
  background-color: #fafbfc;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px #fff solid;
  border-top: 1px #eee solid;
  padding: 20px;
}

.social .footer .avatar-wrap {
  vertical-align: top;
}

.modal-user-post .modal-footer input,
.social .user-posts .footer input {
  background-color: #fff;
  border: 1px #eee solid;
  width: calc(100% - 85px);
  vertical-align: top;
  margin-top: 2.5px;
  margin-left: 10px;
}

/* ============ post icons ============ */

.social .actions {
  list-style-type: none;
  margin-left: 30px;
  margin-top: 5px;
  float: left;
}

.social .actions li {
  display: inline-block;
  margin: 0 30px 0 0;
}

.social .action-icon {
  width: 30px;
  display: inline-block;
}

.camera {
  height: 20px;
}

.camera .head {
  height: 3px;
  margin: 0 10px 0 10px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.camera .main {
  height: 17px;
  position: relative;
  border-radius: 2px;
}

.camera .lens {
  position: absolute;
  background-color: #fff;
  width: 13px;
  height: 13px;
  left: 9.5px;
  top: 2px;
  border-radius: 50%;
}

.camera .lens-inner {
  position: absolute;
  width: 9px;
  height: 9px;
  left: 11.5px;
  top: 4px;
  border-radius: 50%;
}

.youtube {
  border-radius: 5px;
  position: relative;
  height: 19px;
}

.youtube .arrow {
  position: absolute;
  top: 5px;
  left: 11px;
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 12px;
}

.social .camera .head,
.social .camera .main,
.social .camera .lens-inner,
.social .youtube {
  background-color: #bbb;
}

.social a:hover .camera .head,
.social a:hover .camera .main,
.social a:hover .camera .lens-inner,
.social a:hover .youtube {
  background-color: #666;
}

/* ======== redactor ========== */

#redactor_modal {
  border-radius: 6px;
  font-size: 1em !important;
  overflow: hidden;
  font-family: var(--font-family);
}

#redactor_modal header,
#redactor_modal_inner footer,
#redactor_modal_close,
#redactor_modal #redactor-modal-video-insert,
#redactor_tab3 {
  padding: 20px;
}

#redactor_modal_close {
  top: 0;
  right: 20px;
}

#redactor_modal header {
  font-family: Gotham Book, sans-serif;
  font-size: 1.7em;
}

#redactor_modal_inner {
  border-top: 1px #eee solid;
}

#redactor_tabs {
  padding: 20px;
  margin: 0;
  background: transparent;
  border-bottom: 1px #eee solid;
}

#redactor_tabs a {
  background: transparent;
  border: none;
}

#redactor_tabs a:hover {
  color: #000 !important;
}

#redactor_tabs a.redactor_tabs_act {
  color: #fff !important;
}

.redactor_toolbar li a:hover {
  background-color: var(--primary-color);
}

.redactor_dropdown a:hover {
  background-color: var(--blue-lighter);
}

.redactor_toolbar li a:active,
.redactor_toolbar li a.redactor_act {
  background-color: var(--light-gray);
}

.redactor_dropdown a {
  padding: 10px 15px;
  font-family: var(--font-family);
}

#redactor_modal footer {
  text-align: right;
  padding-top: 0;
}

#redactor_modal footer button {
  width: auto !important;
  margin-left: 10px;
}

#redactor_modal footer button.redactor_btn_modal_close {
  display: none;
}

#redactor_modal footer button.redactor_modal_action_btn {
  background: var(--primary-color);
}

#redactor_modal footer button.redactor_modal_action_btn:hover {
  background: var(--primary-color);
  color: #fff;
}

#redactor_modal input[type=file] {
  background-color: transparent;
  padding: 0 20px;
  width: calc(100% - 40px);
  margin-bottom: 20px;
}

.redactor_droparea {
  width: calc(100% - 40px);
  margin: 20px;
}

.redactor_droparea .redactor_dropareabox {
  margin: 0;
  padding: 60px 0;
  width: calc(100% - 4px);
}

.redactor_dropalternative {
  padding: 0 20px 20px 20px;
  font-size: 1em;
  margin: 0;
}

#redactor_modal label {
  padding: 0 0 3px 0 !important;
  font-size: 1em !important;
  margin: 0 !important;
}

#redactor_modal input[type="text"] {
  width: 100%;
  border: 1px solid var(--light-gray) !important;
  background: transparent;
  height: auto !important;
  margin-bottom: var(--mid-spacing) !important;
}

#redactor_modal input[type=text] {
  box-shadow: none;
}

#redactor_modal textarea {
  width: calc(100% - 24px) !important;
  margin: 0;
  box-shadow: none;
}

.redactor_dropdown {
  padding: 5px !important;
  font-size: var(--font-size);
  box-shadow: var(--shadow);
  border-radius: var(--radius);
}

/* ============ errors =========== */

.social .wysiwyg + .error {
  margin: 0 20px;
}

.social .footer .error {
  margin: 10px 20px;
}

/* ============ sidebar =========== */

.social-sidebar ul {
  list-style-type: none;
}

.social-sidebar li {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px #eee solid;
}

.social-sidebar .user {
  font-weight: bold;
}

.social-sidebar .top-posts .avatar-wrap {
  vertical-align: middle;
}

.social-sidebar .recent-comments .avatar-wrap {
  vertical-align: top;
}

.social-sidebar .avatar-wrap {
  border-radius: 2px;
}

.social-sidebar .link {
  margin-top: 8px;
  text-align: right;
}

.social-sidebar .blurb {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  max-width: calc(100% - 60px);
}

.social-sidebar .content {
  font-style: italic;
  margin-top: 5px;
  word-break: break-word;
}

.social-sidebar li:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.social-sidebar li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: none;
}

.social-sidebar .thumbnail {
  display: block;
  width: calc(100% + 19px);
  margin-left: -9px;
  margin-top: 12px;
  margin-bottom: 12px;
}

/* ============== user post modal ============= */

.modal-user-post .modal-header .inner {
  display: inline-block;
  margin-left: 10px;
}

.modal-user-post .modal-footer {
  text-align: left;
}

.modal-user-post .modal-header .avatar-wrap,
.modal-user-post .modal-footer .avatar-wrap {
  vertical-align: top;
}

/* ========== Responsive ============= */
@media (min-width: 768px) {
  .social .user-posts {
    border-left: 2px #fff solid;
    margin-left: 20px;
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  /* =========== comments ============ */

  .modal-user-post .comments li,
  .social .comments li {
    display: table-row;
  }

  .modal-user-post .comments .avatar-wrap,
  .modal-user-post .comments .info,
  .modal-user-post .comments .message,
  .social .comments .avatar-wrap,
  .social .comments .info,
  .social .comments .message {
    display: table-cell;
    padding: 10px 5px;
  }
}

@media (max-width: 767px) {
  .box-user-post-new .arrow-down {
    left: 23px;
  }

  .box-user-post-new .actions {
    margin-left: 0;
    float: none;
    text-align: left;
  }

  .box-user-post-new .pinned {
    float: left;
    margin: 30px 0;
  }

  .box-user-post-new .content .btn-primary {
    float: right;
    margin: 20px 0;
  }

  .social .user-posts {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .social .listing li {
    margin-bottom: 10px;
  }

  .social .comments li {
    display: block;
  }

  .social .comments .avatar-wrap,
  .social .comments .info {
    float: left;
    padding: 5px;
  }

  .social .comments .message {
    padding: 10px 5px;
    float: left;
    width: 100%;
  }
}
