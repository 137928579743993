/* Modal backdrop */
[data-command-palette-target="modal"] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 120;
}

[data-command-palette-target="modal"].hidden {
  display: none;
}

/* Modal content */
[data-command-palette-target="modal"] > div {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  width: 100%;
  max-width: 32rem;
  height: 32rem; /* Fixed height */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent content from spilling out */
}

/* Search input container */
[data-command-palette-target="modal"] > div > div:first-child {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

/* Search input */
[data-command-palette-target="input"] {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;
}

[data-command-palette-target="input"]:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

/* Results container */
[data-command-palette-target="results"] {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
}

/* Command items */
.command-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 0.375rem;
  margin-bottom: 0.25rem;
}

.command-item:last-child {
  margin-bottom: 0;
}

.command-item:hover {
  background-color: #f3f4f6;
}

.command-item.selected {
  background-color: #e5e7eb;
}

.command-name {
  font-weight: 500;
  color: #111827;
}

.command-description {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.25rem;
}

/* Loading spinner */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Keyboard shortcut hint */
.kbd {
  padding: 0.25rem 0.5rem;
  background-color: #f3f4f6;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
}
