
.row-stats {
  width: 100%;
  margin-left: 20px;
}

.stats {
  margin: 20px 0 0 0 !important;
  border-radius: 3px;
  overflow: hidden;
}

.stats-green,
.stats-blue,
.stats-red,
.stats-orange {
  color: #fff;
}

.stats-green {
  background-color: #92e439;
}

.stats-red {
  background-color: #eb5767;
}

.stats-blue {
  background-color: #3bb2ed;
}

.stats-orange {
  background-color: #ffab21;
}

.stats-light .top,
.stats-light .middle,
.stats-light .bottom {
  background-color: #f2f3f5;
}

.stats-light-red,
.stats-light-blue {
  padding-left: 4px;
}

.stats-light-red {
  background-color: #eb5767;
}

.stats-light-blue {
  background-color: #3bb2ed;
}

.stats .top,
.stats .middle,
.stats .bottom {
  padding: 10px 20px;
}

.stats .top {
  padding-top: 20px;
}

.stats .top h3 {
  border: none;
  font-size: 1.2em;
  margin: 0;
  padding: 0;
  display: block;
}

.stats-compact .middle {
  font-size: 2em;
}
