
#ProductPreview [data-ts-item],
#ProductChangelog [data-ts-item] {
  width: 100%;
}

#ProductPreview .indicator,
#ProductChangelog .indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 1px;
}

#ProductPreview .indicator.active,
#ProductChangelog .indicator.active {
  background-color: #149811;
}

#ProductPreview .indicator.deprecated,
#ProductChangelog .indicator.deprecated {
  background-color: #e95767;
}

#ProductPreview .indicator.unpublished,
#ProductChangelog .indicator.unpublished {
  background-color: #ffa500;
}
