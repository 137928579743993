/* =============== mailpane =============== */

#mailpane-actions {
  display: none;
}

.mailpane-toggler .btn {
  height: 20px;
}

.mailpane-toggler .btn i {
  width: 15px;
  margin-top: 3px;
}

.mailpane {
  position: fixed;
  right: 0;
  top: 0;
  width: 45vw;
  min-width: 500px;
  background-color: #fff;
  display: block;
  transform: translateX(100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px;
  transition: all 0.2s linear;
  height: 100vh !important;
}

.mailpane .close {
  opacity: 1;
}

.mailpane-open {
  transition: margin 0.5s;
}

.mailpane-open .mailpane {
  transform: translateX(0);
}

.mailpane .header {
  height: 70px;
  background: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 15px 0 25px;
}

.mailpane .header h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  flex-grow: 1;
}

.mailpane .header .close {
  height: 30px;
}

.mailpane .body {
  padding: 10px;
}

.mailpane .layout-body {
  height: calc(100vh - 70px);
}

.mailpane-compose-styling {
  height: auto;
}

.mailpane .panel-pane {
  width: calc(50% - 20px);
  padding: 10px;
  height: auto;
  max-height: calc(100vh - 161px);
  overflow: hidden;
}

.mailpane .tab-content {
  background-color: var(--light-gray-bg);
  border: 1px solid var(--light-gray);
  border-radius: 0 var(--lg-radius) var(--lg-radius) var(--lg-radius);
  height: auto;
}

.mailpane .nav-tabs {
  margin-bottom: -1px;
}

.mailpane .nav-tabs-dynamic a {
  background-color: var(--light-gray);
  border: 1px solid var(--light-gray);
  text-decoration: none;
}

.mailpane .nav-tabs-dynamic a:hover {
  color: var(--primary-color);
}

.mailpane .nav-tabs-dynamic a.active:not(.nav-link) {
  border-bottom: none;
  background-color: var(--light-gray-bg);
  color: var(--primary-color);
}

.mailpane .input-group {
  flex-wrap: nowrap;
}

/* .mailpane input[type='text'],
.mailpane select,
.mailpane textarea {
  width: 93%;
} */

.mailpane .select2-container {
  width: 100%;
}

.mailpane input[type='text'],
.mailpane select,
.mailpane .select2-container,
.mailpane textarea {
  margin: 0 0 2% 0;
  background: var(--input-bg);
  border: 1px solid var(--light-gray);
}

.mailpane input[type='text']:hover,
.mailpane select:hover,
.mailpane .select2-container:hover,
.mailpane textarea:hover,
.mailpane input[type='text']:focus,
.mailpane select:focus,
.mailpane .select2-container:focus,
.mailpane textarea:focus {
  border-color: var(--primary-color);
}

.mailpane .iscroll-loader {
  background-color: #fff;
  height: 25px;
  padding: 0;
  border-radius: 15px;
  margin: 0 auto;
  width: 25px;
  background-position: 0 0;
  opacity: 0.7;
}

.mailpane .tab-content-loading {
  color: #fff;
  background-image: none;
  padding-left: 8px;
  font-weight: normal;
}

.mailpane .reading-pane .message h3,
.mailpane .emails-sent .reading-pane h3 {
  padding: 10px 10px 0 10px;
  font-weight: var(--font-weight-bold);
  font-size: var(--h3-size);
}

.mailpane .reading-pane .message .from,
.mailpane .emails-sent .reading-pane .from {
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid var(--light-gray);
}

.mailpane .email-template-form .subject {
  width: 100%;
  margin-top: var(--spacing);
}

.mailpane .email-template-form {
  overflow-y: scroll;
}

.mailpane .email-template-form label.checkbox input {
  opacity: 1;
  height: auto;
  width: auto;
  margin-right: 6px;
  position: relative;
  margin-top: -1px;
  vertical-align: middle;
}

.mailpane .email-template-form label.checkbox {
  margin: 0 0 2% 0;
  font-size: 13px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
}

/* ============= mailpane dnd =============== */

.mailpane .handle {
  float: right;
  width: 18px;
  cursor: move;
  display: none;
}

.mailpane .ui-draggable .handle {
  display: inline-block;
}

.mailpane .message.ui-draggable-dragging {
  background-color: #2a3342;
  border-radius: 8px;
  width: 367px;
  padding: 8px;
}

.mailpane-dnd-actions {
  background-color: rgba(0, 0, 0, 0.7);
  width: calc(100% - 420px);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}

.mailpane-dnd-actions a {
  text-decoration: none;
}

.mailpane-dragging .mailpane-dnd-actions {
  display: block;
}

.mailpane-dragging .mailpane-dnd-actions ul {
  margin: 60px 10px 10px 10px;
}

.mailpane-dragging .mailpane-dnd-actions li {
  float: left;
  background-color: #fff;
  display: block;
  border-radius: 8px;
  margin: 10px;
  padding: 0 3% 0 3%;
  font-size: 2em;
  line-height: 2em;
  box-shadow: 5px 5px 10px #222;
  text-decoration: none;
}

.mailpane-dragging .mailpane-dnd-actions li.hover {
  background-color: #f9eca2;
}

/* ============== mailpane compose =========== */

.mailpane textarea {
  height: 250px;
}

.mailpane .query-group input[type='text'] {
  margin: 0;
  flex-grow: 1;
}

.mailpane .submit-group {
  margin-top: 10px;
}

.mailpane .submit-group .alert-sent,
.mailpane .submit-group .alert-failed {
  display: none;
}

.mailpane .submit-group.failed .alert-failed,
.mailpane .submit-group.sent .alert-sent {
  display: block;
}

.mailpane .send-form .error {
  color: #f33;
}

.mailpane .send-form h4 {
  color: #fff;
  font-weight: normal;
  margin: 0 0 15px 0;
}

.mailpane .attachments-size-message,
.mailpane .attachments-size {
  font-size: var(--font-size);
}

.mailpane .attachments-size-message.error {
  color: #f00;
}

.mailpane .attachments-size {
  padding-top: 10px;
}

.mailpane .attachments-size.warning {
  color: #f43;
  font-weight: bold;
}

.mailpane .send-form .sent input[type='submit'],
.mailpane .send-form .failed input[type='submit'] {
  display: none;
}

.mailpane .send-multiple {
  text-align: right;
  padding: 0 0 5px 0;
}

.mailpane .send-multiple label {
  color: #fff;
  font-size: 0.8em;
  display: inline;
}

.mailpane .send-multiple input {
  margin: 0;
}

.mailpane .panel-compose label.error {
  font-size: 0.8em;
  line-height: 0.8em;
}

.mailpane .panel-compose .files ul {
  list-style-type: none;
  margin: 0;
}

.mailpane .panel-compose .redactor_box {
  background: transparent;
}

.mailpane .panel-compose .redactor_message {
  height: 300px;
  min-height: 150px;
  max-height: calc(100vh - 460px);
  overflow-y: scroll;
  padding: 10px;
  border: 1px solid var(--light-gray);
  border-radius: var(--radius);
  resize: vertical;
}

.mailpane .panel-compose .redactor_message:hover {
  border-color: var(--primary-color);
}

.mailpane .redactor_editor .redactor_placeholder {
  color: var(--font-light) !important;
}

/* Hide some redactor buttons */
.mailpane .redactor_box .re-image,
.mailpane .redactor_box .re-video,
.mailpane .redactor_box .re-html {
  display: none;
}

/* =============== mailpane search ============= */

.mailpane .suggestions {
  margin: var(--thin-spacing) var(--mid-spacing);
}

.mailpane .suggestions li {
  display: inline;
  cursor: pointer;
}

.mailpane .suggestions span {
  color: var(--primary-color);
  font-size: var(--font-size);
}

.mailpane .suggestions li:hover span {
  color: var(--primary-dark);
}

.mailpane .suggestions li::after {
  content: ' • ';
}

.mailpane .suggestions li:last-child::after {
  content: '';
}

.mailpane .attachments {
  list-style-type: none;
}

.mailpane,
.mailpane .tab-content .tab-pane,
.mailpane .tab-content .tab-pane form,
.mailpane .tab-content .tab-pane form .panel,
.mailpane .tab-content .tab-pane form .panel .panel-inner,
.mailpane .tab-content .tab-pane form .panel .panel-inner .panel-pane,
.mailpane .tab-content .tab-pane form .panel .panel-inner .panel-pane .reading-pane,
.mailpane .tab-content .tab-pane,
.mailpane .tab-content .tab-pane .tab-content-dynamic,
.mailpane .tab-content .tab-pane .tab-content-dynamic .panel,
.mailpane .tab-content .tab-pane .tab-content-dynamic .panel .panel-inner,
.mailpane .tab-content .tab-pane .tab-content-dynamic .panel .panel-inner .panel-pane {
  height: 100%;
}

/* ============ listing ============= */

.mailpane .listing-messages {
  border: 1px solid var(--light-gray);
  background: var(--input-bg);
  border-radius: var(--radius);
  max-height: calc(100vh - 228px);
  overflow-y: auto;
}

.mailpane .emails-sent .listing-messages {
  max-height: calc(100vh - 152px);
}

.mailpane .listing .no-results {
  font-size: var(--font-size-heading);
  color: var(--font-light);
}

.mailpane .listing > li {
  padding: var(--mid-spacing);
  border-bottom: 1px solid var(--light-gray);
  border-top: none;
}

.mailpane .listing > li:nth-child(2n) {
  background-color: var(--input-bg);
}

.mailpane .listing > li:last-child {
  border-bottom: none;
}

.mailpane .listing .message .band {
  left: 0;
}

.mailpane .listing .title {
  padding: 0;
}

.mailpane .listing-files .title .inner {
  margin-left: 10px;
  max-width: 100%;
}

.mailpane .listing-files .title .svg-inline--fa {
  width: 15px;
  height: 15px;
  color: var(--primary-color);
}

.mailpane .listing-messages-search .title .inner {
  max-width: calc(100% - 25px);
}

.mailpane .listing .title h3 {
  color: var(--font-color);
  cursor: pointer;
  font-size: var(--font-size-heading);
  line-height: 1.3em;
  margin-bottom: 0;
  word-break: normal;
}

.mailpane .listing .title svg {
  cursor: pointer;
}

.mailpane .listing .subheading {
  color: var(--font-light);
  font-size: var(--font-size);
  margin: 0;
}

.mailpane .listing-messages .body {
  padding: 0;
}

.mailpane .listing-messages .attachments {
  list-style-type: none;
}

.mailpane .reading-pane .attachments {
  padding-left: 10px;
}

.mailpane .listing-messages .attachments li,
.mailpane .reading-pane .attachments li {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!-- Font Awesome Pro 5.15.4 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='rgb(118, 131, 144)' d='M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 16px 16px;
  padding: 0 0 0 20px;
  color: var(--font-light);
  display: block;
  margin-top: 5px;
  font-size: var(--font-size);
}

.mailpane .reading-pane .attachments li a {
  color: var(--font-light);
  text-decoration: none;
}

.mailpane .reading-pane .attachments li a:hover {
  color: var(--font-color);
  text-decoration: underline;
}

.mailpane .listing-messages .attachments li:last-child {
  margin-bottom: 0;
}

.mailpane .listing-messages .subheading a {
  font-size: 0.8em;
  color: #d6d6d6;
  letter-spacing: 1px;
}

/* ========== reading pane ============ */

.mailpane .reading-pane h3 {
  margin: 0;
  padding: 10px 0;
}

.mailpane .reading-pane .status {
  float: right;
  padding: 10px 10px 0 0;
}

.mailpane .emails-sent .reading-pane .body {
  padding-top: 0;
}

.mailpane .reading-pane .buttons {
  margin-bottom: 2%;
}

.mailpane .reading-pane .loading {
  background-position: center center;
}

.mailpane .reading-pane .content {
  max-height: calc(100vh - 202px);
  height: auto;
  clear: both;
  overflow-y: scroll;
  border-radius: var(--radius);
  background-color: var(--input-bg);
  border: 1px solid var(--light-gray);
}

.mailpane .reading-pane .btn-cancel {
  cursor: pointer;
}

.mailpane .reading-pane .btn-group {
  float: right;
}

.mailpane .reading-pane h3 {
  border: none;
  margin-bottom: 5px;
}

.mailpane .reading-pane .from {
  margin-bottom: 2%;
}

.mailpane .reading-pane .btn-actions a {
  cursor: pointer;
}

/* =========== files lists ============ */

.mailpane .files-available {
  margin-top: 15px;
}

.mailpane .tab-content .tab-pane form .panel .panel-inner .panel-pane.select-attachments {
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
}

.mailpane .select-attachments .file .content {
  display: flex;
  align-items: center;
}

.btn-attach-wrap {
  width: 100%;
  margin: 0;
}

.btn-attach,
.btn-attach:hover {
  background-color: var(--primary-color) !important;
  border: none;
  font-size: 11px;
  color: #fff !important;
  padding: 11px 20px 10px;
}

.btn-attach:hover {
  opacity: 0.9;
}

.mailpane .attach-info {
  color: var(--font-color);
  float: right;
}

.mailpane .listing-files {
  max-height: calc(100vh - 332px);
  overflow-y: auto;
}

.mailpane .listing-files:empty {
  border: none;
}

.mailpane .files-available {
  margin-bottom: 15px;
}

.mailpane .listing-files:empty {
  border: none;
}

.mailpane .listing-files .icon-selected,
.mailpane .listing-files .selected .icon-no-selected {
  display: none;
}

.mailpane .listing-files .icon-no-selected,
.mailpane .listing-files .selected .icon-selected {
  display: inline-block;
}

.mailpane .send-form .attachments {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mailpane .send-form .attachments .control-group {
  padding-left: 0;
}

.mailpane .send-form .attachments .control-group div:nth-of-type(2),
.mailpane .listing-files li:first-of-type {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.mailpane .send-form .attachment {
  line-height: 1em;
  display: flex;
  align-items: center;
}

.mailpane .send-form .attachment label {
  line-height: 1.3em;
  margin: 0;
}

.mailpane .send-form .attachments label.required::before {
  display: none;
}

.mailpane .send-form .collection-item,
.mailpane .listing-files .file {
  border: var(--light-gray) solid;
  border-width: 1px 1px 0 1px;
  background: var(--input-bg);
  margin: 0;
  padding: 10px;
  border-radius: 0;
}

.mailpane .send-form .collection-item:last-child,
.mailpane .listing-files li:last-of-type {
  border-bottom: 1px var(--light-gray) solid;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

.mailpane .send-form .delete {
  cursor: pointer;
  margin-right: 10px;
  color: #f33;
}

.mailpane .send-form .delete,
.mailpane .send-form .control-label {
  display: inline-block;
}

/* ================ mailpane actions ============= */

.mailpane-action {
  white-space: nowrap;
  display: block;
  font-size: 0.9em;
}
