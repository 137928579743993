.diff-wrapper.diff {
  --tab-size: 4;

  background: repeating-linear-gradient(-45deg, whitesmoke, whitesmoke 0.5em, #e8e8e8 0.5em, #e8e8e8 1em);
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  color: black;
  empty-cells: show;
  font-family: monospace;
  font-size: 13px;
  width: 100%;
  word-break: break-all;
}

.diff-wrapper.diff tbody tr {
  border-width: 0 !important;
}

.diff-wrapper.diff th {
  font-weight: 700;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.diff-wrapper.diff td {
  vertical-align: baseline;
}

.diff-wrapper.diff td,
.diff-wrapper.diff th {
  border-collapse: separate;
  border: none;
  padding: 1px 2px;
  background: #fff;
}

.diff-wrapper.diff td:empty::after,
.diff-wrapper.diff th:empty::after {
  content: " ";
  visibility: hidden;
}

.diff-wrapper.diff td a,
.diff-wrapper.diff th a {
  color: #000;
  cursor: inherit;
  pointer-events: none;
}

.diff-wrapper.diff thead th {
  background: var(--light-gray-bg);
  border-bottom: 1px solid var(--light-gray);
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--font-color);
  padding: 10px;
  line-height: 14px;
  text-align: left;
  width: 50%;
}

.diff-wrapper.diff tbody.skipped {
  border-top: 1px solid black;
}

.diff-wrapper.diff tbody.skipped td,
.diff-wrapper.diff tbody.skipped th {
  display: none;
}

.diff-wrapper.diff tbody th {
  background: var(--disabled-color);
  border: solid var(--light-gray);
  border-width: 0 1px 1px 0;
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  color: var(--font-color);
  text-align: right;
  vertical-align: top;
  width: 4em;
}

.diff-wrapper.diff tbody td {
  border-bottom: 1px solid var(--smooth-gray);
  color: var(--font-color);
  font-size: var(--font-size);
}

.diff-wrapper.diff tbody th.sign {
  background: #fff;
  border-right: none;
  padding: 1px 0;
  text-align: center;
  width: 1em;
}

.diff-wrapper.diff tbody th.sign.del {
  background: #fbe1e1;
}

.diff-wrapper.diff tbody th.sign.ins {
  background: #e1fbe1;
}

.diff-wrapper.diff.diff-html {
  white-space: pre-wrap;
  tab-size: var(--tab-size);
}

.diff-wrapper.diff.diff-html .ch {
  line-height: 1em;
  background-clip: border-box;
  background-repeat: repeat-x;
  background-position: left center;
}

.diff-wrapper.diff.diff-html .ch.sp {
  background-image: url('data:image/svg+xml,%3Csvg preserveAspectRatio="xMinYMid meet" viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M4.5 11C4.5 10.1716 5.17157 9.5 6 9.5C6.82843 9.5 7.5 10.1716 7.5 11C7.5 11.8284 6.82843 12.5 6 12.5C5.17157 12.5 4.5 11.8284 4.5 11Z" fill="rgba%2860, 60, 60, 50%25%29"/%3E%3C/svg%3E');
  background-size: 1ch 1.25em;
}

.diff-wrapper.diff.diff-html .ch.tab {
  background-image: url('data:image/svg+xml,%3Csvg preserveAspectRatio="xMinYMid meet" viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.5 10.44L6.62 8.12L7.32 7.26L12.04 11V11.44L7.28 14.9L6.62 13.9L9.48 11.78H0V10.44H9.5Z" fill="rgba%2860, 60, 60, 50%25%29"/%3E%3C/svg%3E');
  background-size: calc(var(--tab-size) * 1ch) 1.25em;
  background-position: 2px center;
}

.diff-wrapper.diff.diff-html .change.change-eq .old,
.diff-wrapper.diff.diff-html .change.change-eq .new {
  background: #fff;
}

.diff-wrapper.diff.diff-html .change .old {
  background: #fbe1e1;
  width: 45%;
}

.diff-wrapper.diff.diff-html .change .new {
  background: #e1fbe1;
}

.diff-wrapper.diff.diff-html .change .rep {
  background: #fef6d9;
}

.diff-wrapper.diff.diff-html .change .old.none,
.diff-wrapper.diff.diff-html .change .new.none,
.diff-wrapper.diff.diff-html .change .rep.none {
  background: transparent;
  cursor: not-allowed;
}

.diff-wrapper.diff.diff-html .change ins,
.diff-wrapper.diff.diff-html .change del {
  font-weight: bold;
  text-decoration: none;
}

.diff-wrapper.diff.diff-html .change ins {
  background: #94f094;
}

.diff-wrapper.diff.diff-html .change del {
  background: #f09494;
}
