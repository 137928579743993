.import .name-val {
  list-style-type: none;
}

.import .name-val li {
  padding: 0.5rem 1rem;
}

.import .name-val li:first-child {
  padding-top: 0;
}

.import .name-val label,
.import .name-val span {
  display: inline-block;
  vertical-align: top;
}

.import .name-val label {
  font-weight: bold;
  width: 40%;
}

/* ============== managed onboarding ================= */

.managed-onboarding .card-columns.phase {
  column-count: unset;
  column-gap: unset;
}

.managed-onboarding .row [class*=col-] {
  margin-bottom: 0.75rem;
}

.managed-onboarding .card-columns.phase .card {
  height: 100%;
  position: relative;
}

.managed-onboarding .card-columns.phase .card .card-body {
  height: 100%;
  padding-bottom: 60px;
}

.managed-onboarding .card-columns.phase .card .card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.managed-onboarding .card-title,
.managed-onboarding .card-text {
  font-size: small;
}

.managed-onboarding .card-columns.phase a.btn {
  width: 100%;
}

.managed-onboarding .progress-summary {
  margin: 20px 20px 30px 20px;
}

.managed-onboarding #progress-estimate {
  margin: 2px 20px 3px 20px;
}

.managed-onboarding .row.managed [class*="col-"]:first-child p,
.managed-onboarding .row [class*="span"]:first-child p {
  margin: 0;
}
