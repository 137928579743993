
html,
body {
  height: 100%;
}

body {
  margin-top: 90px;
  letter-spacing: 0.5px;
  background-color: #f1f5f9;
  color: var(--font-color);
}

body,
input,
textarea,
button {
  font-family: Gotham, sans-serif;
  font-weight: 300;
}

body,
button {
  font-size: 0.9em;
}

input,
textarea {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

a {
  cursor: pointer;
  color: #000;
}

a {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.invert-anchors a {
  text-decoration: none;
}

.invert-anchors a:hover {
  text-decoration: underline;
}

.invert-anchors .btn:hover {
  text-decoration: none;
}

p {
  line-height: 1.4em;
}

/* ============= division themes ============= */

/* Division 1 */
.theme-division-1 .division-selector {
  color: #3ab1eb;
}

.theme-division-1 .division-selector:hover {
  color: #2d99cf;
}

/* Division 2 */
.theme-division-2 .division-selector {
  color: #92e439;
}

.theme-division-2 .division-selector:hover {
  color: #58e426;
}

/* Division 3 */
.theme-division-3 .division-selector {
  color: #ffab21;
}

.theme-division-3 .division-selector:hover {
  color: #ff8818;
}

.division-selector {
  background: transparent;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 2px;
}

/* ========= loaders (source: http://tobiasahlin.com/spinkit/) ========= */

.iscroll-loader {
  width: 50px;
  margin: 20px auto;
}

.loader {
  position: absolute;
  top: 0;
}

.loader-fixed {
  position: static;
}

.loader,
.loader .mask {
  width: 100%;
  height: 100%;
}

.loader .mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.loader .white-mask {
  background-color: rgba(255, 255, 255, 0.7);
}

.loader .inner {
  margin: 0 auto;
  position: fixed;
  top: 30%;
  left: 45%;
  padding: 40px 10px 10px 10px;
  white-space: nowrap;
}

.loader .inner-partial {
  margin: 0 0 0 -60px;
  position: absolute;
  top: 0;
  left: 50%;
  padding: 40px 10px 10px 10px;
  white-space: nowrap;
}

.mailpane-open .loader .inner {
  left: 31%;
}

.loader .inner.success {
  background-color: #daedde;
  border-color: #479555;
  color: #277535;
}

.loader .inner.failure {
  background-color: #eddbe3;
  border-color: #bd4247;
  color: #bd4247;
}

.loader-indicator-pulse {
  text-align: center;
  font-size: 10px;
}

.loader-indicator-pulse .rect {
  height: 100%;
  display: inline-block;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.loader-indicator-pulse {
  height: 30px;
}

.loader-indicator-pulse .rect {
  width: 6px;
  background-color: #3bb2ed;
}

.loader .loader-indicator-pulse {
  height: 200px;
}

.loader .loader-indicator-pulse .rect {
  width: 20px;
  background-color: rgba(0, 0, 0, 0.5);
}

.loader .loader-indicator-pulse .rect1 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.loader .loader-indicator-pulse .rect5 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.loader-indicator-pulse .rect {
  margin-right: 2px;
}

.loader-indicator-pulse .rect:last-child {
  margin-right: 0;
}

.loader-indicator-pulse .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loader-indicator-pulse .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loader-indicator-pulse .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loader-indicator-pulse .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/* ============ Loader 2.0 =========== */
#circularG {
  position: relative;
  width: 30px;
  height: 30px;
}

.circularG {
  position: absolute;
  background-color: #3bb2ed;
  width: 7px;
  height: 7px;
  -moz-border-radius: 5px;
  -moz-animation-name: bounce_circularg;
  -moz-animation-duration: 1.04s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: normal;
  -webkit-border-radius: 5px;
  -webkit-animation-name: bounce_circularg;
  -webkit-animation-duration: 1.04s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  -ms-border-radius: 5px;
  -ms-animation-name: bounce_circularg;
  -ms-animation-duration: 1.04s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: normal;
  border-radius: 5px;
  animation-name: bounce_circularG;
  animation-duration: 1.04s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

#circularG_1 {
  left: 0;
  top: 12px;
  -moz-animation-delay: 0.39s;
  -webkit-animation-delay: 0.39s;
  -ms-animation-delay: 0.39s;
  animation-delay: 0.39s;
}

#circularG_2 {
  left: 3px;
  top: 3px;
  -moz-animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
  -ms-animation-delay: 0.52s;
  animation-delay: 0.52s;
}

#circularG_3 {
  top: 0;
  left: 12px;
  -moz-animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
  -ms-animation-delay: 0.65s;
  animation-delay: 0.65s;
}

#circularG_4 {
  right: 3px;
  top: 3px;
  -moz-animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  -ms-animation-delay: 0.78s;
  animation-delay: 0.78s;
}

#circularG_5 {
  right: 0;
  top: 12px;
  -moz-animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  -ms-animation-delay: 0.91s;
  animation-delay: 0.91s;
}

#circularG_6 {
  right: 3px;
  bottom: 3px;
  -moz-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  animation-delay: 1.04s;
}

#circularG_7 {
  left: 12px;
  bottom: 0;
  -moz-animation-delay: 1.17s;
  -webkit-animation-delay: 1.17s;
  -ms-animation-delay: 1.17s;
  animation-delay: 1.17s;
}

#circularG_8 {
  left: 3px;
  bottom: 3px;
  -moz-animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -ms-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

@-moz-keyframes bounce_circularG {
  0% {
    -moz-transform: scale(1);
  }

  100% {
    -moz-transform: scale(0.3);
  }
}

@-webkit-keyframes bounce_circularG {
  0% {
    -webkit-transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.3);
  }
}

@-ms-keyframes bounce_circularG {
  0% {
    -ms-transform: scale(1);
  }

  100% {
    -ms-transform: scale(0.3);
  }
}

@keyframes bounce_circularG {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.3);
  }
}

.text-success {
  color: var(--success-color);
}

.text-error {
  color: var(--danger-color);
}

.text-error-sm {
  font-size: 80%;
}

/* ========= user avatars ========== */

/* ===== sizes: 25, 35, 40, 50, 60, 200, 300 ==== */

.avatar-wrap {
  display: inline-block;
  position: relative;
  font-size: 0;
}

.avatar {
  font-size: 0;
  overflow: hidden;
  display: inline-block;
}

.avatar img {
  border-radius: 100px;
}

/* ========= currencies/percentages ============ */

.currency-negative,
.percentage-negative {
  color: var(--red-color);
}

/* ============ oauth token issue ============= */

.oauth-token-issue {
  margin-top: 120px;
}

.oauth-token-issue-banner {
  background-color: #3ab1eb;
  border-bottom: 1px #29a0ea solid;
  margin-left: 70px;
  width: calc(100% - 70px);
  padding: 10px;
  color: #fff;
  font-size: 0.9em;
  text-align: center;
  line-height: 1.4em;
}

/* ========= ticks and crosses ========== */

.tick-red {
  color: #c33;
}

.tick-green {
  color: #3a3;
}

/* ========= misc ========== */

.no-wrap {
  white-space: nowrap;
}

.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.search-sort {
  margin: 20px 20px 70px 0;
}

.scroller-table {
  display: none;
}

.scroller-table.scroller-scrolling {
  display: table;
}

.scroller-scrolling {
  position: fixed;
  top: 60px;
  bottom: 0;
  overflow-y: scroll;
}

.long-text {
  word-break: break-all;
}

.datepicker .hidden,
.hidden {
  display: none !important;
}

.no-results {
  padding: 10px;
  font-size: 1.1em;
  color: #000;
}

.icon-pdf {
  background-repeat: no-repeat;
  background-image: url('../img/pdf.png');
  background-position: 0 0;
  height: 35px;
  width: 35px;
  vertical-align: middle;
}

.task-actions {
  white-space: nowrap;
}

.task-complete {
  text-decoration: line-through;
}

.box .name-val span.task-type-incomplete {
  display: inline;
  font-weight: bold;
  color: #eb5767;
}

.job-queue-working {
  text-align: center;
}

.job-queue-working h2 {
  display: block;
  border: none;
}

.circle-icon {
  border: 3px #fff solid;
  border-radius: 50%;
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: #ccc;
}

.circle-icon-tab {
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  background-color: #ccc;
  padding: 10px 11px;
  line-height: 0px;
  cursor: pointer;
}

.circle-icon-blue {
  background-color: #3bb2ed;
}

.circle-icon-green {
  background-color: #92e439;
}

.circle-icon-red {
  background-color: #eb5767;
}

.circle-icon-orange {
  background-color: #ffab21;
}

.circle-icon-black {
  background-color: #000;
}

.actions a {
  text-decoration: none;
}

.fa-sync.soft {
  opacity: 0.6;
}

.sub-info {
  font-size: var(--font-size);
  color: var(--font-light);
  font-weight: var(--font-weight);
  font-style: italic;
  display: block;
}

/* ======= collapse ========== */
.accordion-group {
  margin-bottom: 2px;
  border: 1px solid var(--light-gray);
  border-radius: var(--radius);
}

.accordion-heading {
  border-bottom: 0;
}

.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}

.accordion-toggle {
  cursor: pointer;
  text-decoration: none;
}

.accordion-toggle p {
  font-family: monospace;
  font-size: 30px;
  color: rgb(44, 153, 210);
  margin: 0 0 20px;
}

.collapse:not([data-show-hide-target]) {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.2s ease-in;
  -moz-transition: height 0.2s ease-in;
  -ms-transition: height 0.2s ease-in;
  -o-transition: height 0.2s ease-in;
  transition: height 0.2s ease-in;
}

.collapse.in {
  height: auto;
}

.accordion-heading .btn-primary {
  margin-top: -15px;
  margin-right: -10px;
}

/* ======= landing pages ========== */

.landing h3 {
  font-size: 1.2em;
  border-bottom-width: 1px;
}

.landing ul {
  margin-left: 35px;
}

.landing a {
  letter-spacing: 0;
  text-decoration: none;
}

.landing a:hover {
  text-decoration: underline;
}

.landing a.btn:hover {
  text-decoration: none;
}

.landing li span,
.settings-content li span {
  margin-top: 0;
  margin-bottom: var(--lg-spacing);
  display: block;
  font-size: var(--font-size);
}

/* ========= rows ======== */

.row-inset {
  background-color: #fafbfc;
  display: flex;
  border-bottom: 1px solid #eee;
}

.row-inset > [class*=col-]:first-child,
.row-inset > [class*=span]:first-child {
  background-color: #fff;
  border-right: 1px #eee solid;
}

.row-inset > [class*=col-9]:first-child,
.row-inset > [class*=span9]:first-child {
  width: calc(74.46808510638297% - 1px);
}

.row-inset > [class*=col-8]:first-child,
.row-inset > [class*=span8]:first-child {
  width: calc(65.95744680851064% - 1px);
}

.row [class*="col-"]:first-child p,
.row [class*="span"]:first-child p {
  margin: 10px 20px;
}

.row [class*="col-"]:first-child .alert,
.row [class*="span"]:first-child .alert {
  margin: 20px;
}

.row [class*="col-"]:first-child .alert-summary,
.row [class*="span"]:first-child .alert-summary {
  margin: 0 20px;
}

.row-flex {
  display: flex;
}

.row-header h2 {
  margin-left: 20px;
}

/* ========= footer ============= */

.row.footer > div {
  margin-top: 25px;
  font-weight: 200;
}

/* ========= notes =========== */

.tab-pane#notes {
  padding: 20px 20px 20px 0;
}

.form-notes textarea {
  width: 800px;
  height: 500px;
}

/* ============== file viewing ================== */

.file-viewer-container {
  margin-top: 50px;
}

.file-viewer-container .navbar {
  height: 50px;
}

.file-viewer-container .navbar-brand {
  margin-top: 5px;
}

.file-viewer-container .container,
.file-viewer-container .container .row,
.file-viewer-container .container .row .col-12,
.file-viewer-container .container .row .span12 {
  height: 100%;
}

.pull-right .fileviewer-select {
  margin-top: 7px;
}

#fileviewer-display iframe {
  width: 100%;
  height: 100%;
}

.fileviewer-list ul {
  padding: 10px;
  list-style-type: none;
  margin: 0 0 10px;
}

.fileviewer-list li {
  margin: 8px 0 8px 0;
}

.fileviewer-list input {
  margin: 4px 0 4px;
  display: block;
}

.fileviewer-list .empty {
  font-style: italic;
  background-image: none;
  padding-left: 0;
}

.fileviewer-list .file input {
  float: left;
}

.fileviewer-list .file .delete {
  padding: 4px;
}

.fileviewer-list .inputs .file .delete {
  float: left;
  margin-top: 6px;
}

/* ========== content page header ============= */

.content-page-header {
  display: flex;
  margin: 5px 0 10px;
  min-height: 38px;
}

.content-page-header h2 {
  margin: 0 20px 0 0;
  line-height: 38px;
}

.content-page-header .btn-group,
.content-page-header > form,
.content-page-header > .btn {
  margin-left: 10px;
  float: right;
}

.content-page-header .btn-group .dropdown-menu {
  right: 0;
  left: auto;
}

/* ========== Info profile ============= */
.info-profile .info-card span {
  display: block;
  font-weight: var(--font-weight-bold);
}

.info-profile__avatar img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

.info-profile .team__name {
  font-weight: bold;
  padding-bottom: 6px;
}

/* ============== User Profile Editor ============== */

.role-editor h5,
.role-editor .padded-section {
  padding-left: 20px;
}

.role-features-list {
  padding: 10px 0 20px;
}

.role-features-list h5 {
  padding-left: 20px;
}

.role-features-list-item {
  position: relative;
  list-style-type: none;
}

.role-features-list-item .form-group {
  margin-bottom: 5px;
  margin-left: -6px;
}

.role-features-list-item p {
  font-size: 12px;
  margin: 0 0 5px 45px;
}

.role-features-list-item .checkbox-tick + label {
  margin-left: 20px;
}

.role-features-list-item .checkbox-tick + label + .label-text {
  position: absolute;
  left: 50px;
  top: 8px;
}

.role-features-list .role-features-list-item-highlighted {
  background-color: rgba(21, 255, 0, 0.11);
  transition: background-color 0.2s;
}

/* ============== dnd ============== */

.dnd-dropzone-wrapper,
.dnd-dropzone {
  position: relative;
  min-height: 200px;
}

.dnd-prompt {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(72, 189, 159, 0.3);
  font-size: 3.4em;
  color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  text-align: center;
  display: none;
}

.dnd-prompt .wrap {
  display: table;
  width: 100%;
  height: 100%;
}

.dnd-prompt .inner {
  display: table-cell;
  vertical-align: middle;
}

.dnd-hover .dnd-prompt {
  display: block;
}

/* =========== carrier templates ========== */

.form-carrier-template .edit-control {
  margin-left: 20px;
}

.form-carrier-template .text-error {
  color: #eb5767;
  padding-top: 10px;
  display: block;
}

.form-carrier-template p {
  line-height: 1.4em;
  margin: 0 20px 20px 20px;
}

.carrier-templates .search {
  padding: 20px;
}

.carrier-templates .search input[type=text] {
  min-width: 200px;
  max-width: 200px;
}

.carrier-templates .search label {
  font-weight: bold;
  display: inline;
  margin-right: 30px;
}

.carrier-templates input.inactive {
  float: none;
  margin: 0 10px 0 10px;
}

.carrier-templates td {
  cursor: pointer;
  color: #000;
}

.carrier-templates td:hover {
  text-decoration: underline;
}

.carrier-templates td.status:hover {
  text-decoration: none;
}

.modal-carrier-templates textarea {
  width: 400px;
  height: 150px;
}

.carrier-template .adjustments .headers {
  margin-top: 15px;
}

.carrier-template .adjustments .headers div {
  font-weight: bold;
}

/* ============ arrow heads ============= */

.arrow-up,
.arrow-down,
.arrow-left,
.arrow-right {
  display: inline-block;
  width: 0;
}

.arrow-up {
  height: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.arrow-down {
  height: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
}

.arrow-right {
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
}

.arrow-left {
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
}

.arrow-small.arrow-up,
.arrow-small.arrow-down,
.arrow-small.arrow-right,
.arrow-small.arrow-left {
  border-width: 5px;
}

.arrow-white.arrow-up {
  border-bottom-color: #fff;
}

.arrow-white.arrow-down {
  border-top-color: #fff;
}

.arrow-white.arrow-right {
  border-left-color: #fff;
}

.arrow-white.arrow-left {
  border-right-color: #fff;
}

/* ============= clearing ============ */

.clearing .address {
  list-style-type: none;
}

.clearing .address li {
  padding-bottom: 8px;
}

.clearing .address li:last-child {
  padding-bottom: 0;
}

.clearing .address label,
.clearing .address span {
  display: inline-block;
  vertical-align: top;
}

.clearing .address label {
  font-weight: bold;
  width: 20%;
}

.clearing .current .address {
  margin: 20px 0 20px 40px;
}

/* ============= agencies ============ */

.agency-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--thin-spacing);
  margin-top: -3px;
}

.agency-header .producers .avatar {
  border-radius: 100px;
}

.agency-overview .row-map {
  margin-top: 20px;
}

.agency-overview .row-outset {
  background-color: #e9eff3;
  padding: 20px 0 0 0;
}

.agency-overview .box .empty,
.agency-overview .box .top {
  padding: 20px;
}

.agency-overview .box-outreach .body,
.agency-overview .box-outreach .footer {
  padding: 20px;
}

.agency-overview .box-outreach .footer {
  text-align: right;
}

.agency-overview .box-producers ul {
  list-style-type: none;
}

.agency-overview .box-producers li {
  padding: 10px 0 0 20px;
}

.agency-overview .box-producers li:first-child {
  padding-top: 20px;
}

.agency-overview .box-producers li:last-child {
  padding-bottom: 20px;
}

.agency-overview .box-producers li .avatar {
  vertical-align: middle;
  border-radius: 3px;
  margin-right: 10px;
}

.agency-overview .no-growth {
  opacity: 0.5;
}

.label-ap {
  padding: 4px 8px;
}

.label-ap-active {
  background-color: #92e439;
}

.label-ap-invited {
  background-color: #ffab21;
}

.label-ap-disabled {
  background-color: #eb5767;
}

/* ============== carriers ============= */

.carrier-or-marketing-company-overview .row-map {
  background-color: #e9eff3;
  padding: 20px 0 0 0;
}

.carrier-or-marketing-company-overview .top-binds {
  list-style-type: none;
  padding: 10px 0;
}

.carrier-or-marketing-company-overview .top-bind {
  padding: 10px 20px;
}

.carrier-or-marketing-company-overview .top-bind .date-bound {
  float: right;
  color: #ccc;
}

.carrier-or-marketing-company-overview .top-bind .avatar {
  border-radius: 2px;
  margin-right: 10px;
}

.carrier-or-marketing-company-overview .top-bind .avatar-wrap,
.carrier-or-marketing-company-overview .top-bind .inner {
  display: inline-block;
  vertical-align: top;
}

.carrier-or-marketing-company-overview .top-bind .policy-num {
  margin-bottom: 10px;
}

.carrier-or-marketing-company-overview .top-bind .policy-num a {
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
}

.carrier-or-marketing-company-overview .no-results {
  text-align: center;
  font-style: italic;
  font-size: 1em;
}

.pre-bind-notes ul {
  padding: 10px 20px;
}

.modal-carrier-pre-bind ul {
  margin-left: 20px;
}

.carrier-file-tags p {
  margin: 0 0 20px 20px;
}

.carrier-file-tags .row-headers {
  font-weight: bold;
}

/* ============ insureds =========== */

.listing-insureds .doing-business-as {
  font-style: italic;
  font-size: 0.9em;
  margin-top: 5px;
}

/* ========== Coverage Codes ========= */

.no-decoration {
  text-decoration: none;
}

/* ============== search ============== */

.live-search {
  display: none;
}

.live-search-content {
  border-radius: 10px;
}

.live-search-options {
  margin: 32px 20px 0 12px;
  color: #fff;
}

.live-search-options h3 {
  margin: 0 10px 0 0;
  border: 0;
  font-size: 1em;
}

.live-search-options label {
  font-size: 0.8em;
}

.live-search-content {
  margin-left: 70px;
}

.live-search-content h2 {
  font-size: 1.2em;
}

.live-search-content .listing {
  font-size: 0.9em;
}

.live-search-content .address {
  font-style: italic;
  margin-bottom: 5px;
}

.live-search-visible .main-column,
.live-search-visible .error-page,
.form-search .btn-cancel,
.live-search-visible .live-search .loading {
  display: none;
}

.live-search-visible .live-search-content,
.live-search-visible .live-search .results {
  display: block;
}

.live-search-visible .live-search-options,
.live-search-visible .btn-cancel {
  display: inline-block;
}

.live-search .no-results,
.live-search .loading {
  padding: 20px;
  font-size: 2em;
}

.live-search h2 {
  border: none;
  font-weight: bold;
  margin: 20px 15px;
}

.live-search .search-results-filters {
  background: var(--light-gray-bg);
  padding: 1rem 0;
}

.live-search .search-results-filters .form-group {
  margin-bottom: 0;
}

.live-search .search-results-table {
  line-height: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
}

.live-search .search-all-results {
  margin: 20px 20px 0;
  line-height: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
}

.live-search .search-results-table .results-table-header {
  background-color: #f9fafb;
  text-transform: capitalize;
  border-radius: 6px;
  cursor: pointer;
}

.live-search .search-results-table .results-table-header h2 {
  display: inline-block;
}

.live-search .search-results-table .results-table-header a {
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
}

.live-search .search-results-table .results-table-footer {
  background-color: #f9fafb;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.live-search .search-results-table > .collapse {
  overflow: hidden;
  margin-left: -20px;
  padding-left: 20px;
}

.live-search .row-cancel .col-6:last-child,
.live-search .row-cancel .span6:last-child {
  text-align: right;
  padding: 20px;
}

.live-search .show-detail {
  margin: 20px;
  display: inline-block;
}

.live-search .show-detail span {
  text-transform: capitalize;
}

.live-search .chevron {
  margin-left: 25px;
}

.live-search h2 {
  color: #4b5167;
}

.live-search .show-detail,
.live-search .nocoll,
.live-search .nocoll h2 {
  color: #3ab1eb;
}

.live-search .nocoll .chevron {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.live-search .max3 .listing li:nth-child(n+3) {
  display: none;
}

.live-search .direct-hit {
  display: block;
  color: #ffab21;
  font-size: 11px;
  font-weight: bold;
}

.live-search .box-header-data .direct-hit {
  background: #ffab21;
  color: white;
  border-radius: 3px;
  padding: 8px 16px;
  margin-left: 10px;
}

.live-search .expandable-row.expanded td {
  background-color: #3ab1eb;
}

.live-search .filters-horizontal .control-group {
  display: inline-block;
}

.live-search .filters-title {
  font-size: 14px;
  font-weight: 500;
  margin: 9px 5px;
}

.live-search .search-suggestions {
  background: white;
  margin: 20px;
  padding: 40px;
}

.live-search .search-suggestions h2 {
  margin: 20px 5px;
}

.live-search .search-suggestions svg {
  font-size: 22px;
  vertical-align: top;
  opacity: 0.7;
  min-width: 26px;
  margin-right: 10px;
}

.live-search .search-suggestions ul {
  list-style: none;
}

.live-search .search-suggestions ul li {
  padding: 6px;
}

.live-search li .search-suggestions-item {
  display: inline-block;
  max-width: calc(100% - 50px);
}

.live-search li .search-suggestions-item a {
  font-size: 16px;
  text-decoration: none;
  color: #3ab1eb;
  font-weight: bold;
}

.live-search li .search-suggestions-item span {
  margin-top: 5px;
  display: block;
  font-size: 0.8em;
}

.live-search-suggestions .live-search .search-results-filters {
  display: none;
}

/* ============= tax playground ============= */

.tax-playground .name-val {
  margin: 0 0 0 20px;
  list-style-type: none;
}

.tax-playground .name-val li {
  padding: 0 0 20px;
  font-size: 1.3em;
}

.tax-playground .name-val label {
  font-weight: bold;
  width: 40%;
  display: inline-block;
}

.tax-playground-source-1 #tax_playground_admitted_control_group,
.tax-playground-source-1 #tax_playground_broker_fee_taxable_control_group,
.tax-playground-source-1 #tax_playground_company_inspection_fee_taxable_control_group,
.tax-playground-source-2 #tax_playground_we_file_taxes_control_group,
.tax-playground-source-2 #tax_playground_california_control_group {
  pointer-events: none;
  opacity: 0.5;
  color: rgba(200, 0, 0, 0.5);
}

.tax-playground .intro {
  line-height: 1.4em;
}

/* ============== assignees =============== */

.assignees {
  list-style-type: none;
}

.assignee,
.box .assignees .assignee {
  padding: 0;
  margin-bottom: 10px;
}

.box .assignees .assignee .avatar-link {
  display: inline-block;
}

.assignee:last-child {
  margin-bottom: 15px;
}

.assignee .avatar-wrap,
.assignee span {
  vertical-align: middle;
}

.assignee .avatar {
  border-radius: 2px;
}

.assignee span {
  display: inline-block;
}

.no-results-assignees {
  color: #a3a3a3;
  padding: 0 0 10px 0;
  display: inline-block;
  font-size: 1em;
}

.form-assignees .control-group {
  margin-bottom: 0;
}

.form-assignees .select2-container {
  width: calc(60% - 27px);
}

.form-assignees .delete {
  text-align: left;
  padding-top: 5px;
}

.form-assignees .select2-container .select2-choice {
  height: 30px;
  line-height: 30px;
  background: transparent;
}

.form-assignees .select2-container-disabled .select2-choice {
  border: none;
  height: auto;
  padding-left: 12px;
}

.form-assignees .select2-container-disabled .select2-arrow {
  display: none;
}

.form-assignees li.select2-search-choice {
  float: none;
}

.form-assignees .avatar-wrap,
.form-assignees .name {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
}

.form-assignees .avatar {
  border-radius: 100px;
}

.form-assignees .name {
  margin-left: 3px;
}

.form-assignees .modal-body .collection-item,
.form-assignees .modal-body .collection-item > div,
.form-policy-users .collection-item,
.form-policy-users .collection-item > div,
.form-assignees-application .collection-item,
.form-assignees-application .collection-item > div {
  display: flex;
  justify-items: center;
  padding: 0;
  margin: 0;
  border: none;
}

.form-assignees .modal-body fieldset.form-group,
.form-policy-users fieldset.form-group,
.form-assignees-application fieldset.form-group {
  margin-bottom: 0;
}

.form-assignees .modal-body .collection-item > div,
.form-policy-users .collection-item > div,
.form-assignees-application .collection-item > div {
  flex-grow: 1;
}

.form-policy-users .collection-item > div .form-group,
.form-assignees-application .collection-item > div .form-group,
.form-assignees .modal-body .collection-item > div .form-group {
  margin-right: 10px;
  width: calc(50% - 10px);
  box-sizing: content-box;
  flex-grow: 1;
}

.form-assignees .modal-body .collection-item > div .form-group .select2-container,
.form-policy-users .collection-item > div .form-group .select2-container,
.form-assignees-application .collection-item > div .form-group .select2-container {
  width: 100%;
  box-sizing: border-box !important;
}

.form-assignees .modal-body .collection-item .delete {
  margin-top: 5px;
}

.form-assignees .modal-body .collection-item .delete {
  margin-left: auto;
}

.form-assignees .modal-body .collection-item .select2-choice,
.form-policy-users .collection-item .select2-choice,
.form-assignees-application .collection-item .select2-choice {
  min-height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: var(--font-size-heading);
}

.form-assignees .modal-body .collection-item .select2-container-disabled .select2-choice {
  background-color: transparent;
}

.form-assignees .modal-body .collection-item .select2-container {
  border: 1px solid var(--light-gray);
  padding: var(--thin-spacing);
  width: calc(50% - 10px);
  margin-right: 10px;
}

.form-assignees .modal-body .collection-item .select2-container.error {
  border-color: var(--danger-color);
}

.form-assignees .modal-body .collection-item .select2-container-disabled {
  border: none;
}

.form-assignees .modal-body .collection-item .avatar {
  border-radius: 100px;
}

.form-assignees .collection-item:first-child {
  margin-top: 5px;
}

.form-assignees .collection-item:last-child {
  margin-bottom: 10px;
}

.form-assignees .collection-item .control-group,
.form-policy-users .collection-item .control-group {
  display: inline-block;
  width: calc(50% - 60px);
}

.form-assignees .collection-item .control-group .select2-container {
  width: 100% !important;
}

.modal-assignees .modal-body {
  padding: 10px 0 15px 0;
}

/* ============== accounting ============= */

.multi-select-transactions {
  z-index: 1;
}

.multi-select-transactions .btn-cancel {
  position: absolute;
  width: 71px;
  left: 0;
  height: 100%;
  background: #e55144;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  font-size: 9px;
}

.multi-select-transactions .btn-cancel i {
  font-size: 18px;
}

.transaction-table-loading .transaction-item,
.transaction-table-loading .payment-entry {
  display: none;
}

.transaction-table-loader td {
  background-image: url(../img/3rdparty/select2/select2-spinner.gif);
  background-repeat: no-repeat;
  background-position: 20px center;
  padding-left: 45px !important;
}

.transaction-table-summary .header-summary {
  margin-top: 25px;
}

.transaction-table-summary .name-val {
  list-style-type: none;
  margin: 0 15px;
}

.transaction-table-summary .name-val label,
.transaction-table-summary .name-val span {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
}

.transaction-table-summary .name-val label {
  width: 280px;
  font-weight: bold;
}

.transaction-table .red-value {
  color: #c33;
}

.transaction-table .tick-value {
  color: #3a3;
  font-size: 20px;
}

.transaction-table .tick-value.amber {
  color: #ffab21;
  cursor: default;
}

.transaction-table .success-value {
  color: #68ca2d;
  font-size: 10px;
  font-weight: bold;
  padding: 0;
}

.transaction-table td.td-action {
  text-align: center;
}

.transaction-table .border-left {
  border-left: 1px solid #eee;
  padding-left: 10px;
}

.transaction-table tr.results-row {
  font-weight: bold;
}

.transaction-table td.td-action .btn-primary {
  min-width: 90%;
}

.transaction-table .border-left {
  border-left: 1px solid #eee;
  padding-left: 10px;
}

.transaction-table tr.results-row {
  font-weight: bold;
}

.transaction-table tr.results-row.filtered {
  display: table-row;
}

.transaction-table td.td-action .btn-primary {
  min-width: 90%;
}

.transaction-table-pagination {
  text-align: right;
}

.transaction-table-pagination input[type=text] {
  min-width: 50px;
  max-width: 50px;
}

.accounting-payment-details .row-pre-table,
.transaction-table .row-pre-table {
  background: #fff;
}

.transaction-table .row-pre-table [class*=col-],
.transaction-group-table .row-pre-table [class*=col-],
.transaction-table .row-pre-table [class*=span],
.transaction-group-table .row-pre-table [class*=span] {
  padding: 20px;
}

.transaction-table .control-section-select .switch-field label,
.transaction-group-table .control-section-select .switch-field label {
  padding: 8px;
}

.transaction-entries .no-results {
  display: table-row;
}

.transaction-entries .acc-table-sm {
  width: 3%;
}

.transaction-entries .acc-table-md {
  width: 11%;
}

.transaction-entries .acc-table-lg-sub {
  width: 6%;
}

.transaction-entries .acc-table-sm-sub {
  width: 2.75%;
}

@media (min-width: 1200px) {
  .transaction-entries .acc-table-sm {
    width: 3%;
  }

  .transaction-entries .acc-table-md {
    width: 8.25%;
  }

  .transaction-entries .acc-table-lg-sub {
    width: 7%;
  }

  .transaction-entries .acc-table-sm-sub {
    width: 4%;
  }
}

.transaction-loading {
  margin: 20px 20px 20px 0;
}

.transaction-loading .bar {
  white-space: nowrap;
  line-height: 20px;
}

.transaction-loading .message {
  font-style: italic;
  margin: 10px 0;
}

.more-links {
  padding: 20px 20px 0;
}

.more-links .btn-group,
.more-links > .btn {
  margin-left: 10px;
  float: right;
}

.modal-transaction-group-summary .modal-body {
  margin-top: 20px;
}

.modal-transaction-group-summary ul.name-val {
  padding-left: 20px;
}

.modal-transaction-group-summary h2 {
  border: 0;
  border-left: 3px #3bb2ed solid;
  padding-left: 10px;
}

.modal-transaction-group-summary .modal-body .name-val label {
  width: 100px;
}

.modal-transaction-group-summary .modal-body .name-val span {
  width: calc(100% - 115px);
}

.zero-apply {
  text-decoration: line-through;
  color: #aaa;
}

.transaction-table-load-all {
  font-size: 0.9em;
}

.payment-entries .is-payable,
.transaction-table .is-payable {
  text-align: center;
}

.payment-entries .is-payable .inner,
.transaction-table .is-payable .inner {
  display: none;
}

.payment-entries .is-payable-loaded .inner,
.transaction-table .is-payable-loaded .inner {
  display: inline-block;
}

.payment-entries .is-payable-loading .outer,
.transaction-table .is-payable-loading .outer {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url(../img/3rdparty/select2/select2-spinner.gif);
}

.transaction-table-new-items td {
  background-color: #fea;
  padding: 10px;
  font-size: 0.9em;
  text-align: center;
}

.transaction-table-new-items a {
  cursor: pointer;
  text-decoration: underline;
}

.accounting-offset-message {
  float: left;
  padding: 10px;
}

.accounting-offset-message label {
  font-weight: bold;
}

.modal-accounting-offset [type=submit] {
  background-color: #3ab1eb;
}

.modal-accounting-offset .row-controls {
  padding-top: 20px;
}

/* ================ customers ================ */

.customer-badge {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  text-align: center;
  font-size: 10px;
  line-height: 14px;
  font-weight: bold;
  color: #fff;
  border: 1px transparent solid;
}

.customer-badge-marketing-company {
  background-color: #999;
  border-color: #888;
}

.customer-badge-marketing-company::before {
  content: "M";
}

.customer-badge-agency {
  background-color: #d178ed;
  border-color: #c177de;
}

.customer-badge-agency::before {
  content: "A";
}

.customer-badge-supplier {
  background-color: #fc9;
  border-color: #efcb88;
}

.customer-badge-supplier::before {
  content: "s";
}

.customer-badge-tax-authority {
  background-color: #3d9043;
  border-color: #39803e;
}

.customer-badge-tax-authority::before {
  content: "T";
}

.customer-badge-insured {
  background-color: #3bb2ed;
  border-color: #38c2dc;
}

.customer-badge-insured::before {
  content: "I";
}

.customer-inline .customer-badge {
  margin-right: 5px;
  margin-bottom: 5px;
  float: left;
}

.customer-inline .customer-name {
  line-height: 1.4em;
}

/* ================ contacts ================ */

.contact-badge {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 42px;
  border-radius: 3px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: #fff;
}

.contact-badge-agent {
  background-color: #3dc4f3;
}

.contact-badge-agent::before {
  content: "A";
}

.contact-badge-inspection {
  background-color: #999;
}

.contact-badge-inspection::before {
  content: "I";
}

.contact-badge-marketing {
  background-color: #eeb310;
}

.contact-badge-marketing::before {
  content: "M";
}

/* =============== control sections ================= */

.control-section {
  display: none;
}

.control-section-visible {
  display: block;
}

th.control-section-visible,
td.control-section-visible {
  display: table-cell;
}

th.control-section,
td.control-section {
  text-align: center;
  width: 100px;
}

/* ============== z-indexes ========== */

#topbar {
  z-index: 1010; /* prevents tom-select going above navbar */
}

.mailpane,
.table-listing-tools {
  z-index: 1015;
}

.dropdown-menu {
  z-index: 1000;
}

.dropdown-background {
  z-index: 990;
}

.alert-dismissible {
  z-index: 130;
}

.navbar,
.loader .mask,
.nav-main {
  z-index: 120;
}

.nav-tabs {
  z-index: 110;
}

.live-search {
  z-index: 6;
}

.nav-pills {
  z-index: 4;
}

.editor .ui-draggable-dragging {
  z-index: 3;
}

.image-tools,
.shape-tools,
.font-tools {
  z-index: 2;
}

.select-files,
.multi-select-visible,
.template-editor .mode {
  z-index: 1;
}

/* =========== Progressor ============ */

@-webkit-keyframes progressor-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-moz-keyframes progressor-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-ms-keyframes progressor-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-o-keyframes progressor-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 40px 0;
  }
}

@keyframes progressor-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progressor {
  height: 20px;
  overflow: hidden;
  background-color: #f7f7f7;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progressor-inline {
  display: inline-block;
  width: 100%;
}

.progressor .bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e90d2;
  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
  background-image: -webkit-linear-gradient(top, #149bdf, #0480be);
  background-image: -o-linear-gradient(top, #149bdf, #0480be);
  background-image: linear-gradient(to bottom, #149bdf, #0480be);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.8s ease;
  -moz-transition: width 0.8s ease;
  -o-transition: width 0.8s ease;
  transition: width 0.8s ease;
}

.progressor .bar + .bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
}

.progressor-striped .bar {
  background-color: #149bdf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progressor.active .bar {
  -webkit-animation: progressor-bar-stripes 2s linear infinite;
  -moz-animation: progressor-bar-stripes 2s linear infinite;
  -ms-animation: progressor-bar-stripes 2s linear infinite;
  -o-animation: progressor-bar-stripes 2s linear infinite;
  animation: progressor-bar-stripes 2s linear infinite;
}

.progressor-danger .bar,
.progressor .bar-danger {
  background-color: #dd514c;
  background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#c43c35));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: linear-gradient(to bottom, #ee5f5b, #c43c35);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffc43c35', GradientType=0);
}

.progressor-danger.progressor-striped .bar,
.progressor-striped .bar-danger {
  background-color: #ee5f5b;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progressor-success .bar,
.progressor .bar-success {
  background-color: #5eb95e;
  background-image: -moz-linear-gradient(top, #62c462, #57a957);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#57a957));
  background-image: -webkit-linear-gradient(top, #62c462, #57a957);
  background-image: -o-linear-gradient(top, #62c462, #57a957);
  background-image: linear-gradient(to bottom, #62c462, #57a957);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff57a957', GradientType=0);
}

.progressor-success.progressor-striped .bar,
.progressor-striped .bar-success {
  background-color: #62c462;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progressor-info .bar,
.progressor .bar-info {
  background-color: #4bb1cf;
  background-image: -moz-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#339bb9));
  background-image: -webkit-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -o-linear-gradient(top, #5bc0de, #339bb9);
  background-image: linear-gradient(to bottom, #5bc0de, #339bb9);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff339bb9', GradientType=0);
}

.progressor-info.progressor-striped .bar,
.progressor-striped .bar-info {
  background-color: #5bc0de;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progressor-warning .bar,
.progressor .bar-warning {
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
}

.progressor-warning.progressor-striped .bar,
.progressor-striped .bar-warning {
  background-color: #fbb450;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

/* ================= Sort and filter =============== */

.box-filter .title h3 {
  border-bottom: none;
  margin: 15px;
  font-size: var(--large-font-size);
  font-weight: var(--font-weight-thin);
  letter-spacing: 0;
}

/* ================= forecasts =============== */

.forecast-form input.month-input[type=text] {
  min-width: 20px;
  max-width: 50px;
}

.forecast-form .text-error {
  color: #eb5767;
}

/* ======= Mobile full menu ========== */

.full-menu {
  background: #191919;
  width: 90%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  overflow: auto;
}

.full-menu h2 {
  font-size: 40px;
  padding: 27px;
  margin: 0;
  color: #fff;
}

.full-menu .btn-icon-close {
  float: right;
  width: 94px;
  height: 94px;
  background: #3ab1eb;
  border-radius: 0;
  border: none;
  position: fixed;
  top: 0;
  right: 0;
}

.full-menu-content {
  padding: 27px;
}

.full-menu-content .divider {
  border-bottom: 2px solid #333;
  margin: 0 0 20px;
}

.full-menu-content li {
  opacity: 0.65;
  padding-bottom: 23px;
}

.full-menu-content a {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}

.full-menu-content li.active {
  opacity: 1;
}

.full-menu-content .division-selector-mobile {
  color: #3ab1eb;
  text-transform: uppercase;
  padding: 20px 0;
  font-weight: bold;
  letter-spacing: 2px;
}

.full-menu-content .sub-list-mobile {
  margin: 20px;
}

.full-menu-btn {
  font-size: 25px;
  color: #86898d;
  margin: 11px;
}

.full-menu-btn i {
  margin-top: -2px;
}

.search-bar-btn {
  font-size: 20px;
  color: #3bb2ed;
  border-right: 1px solid #86898d;
  padding: 0 17px;
}

@media (max-width: 979px) {
  #search-bar-block {
    display: none;
  }

  #search-bar-block .form-search {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100vw;
    background: #2a3342;
    border-radius: 0;
    border-top: 1px solid var(--dark-gray);
  }

  #search-bar-block .form-search input[type=text],
  #search-bar-block .form-search input[type=text]:focus {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  #search-bar-block .form-search .select2 .select2-choice {
    width: 100%;
    text-align: left;
  }

  #search-bar-block .form-search .select2 {
    width: 50%;
    float: left;
  }

  #search-bar-block .form-search i.fa-chevron-down {
    width: 8%;
    margin: 10px 0;
  }

  #search-bar-block .btn-cancel {
    width: 40%;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
  }
}

@media (min-width: 979px) {
  #search-bar-block {
    display: block !important;
  }
}

.menu-toggle-content {
  display: none;
}

/* ========== Chevron ============= */

.army-chevron {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 200px;
  height: 16px;
  position: relative;
  margin: 0 0 6px;
  padding: 12px;
  border: none;
  font: normal 100%/normal Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  -o-text-overflow: clip;
  text-overflow: clip;
}

.army-chevron::before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 51%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border: none;
  font: normal 100%/normal Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 1);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #1abc9c;
  text-shadow: none;
  -webkit-transform: skewY(21deg);
  transform: skewY(21deg);
}

.army-chevron::after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 50%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  border: none;
  font: normal 100%/normal Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 1);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #1abc9c;
  text-shadow: none;
  -webkit-transform: skewY(-21deg);
  transform: skewY(-21deg);
}

/* ========== Fonts ============= */

@font-face {
  font-family: Gotham;
  src: url('../../../../../../../assets/fonts/Gotham-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: Gotham;
  src: url('../../../../../../../assets/fonts/Gotham-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url('../../../../../../../assets/fonts/Gotham-Book.otf');
  font-weight: 400;
}

@font-face {
  font-family: Gotham;
  src: url('../../../../../../../assets/fonts/Gotham-Medium.otf');
  font-weight: 700;
}

@font-face {
  font-family: Gotham Book;
  src: url('../../../../../../../assets/fonts/Gotham-Light.otf');
}

@font-face {
  font-family: Gotham Black;
  src: url('../../../../../../../assets/fonts/Gotham-Bold.otf');
}

/* ========== Inspections ============= */

.inspection-documents {
  list-style-type: none;
  margin-right: 20px;
}

/* ========== Form Set Viewer =============== */

.formset-viewer-container {
  margin-top: 50px;
}

.formset-viewer-container .navbar {
  height: 50px;
}

.formset-viewer-container .navbar-brand {
  margin-top: 5px;
}

.pull-right .formset-viewer-selected-template {
  margin-top: 7px;
}

.formset-viewer-display-container,
.formset-viewer-display-container iframe {
  height: 100%;
}

.formset-viewer-display-container {
  padding: 0;
}

.formset-viewer-display-container iframe {
  width: 100%;
}

.formset-viewer-empty {
  text-align: center;
  margin-top: 100px;
}

/* ========== select2 =============== */

.select2-container-multi .select2-choices .select2-search-field input {
  font-family: inherit;
}

/* ========== Responsive ============= */

@media (max-width: 1200px) {
  .form-horizontal .controls {
    margin-left: 0;
  }

  .form-horizontal .subcontrols .controls {
    margin-left: 0;
  }
}

@media (min-width: 979px) {
  /* ============ layout -============== */
  [class*=col-].no-margin,
  [class*=span].no-margin {
    margin-left: 0;
  }

  .col-1.no-margin,
  .span1.no-margin {
    width: 8.51063829787%;
  }

  .col-2.no-margin,
  .span2.no-margin {
    width: 17.0212765957%;
  }

  .col-3.no-margin,
  .span3.no-margin {
    width: 25.53191489361702%;
  }

  .col-4.no-margin,
  .span4.no-margin {
    width: 34.042553191489%;
  }

  .col-5.no-margin,
  .span5.no-margin {
    width: 42.5531914893%;
  }

  .col-6.no-margin,
  .span6.no-margin {
    width: 51.063829787234%;
  }

  .col-7.no-margin,
  .span7.no-margin {
    width: 59.57446808510638%;
  }

  .col-8.no-margin,
  .span8.no-margin {
    width: 68.085%;
  }

  .col-9.no-margin,
  .span9.no-margin {
    width: 76.595532%;
  }

  .col-10.no-margin,
  .span10.no-margin {
    width: 85.1063829787%;
  }

  .main-column {
    margin-left: 75px;
  }

  /* ================= Search =============== */
  .live-search .search-results-filters {
    margin: 0 20px;
  }

  .live-search .search-results-table {
    margin: 0 20px 20px;
  }
}

@media (max-width: 767px) {
  body {
    margin-top: 60px;
  }

  h2 {
    margin: 10px 0;
  }

  .main-column {
    margin-left: 0;
    overflow: scroll;
  }

  .content-page-header .btn {
    margin: 10px 0;
  }

  /* ================= Search =============== */
  .live-search {
    margin: 110px -20px 0;
  }

  .live-search .filters {
    zoom: 80%;
  }

  .live-search-options {
    position: fixed;
    top: 112px;
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px 20px;
    background: #2a3342;
  }

  .live-search .search-results-table {
    overflow: auto;
  }
}

label > i.required {
  display: inline-block;
  color: #f13a30;
  font-weight: 700;
  margin-right: 6px;
}

.scrollable-form-section {
  margin-bottom: 12px;
}

.new-ui label,
.new-ui .select2-container {
  display: block;
}

.new-ui label.control-label {
  display: none;
}

.new-ui .control-group {
  padding-left: 0;
}

.calendar-container {
  padding: 8px 12px;
  color: #4b5167;
}

.new-ui .form-footer {
  padding: var(--mid-spacing);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.new-ui .actions a {
  color: #2c99d0;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}

.new-ui h2 {
  margin: 16px 16px 8px 16px;
}

.new-ui .side-navbar {
  background-color: #f9fafb;
}

.new-ui .tree-view {
  list-style: none;
  position: relative;
  padding: 8px 8px 8px 16px;
  margin-top: 16px;
}

.new-ui .tree-view > li {
  margin-bottom: 16px;
}

.new-ui .tree-view li {
  list-style: none;
  box-sizing: border-box;
  transition: all ease 1s;
}

.new-ui .tree-view > li > a {
  text-decoration: none;
  font-size: 1.1rem;
  padding-left: 8px;
}

.new-ui .tree-view > li.active > a {
  border-right: 1px solid #fafbfc;
  border-left: 4px solid #3ab1eb;
  color: #3ab1eb;
}

.new-ui .tree-view li ul li.active label,
.new-ui .tree-view li ul li label:hover {
  border-bottom: 1px solid #3ab1eb;
  font-weight: 700;
}

.affix {
  position: fixed;
  top: 80px;
  transition: all 1s ease;
}

.new-ui .tree-view li ul li label {
  cursor: pointer;
  margin-left: 16px;
  margin-bottom: 0;
  display: inline-block;
  border-bottom: 1px solid transparent;
}

.new-ui ::-webkit-scrollbar {
  width: 10px;
}

.new-ui .select2.is-invalid {
  border: 1px solid #dc3545;
  border-radius: 5px;
}

.new-ui ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.new-ui ::-webkit-scrollbar-thumb {
  background: #888;
}

.new-ui ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* =========== quick rater ============= */

.overflow-y-auto {
  overflow-y: auto;
}

.max-height-400 {
  max-height: 400px;
}

/**  ====================== brokerage manager =============== */

.form-brokerageSubmission-v3 .files-table .selected-column .form-group {
  margin-bottom: 0;
}

.brokerage-marketing-company label {
  cursor: pointer;
}

/*
 * Hack to fix select2's hiding of select/input controls from
 * totally preventing client side validation
 *
 * See https://github.com/select2/select2/issues/128
*/
select.select2.form-control:required,
input.ajax-select.form-control:required {
  display: block !important;
  position: fixed;
  top: -10px;
  height: 0;
  visibility: hidden;
  pointer-events: none;
  border-color: transparent;
  background: transparent;
  appearance: none;
}

/* ============== cost center selector ================== */

.modal-cost-centre-select .modal-content {
  max-height: calc(100vh - 50px);
}

.modal-cost-centre-select li {
  border-bottom: 1px #eee solid;
  list-style-type: none;
}

.modal-cost-centre-select li:last-child {
  border-bottom: none;
}

.control-group .cost-centre-display-name {
  flex: 0 0 calc(100% - 153px);
}

.control-group .cost-centre-display-name {
  border: none;
}

/* Engineer notes for reports, generally showing where a field comes from or how it's calculated */

.dev-notes {
  font-family: monospace;
  clear: left;
  display: block;
}

.bg-gray {
  background-color: #eee;
}

.recipients-controls {
  display: flex;
  justify-content: flex-end;
}

.recipients-controls a {
  text-decoration: underline !important;
  padding: 0 2px;
  margin-bottom: 1em;
}

#bcc-field,
#cc-field {
  display: none;
}

.primary-dark {
  color: var(--primary-dark);
}

.primary {
  color: var(--primary-color);
}

.padded-p p {
  padding: 7px 0 7px 0;
}

.inline-form,
.inline-form__fields,
.inline-form .control-group {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
  padding-right: 10px;
  justify-content: flex-end;
}

.inline-form__fields {
  flex-grow: 1;
}

.inline-form__fields .form-group {
  width: 50%;
  flex-grow: 1;
}

.inline-form .form-group {
  float: right;
  flex-grow: 1;
}

.inline-form .btn {
  flex-grow: 0;
  margin-left: 20px;
}

.inline-form .form-group > * {
  width: auto;
  float: left;
}

.inline-form .form-group .invalid-feedback {
  padding-left: 50px;
}

.inline-form .control-group {
  width: auto !important;
}

.inline-form .form-group input {
  width: calc(100% - 50px);
}

.inline-form .form-group label {
  margin-bottom: 0;
  margin-right: 10px;
  width: 40px;
  text-align: right;
  padding-top: 8px;
}

@media (max-width: 992px) {
  .inline-form {
    flex-wrap: wrap;
  }

  .inline-form__fields {
    display: block;
  }

  .inline-form .form-group input {
    width: 100%;
  }

  .inline-form .form-group .invalid-feedback {
    padding-left: 0;
  }

  .inline-form .form-group {
    width: 100%;
    padding-right: 0;
    padding-bottom: 10px;
  }

  .inline-form .form-group label {
    width: 25%;
    text-align: left;
  }
}

/** Terms and Conditions **/
.terms-conditions h3 {
  font-size: var(--h2-size);
  font-weight: var(--font-weight-thin);
  padding: 0.5rem 0;
}

.terms-conditions ol {
  padding-left: 0;
}

.terms-conditions li,
.terms-conditions p {
  margin-bottom: 1rem;
  font-size: var(--font-size-heading);
  line-height: 1.5rem;
}

.terms-conditions > li {
  margin-bottom: 0;
  padding-bottom: 40px;
  counter-increment: li1;
}

.terms-conditions > li::marker {
  font-size: 21px;
  font-weight: var(--font-weight-thin);
  content: "" counter(li1) ") ";
}

.terms-conditions > li ol li {
  counter-increment: li2;
}

.terms-conditions > li ol li::marker {
  content: "(" counter(li2, lower-alpha) ") ";
}

/** Content Scroll with Nav **/
.content-scroll {
  display: flex;
}

.content-scroll__nav {
  width: 200px;
  flex-shrink: 0;
}

.content-scroll__nav > .nav-list > li {
  border-left: 3px solid var(--primary-color);
}

.content-scroll__nav > .nav-list {
  background: var(--light-gray-bg);
  border: solid var(--light-gray);
  border-width: 1px 0 1px 1px;
  margin-right: -1px;
  position: sticky;
  top: var(--top-bar-height);
  z-index: 1;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.content-scroll__main {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  position: relative;
  background: var(--light-gray-bg);
  border: 1px solid var(--light-gray);
  scroll-behavior: smooth;
  flex-grow: 1;
}

.content-scroll__main.has-actions {
  overflow: hidden;
}

.content-scroll__main.has-actions .content-scroll__main-scroll {
  height: calc(100% - 70px);
  overflow-y: scroll;
}

.content-scroll__main-footer {
  padding: 1rem;
  bottom: 0;
  width: 100%;
  height: 70px;
  border-top: 1px solid var(--light-gray);
  background: var(--light-gray-bg);
}

/** Sign Up Terms and Conditions Page **/
.signup-terms {
  width: 95%;
  max-width: 1284px;
  margin: -50px auto 0 auto;
}

.signup-terms__header {
  text-align: center;
}

.signup-terms__header h1 img {
  width: 243px;
  height: 45px;
  margin: 10px auto;
}

.signup-terms__header h2 {
  font-size: 22px;
  color: #233966;
  margin-bottom: 16px;
}

.signup-terms .content-scroll__main,
.signup-terms .content-scroll__nav > .nav-list {
  height: calc(100vh - 300px);
  margin-right: 0;
}

.signup-terms__footer {
  margin-top: 16px;
  text-align: center;
}

.signup-terms__agree-label {
  border: 1px solid var(--font-light);
  background-color: var(--light-gray-bg);
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 16px !important;
}

/* ============= rating ============== */

.rating-conditions-preview .comparison {
  text-transform: lowercase;
}

.rating-conditions-preview span:nth-of-type(1) {
  font-weight: bold;
}

.status-rating {
  display: none;
}

.has-rating .rating-success .status-rating-success,
.has-rating .rating-skipped .status-rating-skipped,
.has-rating .rating-failure .status-rating-failure {
  display: inline-block;
}

.collapsible-header .status-rating {
  float: right;
  margin-right: 10px;
}

/* ============= tomselect ============== */

/* == workaround for: https://github.com/symfony/ux/pull/422 == */
.ts-wrapper.required .clear-button {
  display: none;
}

/** Admin User Settings **/
.new-api-key {
  background-color: #f9fafb;
  border: 1px solid #d7dee5;
}

li.api-key {
  border-bottom: 1px solid #d7dee5;
  padding-bottom: 12px;
  font-size: 0.9rem;
}

li.api-key:last-child {
  border-bottom: 0;
}

.aggregate-summary .td {
  color: #000;
}

.aggregate-summary .active {
  background-color: #f0f8fc;
}

.aggregate-summary .county-group.active {
  color: #000;
}

.aggregate-summary .zip-group {
  color: #888;
}

.aggregate-summary .total {
  background: #f9fafb;
  font-size: 13px;
}
