/* =============== lesson creator ============ */

.training-lesson-new {
  background-color: white;
}

.training-lesson-new h3 {
  margin-left: 0;
}

.training-lesson-new > form > div > div {
  padding: 30px;
}

.training-lesson-new > form .lesson-footer > div {
  padding: 20px;
}

.training-lesson-new .lesson-footer {
  background-color: #fafbfc;
  border-top: 1px solid #eee;
}

.training-lesson-new .control-group .select2-container {
  width: 100%;
}

.training-lesson-new .control-group .files-list .file {
  padding: 0;
  border: none;
}

.training-lesson-new .control-group {
  padding-left: 0;
}

.training-lesson-new .redactor_editor {
  min-height: 500px;
}

.training-lesson-new .form-last-row > div {
  padding-left: 0 !important;
}

.training-lesson-new .lesson-slides .add {
  margin-left: 0;
}

.training-lesson-new .lesson-slides .btn-small {
  padding: 4px 6px 4px;
}

/* =============== lesson show ============ */
.lesson-show h2 {
  padding-left: 50px;
}

.lesson-show .files-section-title {
  border-bottom: 1px solid #eee;
  width: 100%;
}

.lesson-show .files-section-title h2 {
  margin: 0;
  padding: 25px 15px 20px;
}

.lesson-show .files-list {
  padding: 5px;
}

.lesson-content {
  padding: 20px;
}

.lesson-heading {
  text-align: center;
  margin-bottom: 30px;
}

.lesson-heading h2.tag-label {
  border: none;
}

/* progressbar */
.slide-info {
  float: right;
  margin-top: -65px;
  text-align: center;
}

.progressbar {
  margin-bottom: 30px;
  overflow: hidden;

  /* CSS counters to number the steps */
  counter-reset: step;
}

.progressbar li {
  list-style-type: none;
  text-transform: uppercase;
  font-size: 9px;
  width: 55px;
  float: left;
  position: relative;
}

.progressbar li::before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #333;
  border: solid 1px #bbb;
  border-radius: 3px;
  margin: 0 auto 5px auto;
}

/* progressbar connectors */
.progressbar li::after {
  content: '';
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /* put it behind the numbers */
}

.progressbar li:first-child::after {
  /* connector not needed before the first step */
  content: none;
}

/* The number of the step and the connector before it = green */
.progressbar li.active::before,
.progressbar li.active::after {
  background: #3ab1eb;
  color: white;
  border-color: #3ab1eb;
}

.lesson .row [class*="span"]:first-child p {
  margin: 0;
}

/* =============== quiz show ============ */

.quiz-collection-items .collection-item {
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
}

.quiz-collection-items .collection-item .delete {
  margin-top: 35px;
}

.quiz-question label {
  display: block;
  margin-bottom: 20px;
}

.quiz-question.no-last {
  border-bottom: 1px solid #eee;
}

.quiz-question.success {
  background: rgba(0, 255, 20, 0.05);
}

.quiz-question.fail {
  background: rgba(255, 0, 0, 0.06);
}

.quiz-question > div {
  padding: 30px;
}

.quiz-question-content label {
  font-weight: bold;
}

.quiz-actions {
  padding: 20px;
  border-top: 1px solid #eee;
}

.quiz-actions button {
  margin-right: 10px;
}

.quiz-results .mark {
  font-size: 30px;
  font-weight: bold;
}

.quiz-results li {
  font-size: 20px;
  list-style: none;
  margin-bottom: 20px;
}

.result-message {
  padding: 20px 50px;
}

.result-message h5.pass {
  color: #37ad37;
}

.result-message h5.fail {
  color: #ff6363;
}

.result-message h5 {
  margin: 20px 0;
  font-size: 20px;
}

/* =============== Learning sidebar ============ */

.learning-sidebar ul {
  padding: 0 20px;
}

.learning-sidebar ul.pending-lessons li {
  list-style: none;
  padding: 23px 0;
  border-top: 1px solid #ddd;
}

.learning-sidebar ul.pending-lessons li a {
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: #4f556a;
}

.learning-sidebar ul.pending-lessons li p {
  font-size: 13px;
}

.learning-sidebar ul.filter-tags {
  overflow: auto;
}

.learning-sidebar ul.filter-tags li {
  float: left;
  margin: 10px 10px 10px 0;
  list-style: none;
}

.learning-sidebar ul.filter-tags .checkbox {
  display: none;
}

.learning-sidebar ul.filter-tags .checkbox + label {
  border: 1px solid #777;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.6;
}

.learning-sidebar ul.filter-tags .checkbox:checked + label {
  font-weight: bold;
  opacity: 1;
}

/* =============== lesson grid ============ */

.training h2 {
  margin: 20px;
  font-size: 30px;
}

.training .listing li {
  background-color: #fff;
  border: none;
  margin-bottom: 40px;
  border-radius: 5px;
  position: relative;
}

.training .listing li.iscroll-item {
  width: 31.3%;
  float: left;
  margin: 10px 1%;
}

.training .listing li.iscroll-item.lesson-completed h3 {
  opacity: 0.5;
}

.training .listing li h3 a {
  word-break: normal;
  color: #4b5167;
  font-size: 1em;
}

.training .listing .content {
  font-size: 1.2em;
  overflow: hidden;
  position: relative;
}

.training .listing .title .content {
  height: 100px;
}

.training .listing .body .content {
  height: 15px;
  font-size: 13px;
}

.training .listing .footer {
  background-color: #fafbfc;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px #fff solid;
  border-top: 1px #eee solid;
  padding: 10px;
}

.training .listing .footer .lesson-tools .btn {
  margin-left: 5px;
}

.training .listing .footer a {
  text-decoration: none;
}

.training .iscroll-content .col-4:nth-child(3n+1),
.training .iscroll-content .span4:nth-child(3n+1) {
  margin-left: 0;
}

.training .title-button {
  margin: 20px 20px 30px 20px;
  float: right;
}

.training .listing .collapsing,
.training .listing .collapse.in {
  position: absolute !important;
  z-index: 20;
  width: auto;
  overflow: visible;
  background-color: #fff;
}
