
.form-user #marketing {
  padding: 0 20px;
}

.form-user .wysiwyg,
.form-user .redactor_wysiwyg {
  min-height: 200px;
}

.form-user .signature {
  border: 1px #eee solid;
  border-radius: 3px;
  margin: 0 0 0 20px;
  padding: 10px;
  display: inline-block;
}

.form-user .signature img {
  max-width: 400px;
}

/* ============= welcome page ============== */

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.tab-content.welcome {
  position: relative;
  background: #fff;
}

.welcome-header {
  text-align: center;
  margin: calc(var(--lg-spacing) * 3) 0 calc(var(--lg-spacing) * 2) 0;
  font-size: var(--h3-size);
}

.welcome-content {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 4s;
  padding-bottom: calc(var(--lg-spacing) * 4);
}

.welcome-video-bg {
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  animation-name: fadeOut;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 4s;
  pointer-events: none;
}

.welcome-cards {
  display: flex;
}

.welcome-card {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.welcome .custom-file {
  max-width: 200px;
  cursor: pointer;
}

.welcome .custom-file:focus,
.welcome .custom-file-label:focus {
  outline: none;
}

.welcome .custom-file-label {
  border: none;
  cursor: pointer;
}

.welcome .redactor_box,
.welcome .redactor_box .redactor_wysiwyg {
  width: 100%;
  height: 100%;
  text-align: left;
}

.welcome .redactor_editor {
  border-color: var(--light-gray);
  border-radius: var(--radius);
}

.welcome .redactor_editor:hover,
.welcome .redactor_editor:focus {
  border-color: var(--primary-color);
}

.welcome .custom-file-label::after {
  background-color: #fff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  width: 100%;
  cursor: pointer;
  content: "Choose Picture";
  border-radius: var(--radius);
}

.welcome .custom-file-label:hover::after {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #fff;
}

.welcome h2 {
  border: none;
  display: block;
  font-size: 28px;
  line-height: 1.3em;
  margin: 0 0 var(--thin-spacing) 0;
  width: 100%;
  font-weight: var(--font-weight-bold);
}

.welcome h3 {
  border: none;
  margin: 0 0 var(--lg-spacing) 0;
  font-size: var(--h3-size);
  font-weight: var(--font-weight-bold);
}

.welcome p {
  font-size: var(--h3-size);
}

.welcome .redactor_toolbar {
  display: none;
}

.welcome .row-submit {
  text-align: center;
  padding: calc(var(--lg-spacing) * 2) 0 var(--lg-spacing) 0;
}

#preview-avatar {
  width: 80px;
  height: 80px;
  background-image: url(../img/avatar-80.png);
  background-position: center;
  background-size: cover;
  border-radius: 100px;
  margin-bottom: var(--lg-spacing);
}

/* ============= company directory ============= */

.company-directory .users {
  padding: var(--mid-spacing);
  overflow: hidden;
}

.company-directory .user {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: relative;
}

@media screen and (min-width: 768px) {
  .company-directory .user {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .company-directory .user {
    width: 33%;
  }
}

.company-directory .avatar {
  border-radius: 3px;
}

.company-directory h3 {
  border: none;
  font-size: 1.2em;
  margin: 0;
  padding: 0 0 5px 0;
  font-weight: bold;
  max-width: 70%;
}

.company-directory .info {
  margin-top: 20px;
  line-height: 1.4em;
}

.company-directory .status {
  float: right;
}

.btn-group-tab a,
.btn-group-tab a:hover {
  text-decoration: none;
  color: #fff;
  padding: 20px;
}

.btn-group-tab .btn {
  position: relative;
  float: left;
  margin-left: -1px;
  border-left: 1px solid #84cbef;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.btn-group-tab .btn:first-child {
  margin-left: 0;
  border-left: none;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
}

.btn-group-tab .btn:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
}

.btn-group-tab .btn {
  background-color: #3ab1eb;
}

.btn-group-tab li.active {
  opacity: 0.6;
}

/* ============ business plans ============== */

.table-business-plans th,
.table-business-plans td {
  text-align: right;
}

.table-business-plans th:first-child,
.table-business-plans td:first-child {
  text-align: left;
}

.business-plan .status {
  color: #999;
  font-weight: bold;
}

.business-plan .status::before {
  content: "~";
}

.business-plan-submitted .status {
  color: #4ecaab;
}

.business-plan-submitted .status::before {
  content: "✔";
}

.form-business-plan-summary textarea {
  width: 90%;
}

.form-business-plan-summary.legacy-plan textarea {
  height: 250px;
}

.form-business-plan .row-table .col-3:first-child,
.form-business-plan .row-table .span3:first-child {
  padding-left: 20px;
  text-align: left;
}

.form-business-plan .row-table .col-3,
.form-business-plan .row-table .span3 {
  padding: 10px;
  border-bottom: 1px solid var(--light-gray);
  background-color: var(--white-bg);
}

.form-business-plan .row-table .col-3,
.form-business-plan .row-table .span3,
.form-business-plan input {
  text-align: right;
}

.form-business-plan .row-headers .col-3,
.form-business-plan .row-headers .span3 {
  font-weight: bold;
  line-height: 24px;
  background-color: var(--light-gray-bg);
}

.form-business-plan .row-totals .col-3,
.form-business-plan .row-totals .span3 {
  padding-top: 35px;
  padding-bottom: 25px;
  padding-right: 15px;
  font-weight: bold;
}

.form-business-plan .info-card textarea {
  width: calc(100% - 50px);
  height: 200px;
}

.form-business-plan .add-on {
  display: none;
}

.form-business-plan .row [class*="span"]:first-child .alert {
  margin-top: 0;
}

.form-business-plan .collection-inline-files .control-group {
  padding: 0 10px;
}

.form-business-plan .collection-inline-files .delete {
  float: right;
}

.form-business-plan .action-drag {
  display: none;
}

.business-plan-conversation-users {
  margin: 20px 20px 0;
}

.business-plan-conversation-users ul {
  list-style-type: none;
  display: inline-block;
}

.business-plan-conversation-users li {
  display: inline-block;
}

/* ============= production ===================== */

.form-user-production .row-invoice .control-group {
  padding-left: 20px;
}

.form-user-production .collection-item {
  padding-top: 30px;
}

.form-user-production .collection-item:first-child {
  padding-top: 0;
}

/* ================ admin ==================== */

.form-user-admin .alert {
  margin: 20px 20px 0 0 !important;
}
