/**
 * Name:       IDEA default theme
 * From IntelliJ IDEA by JetBrains
 */
.CodeMirror {
  font-family: Consolas, Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace, serif;
}

.cm-s-idea span.cm-meta { color: #808000; }
.cm-s-idea span.cm-number { color: #00f; }

.cm-s-idea span.cm-keyword {
  line-height: 1em;
  font-weight: bold;
  color: #000080;
}

.cm-s-idea span.cm-atom {
  font-weight: bold;
  color: #000080;
}

.cm-s-idea span.cm-def { color: #000; }
.cm-s-idea span.cm-variable { color: black; }
.cm-s-idea span.cm-variable-2 { color: black; }

.cm-s-idea span.cm-variable-3,
.cm-s-idea span.cm-type { color: black; }
.cm-s-idea span.cm-property { color: black; }
.cm-s-idea span.cm-operator { color: black; }
.cm-s-idea span.cm-comment { color: #808080; }
.cm-s-idea span.cm-string { color: #008000; }
.cm-s-idea span.cm-string-2 { color: #008000; }
.cm-s-idea span.cm-qualifier { color: #555; }
.cm-s-idea span.cm-error { color: #f00; }
.cm-s-idea span.cm-attribute { color: #00f; }
.cm-s-idea span.cm-tag { color: #000080; }
.cm-s-idea span.cm-link { color: #00f; }
.cm-s-idea .CodeMirror-activeline-background { background: #fffae3; }
.cm-s-idea span.cm-builtin { color: #30a; }
.cm-s-idea span.cm-bracket { color: #cc7; }

.cm-s-idea {
  font-family: Consolas, Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace, serif;
}

.cm-s-idea .CodeMirror-matchingbracket {
  outline: 1px solid grey;
  color: black !important;
}

.CodeMirror-hints.idea {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #616569;
  background-color: #ebf3fd !important;
}

.CodeMirror-hints.idea .CodeMirror-hint-active {
  background-color: #a2b8c9 !important;
  color: #5c6065 !important;
}

/* Based on arcticicestudio's Nord theme
  https://github.com/arcticicestudio/nord */

.cm-s-nord.CodeMirror {
  background: #2e3440;
  color: #d8dee9;
}

.cm-s-nord div.CodeMirror-selected { background: #434c5e; }

.cm-s-nord .CodeMirror-line::selection,
.cm-s-nord .CodeMirror-line > span::selection,
.cm-s-nord .CodeMirror-line > span > span::selection { background: #3b4252; }

.cm-s-nord .CodeMirror-line::-moz-selection,
.cm-s-nord .CodeMirror-line > span::-moz-selection,
.cm-s-nord .CodeMirror-line > span > span::-moz-selection { background: #3b4252; }

.cm-s-nord .CodeMirror-gutters {
  background: #2e3440;
  border-right: 0;
}

.cm-s-nord .CodeMirror-guttermarker { color: #4c566a; }
.cm-s-nord .CodeMirror-guttermarker-subtle { color: #4c566a; }
.cm-s-nord .CodeMirror-linenumber { color: #4c566a; }
.cm-s-nord .CodeMirror-cursor { border-left: 1px solid #f8f8f0; }
.cm-s-nord span.cm-comment { color: #4c566a; }
.cm-s-nord span.cm-atom { color: #b48ead; }
.cm-s-nord span.cm-number { color: #b48ead; }
.cm-s-nord span.cm-comment.cm-attribute { color: #97b757; }
.cm-s-nord span.cm-comment.cm-def { color: #bc9262; }
.cm-s-nord span.cm-comment.cm-tag { color: #bc6283; }
.cm-s-nord span.cm-comment.cm-type { color: #5998a6; }

.cm-s-nord span.cm-property,
.cm-s-nord span.cm-attribute { color: #8fbcbb; }
.cm-s-nord span.cm-keyword { color: #81a1c1; }
.cm-s-nord span.cm-builtin { color: #81a1c1; }
.cm-s-nord span.cm-string { color: #81a1c1; }
.cm-s-nord span.cm-variable { color: #d8dee9; }
.cm-s-nord span.cm-variable-2 { color: #d8dee9; }

.cm-s-nord span.cm-variable-3,
.cm-s-nord span.cm-type { color: #d8dee9; }
.cm-s-nord span.cm-def { color: #8fbcbb; }
.cm-s-nord span.cm-bracket { color: #81a1c1; }
.cm-s-nord span.cm-tag { color: #bf616a; }
.cm-s-nord span.cm-header { color: #b48ead; }
.cm-s-nord span.cm-link { color: #b48ead; }

.cm-s-nord span.cm-error {
  background: #bf616a;
  color: #f8f8f0;
}

.cm-s-nord .CodeMirror-activeline-background { background: #3b4252; }

.cm-s-nord .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
