
.clearing-insured .duplicates > li:first-child {
  border-top: none;
}

.clearing-insured .duplicates .title {
  padding: 20px 0 20px 0;
}

.clearing-insured .duplicates .body {
  padding: 0 0 20px 0;
}

.clearing-insured .duplicates > li:first-child .title {
  padding-top: 0;
}

.clearing-insured .duplicates .title a {
  color: #fff;
}

.form-insured .delete a {
  color: #c33;
}
