.teams {
  padding: var(--mid-spacing);
}

.user-group {
  margin: var(--mid-spacing);
}

.user-group-col {
  display: flex;
}

.user-group-col .card {
  flex-grow: 1;
}

.user-group-col .card-body {
  padding: var(--thin-spacing);
}

.member-item {
  /* text-align: center; */
  text-align: left;
}

.member-item .avatar-link {
  padding: var(--mid-spacing);
  display: flex;
  align-items: center;
  border-radius: 3px;
  gap: 10px;
}

.member-item .avatar-link:hover {
  background-color: var(--lightblue-gray);
}

.actions {
  padding: 0 var(--mid-spacing) var(--mid-spacing) var(--mid-spacing);
  text-align: right;
}

.actions .btn {
  margin-left: var(--thin-spacing);
}
