.tax-authority .name-val {
  list-style-type: none;
}

.tax-authority .name-val li {
  padding: 15px 30px;
}

.tax-authority .name-val li:first-child {
  padding-top: 0;
}

.tax-authority .name-val li:last-child {
  padding-bottom: 30px;
}

.tax-authority .name-val label,
.tax-authority .name-val span {
  display: inline-block;
  vertical-align: top;
}

.tax-authority .name-val label {
  font-weight: bold;
  width: 40%;
}

.tax-region table .header th {
  background-color: #eee;
  font-weight: bold;
}

.tax-playground h4 {
  display: block;
  font-size: 1.2em;
}

.tax-playground .rates {
  list-style-type: none;
  margin-left: 20px;
}

.tax-playground .rates li {
  padding: 5px;
  margin: 0 20px 5px 0;
  text-align: right;
  border-bottom: 1px #eee solid;
}

.tax-playground .rates li:last-child {
  border: none;
}

.tax-playground .rates .currency {
  font-family: Courier New, monospace;
  display: inline-block;
  width: 30%;
}

.tax-playground label {
  font-weight: bold;
}

.tax-playground .add {
  text-align: right;
  margin-right: 20px;
}

.tax-playground .add i {
  margin-right: 8px;
}

.tax-playground .icon-spacer {
  display: inline-block;
  content: " ";
  width: 14px;
}

.tax-playground .alert-error ul {
  list-style-type: none;
}

.tax-import-export p {
  margin: 0 20px 20px 20px;
}

.region-export-actions a {
  white-space: nowrap;
}
