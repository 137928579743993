
.editor {
  background-color: #dcdddf;
  margin-top: 0;
}

.editor .navbar-brand {
  float: none;
  display: inline-block;
  margin: 0;
}

.editor .buttons {
  float: right;
}

.editor,
.editor .toolbar {
  height: 100%;
}

.editor .editor-sidebar,
.editor .current {
  overflow: scroll;
}

.editor .editor-sidebar {
  height: 100%;
}

.editor .current {
  height: 100%;
}

.editor .toolbar .top {
  background-color: #2a3342;
  padding: 10px 20px;
  height: 40px;
}

.editor .toolbar .bottom {
  height: 35px;
  padding: 10px 20px;
  text-align: right;
}

.editor .editor-sidebar,
.editor .current {
  display: inline-block;
  vertical-align: top;
}

.editor .editor-sidebar {
  background-color: #eeeeef;
}

.editor .files,
.editor .editor-sidebar .pages,
.editor .tools {
  list-style-type: none;
}

.editor .files {
  border-right: 1px #cacecf solid;
}

.editor .file {
  position: relative;
}

.editor .toolbar .bottom,
.editor .file .name {
  background-color: #f5f5f5;
  border-bottom: 1px #d4d6d8 solid;
}

.editor .file .icon-wrap {
  position: absolute;
  padding: 5px 5px 5px 20px;
  right: 4px;
  top: 3px;
  background: linear-gradient(to left, #f5f5f5 0%, #f5f5f5 60%, transparent 100%);
  display: none;
}

.editor .file i {
  opacity: 0.6;
}

.editor .file-modified .icon-wrap {
  display: inline-block;
}

.editor .file .arrow {
  position: absolute;
}

.editor .file .arrow-right {
  left: 10px;
  top: 11px;
}

.editor .file .arrow-down {
  left: 8px;
  top: 13px;
}

.editor .file .arrow.arrow-right {
  border-left-color: #2a3342;
}

.editor .file .arrow.arrow-down {
  border-top-color: #2a3342;
}

.editor .file.visible .arrow-right,
.editor .file .arrow-down {
  display: none;
}

.editor .file.visible .arrow-down {
  display: block;
}

.editor .file .name {
  padding: 10px 30px 10px 25px;
  cursor: pointer;
  overflow: hidden;
}

.editor .editor-sidebar .pages {
  display: none;
  overflow: scroll;
  background-color: #ededee;
  max-height: 700px;
}

.editor .editor-sidebar .pages .empty,
.editor .editor-sidebar .pages .error {
  padding: 10px;
}

.editor .visible .pages {
  display: block;
  border-bottom: 1px #d4d6d8 solid;
}

.editor .visible .pages .page:last-child {
  padding-bottom: 10px;
}

.editor .editor-sidebar .page {
  margin: 10px 0 0 0;
  position: relative;
}

.editor .editor-sidebar .page .checkbox-label {
  position: absolute;
  top: 0;
  left: 10px;
}

.editor .editor-sidebar .page .inner {
  width: 120px;
  margin: 0 auto;
}

.editor .editor-sidebar .page .selector {
  margin: 5px 0 0 0;
  text-align: left;
}

.editor .editor-sidebar .page img {
  cursor: pointer;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);
}

.editor .editor-sidebar .page img[src*="no-thumb"] {
  box-shadow: none;
}

.editor .current {
  padding: 2%;
  width: 73%;
}

.editor .current .inner {
  position: relative;
}

.editor .image img {
  width: 100%;
}

.editor .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* ============ editor loader ============ */

.editor.page-loading .image,
.editor.page-loading .overlay,
.editor .loader-indicator {
  display: none;
}

.editor.page-loading .loader-indicator {
  display: block;
  padding-top: 100px;
  height: 150px;
}

.editor .loader-indicator-pulse .rect {
  width: 20px;
  border-radius: 5px;
}

/* =========== editor actions/tools ============ */

.editor .actions {
  float: left;
}

.editor .tools {
  float: right;
}

.editor .tools .tool,
.editor .actions .action {
  width: 30px;
  height: 30px;
  overflow: hidden;
  text-indent: 9999px;
  display: inline-block;
  cursor: pointer;
  margin: 0 8px;
  border: 1px transparent solid;
  border-radius: 3px;
}

.editor .tools .tool:hover,
.editor .actions .action:hover,
.editor .tool-current {
  border: 1px #ddd solid;
  box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.editor .action-disabled,
.editor .tool-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.editor .action-add {
  background-image: url(../img/30x30/add.png);
}

.editor .action-merge {
  background-image: url(../img/30x30/merge.png);
}

.editor .action-preview {
  background-image: url(../img/30x30/view.png);
}

.editor .tool-pointer {
  background-image: url(../img/30x30/pointer.png);
}

.editor .tool-highlighter {
  background-image: url(../img/30x30/highlighter.png);
}

.editor .tool-text {
  background-image: url(../img/30x30/text.png);
}

.editor .tool-image {
  background-image: url(../img/30x30/image.png);
}

/* ============ font/shape tools ============= */

.editor .image-tools,
.editor .shape-tools,
.editor .font-tools {
  background-color: #2a3342;
  border-top: none;
  padding: 10px;
  position: absolute;
  top: 116px;
  right: 20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: none;
}

.editor.current-tool-image .image-tools,
.editor .shape-tools-active,
.editor .font-tools-active {
  display: block;
}

.editor .font-tools .control {
  margin: 0 0 10px 0;
}

.editor .font-tools label {
  font-weight: bold;
  color: #fff;
  width: 100px;
  display: inline-block;
}

.editor .font-tools .controls {
  display: inline-block;
}

.editor .font-tools textarea {
  height: 120px;
}

.editor .control-size .controls {
  min-width: 100px;
}

.editor .shape,
.editor .shape .area {
  display: inline-block;
}

.editor .shape {
  position: relative;
  width: 40px;
  height: 20px;
  margin-right: 5px;
}

.editor .shape:last-child {
  margin-right: 0;
}

.editor .shape .area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.editor .shape .arrow {
  position: absolute;
  top: 1px;
  left: -4px;
  border-left-color: #2a3342;
  display: none;
}

.editor .shape-selected .arrow {
  display: block;
}

.editor .shape-white .area {
  border: 1px #aaa solid;
  background-color: #fff;
}

.editor .shape-yellow .area {
  border: 1px #aaa solid;
  background-color: yellow;
}

/* ============ merging ============ */

.modal-merge .modal-body {
  padding: 20px;
}

.modal-merge .name {
  float: left;
  width: 50%;
  border: 1px #ccc solid;
}

.modal-merge .name.error,
.modal-editor-images .name.error {
  border-color: rgba(235, 87, 103, 0.5);
}

.modal-merge label.error {
  display: inline;
}

.editor .merge ul {
  list-style-type: none;
}

.editor .merge .page img {
  width: 100%;
}

/* ============= images ================= */

.editor .image-tools {
  width: 250px;
}

.editor .image-tools .btn-primary {
  text-align: center;
  width: 100%;
  padding: 6px 0;
}

.editor .image-tools .no-results {
  padding: 10px 0 10px 0;
  font-size: 0.9em;
  line-height: 1.4em;
}

.editor .image-tools ul {
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 10px;
  text-align: center;
  background-color: #fff;
}

.editor .image-tools li:first-child {
  margin-top: 5px;
}

.editor .image-tools li {
  margin-bottom: 5px;
}

.editor .image-tools img {
  cursor: move;
  border-radius: 3px;
}

.form-editor-image {
  float: left;
}

.editor-images {
  list-style-type: none;
}

.modal-editor-images .editor-images {
  text-align: center;
  margin-bottom: 10px;
}

.modal-editor-images .editor-image {
  display: inline-block;
  vertical-align: top;
  margin: 10px 10px 0 10px;
  border: 5px transparent solid;
  border-radius: 5px;
  cursor: pointer;
}

.modal-editor-images .editor-image-selected {
  background-color: rgba(59, 178, 237, 0.4);
}

.modal-editor-images .no-results {
  line-height: 1.6em;
}

.editor .ui-draggable-dragging {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
}

.modal-merge .modal-footer form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}
