
/* ==================== listing ================== */

.table-listing-templates .actions {
  white-space: nowrap;
}

.table-listing-templates .discr {
  font-weight: bold;
}

/* ==================== config =================== */

.modal-template-editor-config .form-horizontal textarea {
  min-width: calc(100% - 20px);
  margin-bottom: 20px;
}

.modal-template-editor-config .form-horizontal [type=text] {
  min-width: calc(100% - 40px);
}

.modal-template-editor-config .form-horizontal .controls {
  margin-left: 80px;
}

.modal-template-editor-config .form-horizontal .control-label {
  width: 80px;
}

/* ==================== editor =================== */

.template-editor {
  margin-top: 0;
}

.template-editor .right .alert-error {
  position: fixed;
  top: 0;
  border-radius: 0;
  z-index: 10;
}

.template-editor form {
  height: 100%;
}

.template-editor .toolbar {
  background-color: #2a3342;
  color: #fff;
  padding: 10px 20px;
}

.template-editor .toolbar,
.template-editor h2 {
  line-height: 20px;
  font-size: 1em;
  padding: 10px 20px;
  color: #fff;
}

.template-editor .toolbar {
  height: 35px;
}

.template-editor .toolbar .form-control {
  display: inline-block;
  width: auto;
}

.template-editor .back {
  margin-right: 10px;
}

.template-editor h2 {
  height: 20px;
}

.template-editor .error {
  display: inline-block;
  margin-left: 10px;
}

.template-editor .btn-save {
  float: right;
}

.template-editor .toolbar .select2-container {
  max-width: 40%;
  display: inline-block;
}

.template-editor .info .select2-container {
  min-width: calc(100% - 20px) !important;
}

.template-editor .left,
.template-editor .right {
  display: inline-block;
  vertical-align: top;
}

.template-editor .left {
  width: 40%;
  height: 100%;
}

.template-editor .right {
  width: calc(60% - 1px);
  overflow: scroll;
  border-left: 1px #2a3342 solid;
  height: 100%;
  background-color: #fff;
}

.template-editor h2 {
  margin: 0;
  border: none;
  display: block;
  background-color: #1b212a;
}

.template-editor .fields-list {
  width: 100%;
  height: calc(60% - 17px);
  overflow: auto;
}

.template-editor .files-info {
  width: 100%;
  height: calc(40% - 17px);
  overflow: auto;
}

.template-editor .files-list,
.template-editor .files-upload {
  width: 100%;
  height: calc(30% - 17px);
  overflow: auto;
}

.template-editor .files-list .preview-action {
  opacity: 0.7;
  cursor: pointer;
}

.template-editor .files-list .preview-action:hover {
  opacity: 1;
}

.template-editor .files-list .preview-action i {
  display: none;
}

.template-editor .files-list .preview-action:hover i {
  display: block;
}

.template-editor .files-upload ul.files {
  padding: 0 40px;
  list-style-type: none;
}

.template-editor .files-upload .dnd-dropzone {
  min-height: 150px;
}

.template-editor .files-selector > div {
  padding-top: 20px;
}

.template-editor .files-selector .headers {
  font-weight: bold;
}

.template-editor .files-selector .row {
  padding-top: 5px;
}

.template-editor .files-selector .select2-container {
  width: 100% !important;
}

.template-editor .info,
.template-editor .twig,
.template-editor .css {
  width: 100%;
  height: calc(33% - 17px);
  overflow: hidden;
}

.template-editor .CodeMirror,
.template-editor textarea {
  width: 100%;
  height: calc(100% - 40px);
  padding: 0;
  border-radius: 0;
  font-family: courier, monospace;
}

.template-editor .has-code-mirror {
  display: none;
}

.template-editor .btn-maximize {
  float: right;
  margin: 9px;
  padding: 3px 6px;
}

.template-editor .fields-list input[type=text],
.template-editor .fields-list textarea,
.template-editor .files-selector input[type=text],
.template-editor .files-info input[type=text],
.template-editor .email input,
.template-editor .email textarea,
.template-editor .info input {
  background-color: #fff;
  width: calc(100% - 40px);
}

.template-editor-info .info,
.template-editor-twig .twig,
.template-editor-css .css {
  height: calc(100% - 135px);
}

.template-editor-info .twig,
.template-editor-info .css,
.template-editor-twig .info,
.template-editor-twig .css,
.template-editor-css .info,
.template-editor-css .twig {
  height: 40px;
}

.template-editor-info .twig .CodeMirror,
.template-editor-info .css .CodeMirror,
.template-editor-twig .info .inner,
.template-editor-twig .css .CodeMirror,
.template-editor-css .info .inner,
.template-editor-css .twig .CodeMirror {
  display: none;
}

.template-editor .mode {
  position: absolute;
  right: 10px;
  top: 10px;
  list-style-type: none;
  background-color: #2a3342;
  border-radius: 5px;
  overflow: hidden;
  border: 1px #2a3342 solid;
}

.template-editor .preview-area,
.template-editor .preview-area iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.template-editor .html,
.template-editor .pdf {
  display: inline-block;
  padding: 8px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.template-editor .html,
.template-editor.mode-pdf .pdf {
  background-color: #4ecaab;
}

.template-editor.mode-pdf .html {
  background-color: transparent;
}

.template-editor .preview-pdf,
.template-editor.mode-pdf .preview-html {
  display: none;
}

.template-editor .preview-html {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  position: relative;
}

.template-editor.mode-pdf .preview-pdf {
  display: block;
  width: 100%;
  height: 100%;
}

/* Fillable Template Editor */

.template-editor .fields-list input[type=text] {
  margin-bottom: 3px;
}

.template-editor .fields-list textarea {
  height: 75px;
  width: calc(100% - 40px);
}

.template-fillable-editor .mode {
  border: none;
  background: none;
}

.template-fillable-editor .btn {
  margin-left: 5px;
}

/* Email Template Editor */

.template-editor .email {
  width: 100%;
  height: calc(66% - 35px);
  overflow: hidden;
}

.template-editor-email .CodeMirror,
.template-editor-email textarea {
  height: calc(100% - 110px);
}

/* ========== evaluator ==================== */

.btn-evaluator-modal {
  height: 65px;
  width: 10px;
  padding: 5px;
  vertical-align: top;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-evaluator .expression {
  padding: 10px;
  margin: 20px;
  width: calc(100% - 60px);
}

.modal-evaluator .output pre,
.modal-evaluator .alert {
  margin: 0 20px 20px 20px;
}

.modal-evaluator .output pre,
.template-editor-template-context pre.sf-dump {
  border-radius: 10px;
  padding: 20px;
}

.template-editor-template-context pre {
  margin: 20px;
}

.template-fillable-editor .scrollable-content {
  overflow-y: scroll;
  height: calc(100% - 40px);
}

.template-fillable-variables input,
.template-fillable-variables textarea {
  border: 1px #ccc solid;
}

.template-fillable-variables > div > .controls {
  padding: 10px;
}

.template-fillable-variables .control-group {
  padding-left: 0;
}
