
.policyfilter .col-checkbox label {
  white-space: nowrap;
}

.policyfilter .col-checkbox {
  text-align: center;
}

.policyfilter .actions {
  margin: 20px;
}

.agencytransfer p,
.producertransfer p {
  margin: 20px;
}

.producertransfer textarea,
.agencytransfer textarea {
  width: 90%;
  height: 225px;
}

.policyfilter .avatar {
  border-radius: 2px;
}
