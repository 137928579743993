
.item-actions,
.item-actions.visible {
  transition: bottom 0.5s;
  transition-timing-function: ease-out;
}

.item-actions {
  position: fixed;
  bottom: -100px;
  left: 90px;
  max-width: 50%;
  min-width: 30%;
  padding: 5px;
  background-color: #1b212a;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-left: 10px;
  z-index: 1;
}

.item-actions.visible {
  bottom: 0;
}

.item-actions .dismiss-all,
.item-action .dismiss {
  float: right;
}

.item-actions .dismiss-all {
  color: #fff;
  margin: 5px 0;
  font-size: 0.8em;
}

.item-action-list {
  list-style-type: none;
  padding-bottom: 20px;
}

.item-action {
  background-color: #2a3342;
  margin: 1px;
  padding: 5px 10px;
  clear: right;
}

.item-action:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.item-action:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.item-action .dismiss {
  text-decoration: none;
  padding: 5px;
  color: #000;
}

.item-action .dismiss:hover {
  color: #fff;
}

.item-action h3 {
  border: none;
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: #fff;
  max-width: calc(100% - 50px);
  line-height: 1.4em;
}

.item-action-error h3 b {
  color: #eb5767;
}

.item-actions .links {
  margin-top: 5px;
}

.item-action .loader-indicator-pulse {
  text-align: left;
}
