.clearfix {
  *zoom: 1;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: "";
}

.clearfix::after {
  clear: both;
}

.hide-text {
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.input-block-level {
  display: block;
  width: 100%;
  min-height: 28px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.hidden {
  display: none;
  visibility: hidden;
}

.visible-phone {
  display: none !important;
}

.visible-tablet {
  display: none !important;
}

.hidden-desktop {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-phone {
    display: inherit !important;
  }

  .hidden-phone {
    display: none !important;
  }

  .hidden-desktop {
    display: inherit !important;
  }

  .visible-desktop {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .visible-tablet {
    display: inherit !important;
  }

  .hidden-tablet {
    display: none !important;
  }

  .hidden-desktop {
    display: inherit !important;
  }

  .visible-desktop {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .nav-collapse {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .page-header h1 small {
    display: block;
    line-height: 18px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    border: 1px solid #ccc;
  }

  .form-horizontal .control-group > label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
  }

  .form-horizontal .controls {
    margin-left: 0;
  }

  .form-horizontal .control-list {
    padding-top: 0;
  }

  .form-horizontal .form-actions {
    padding-right: 10px;
    padding-left: 10px;
  }

  .modal {
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
    width: auto;
    margin: 0;
  }

  .modal.fade.in {
    top: auto;
  }

  .modal-header .close {
    padding: 10px;
    margin: -10px;
  }

  .carousel-caption {
    position: static;
  }
}

@media (max-width: 767px) {
  .container-fluid {
    padding: 0;
  }

  .dl-horizontal dt {
    float: none;
    width: auto;
    clear: none;
    text-align: left;
  }

  .dl-horizontal dd {
    margin-left: 0;
  }

  .container {
    width: auto;
  }

  .row-fluid {
    width: 100%;
  }

  .row,
  .thumbnails {
    margin-left: 0;
  }

  .row [class*="span"],
  .row-fluid [class*="span"] {
    display: block;
    float: none;
    width: auto;
    margin-left: 0;
  }

  .input-large,
  .input-xlarge,
  .input-xxlarge,
  input[class*="span"],
  select[class*="span"],
  textarea[class*="span"],
  .uneditable-input {
    display: block;
    width: 100%;
    min-height: 28px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .input-prepend input,
  .input-append input,
  .input-prepend input[class*="span"],
  .input-append input[class*="span"] {
    display: inline-block;
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .row {
    margin-left: -20px;
    *zoom: 1;
  }

  .row::before,
  .row::after {
    display: table;
    content: "";
  }

  .row::after {
    clear: both;
  }

  [class*="span"] {
    float: left;
    margin-left: 20px;
  }

  .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 724px;
  }

  .span12 {
    width: 724px;
  }

  .span11 {
    width: 662px;
  }

  .span10 {
    width: 600px;
  }

  .span9 {
    width: 538px;
  }

  .span8 {
    width: 476px;
  }

  .span7 {
    width: 414px;
  }

  .span6 {
    width: 352px;
  }

  .span5 {
    width: 290px;
  }

  .span4 {
    width: 228px;
  }

  .span3 {
    width: 166px;
  }

  .span2 {
    width: 104px;
  }

  .span1 {
    width: 42px;
  }

  .offset12 {
    margin-left: 764px;
  }

  .offset11 {
    margin-left: 702px;
  }

  .offset10 {
    margin-left: 640px;
  }

  .offset9 {
    margin-left: 578px;
  }

  .offset8 {
    margin-left: 516px;
  }

  .offset7 {
    margin-left: 454px;
  }

  .offset6 {
    margin-left: 392px;
  }

  .offset5 {
    margin-left: 330px;
  }

  .offset4 {
    margin-left: 268px;
  }

  .offset3 {
    margin-left: 206px;
  }

  .offset2 {
    margin-left: 144px;
  }

  .offset1 {
    margin-left: 82px;
  }

  .row-fluid {
    width: 100%;
    *zoom: 1;
  }

  .row-fluid::before,
  .row-fluid::after {
    display: table;
    content: "";
  }

  .row-fluid::after {
    clear: both;
  }

  .row-fluid [class*="span"] {
    display: block;
    float: left;
    width: 100%;
    min-height: 28px;
    margin-left: 2.762430939%;
    *margin-left: 2.709239449638298%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }

  .row-fluid .span12 {
    width: 99.999999993%;
    *width: 99.9468085036383%;
  }

  .row-fluid .span11 {
    width: 91.436464082%;
    *width: 91.38327259263829%;
  }

  .row-fluid .span10 {
    width: 82.87292817100001%;
    *width: 82.8197366816383%;
  }

  .row-fluid .span9 {
    width: 74.30939226%;
    *width: 74.25620077063829%;
  }

  .row-fluid .span8 {
    width: 65.74585634900001%;
    *width: 65.6926648596383%;
  }

  .row-fluid .span7 {
    width: 57.182320438000005%;
    *width: 57.129128948638304%;
  }

  .row-fluid .span6 {
    width: 48.618784527%;
    *width: 48.5655930376383%;
  }

  .row-fluid .span5 {
    width: 40.055248616%;
    *width: 40.0020571266383%;
  }

  .row-fluid .span4 {
    width: 31.491712705%;
    *width: 31.4385212156383%;
  }

  .row-fluid .span3 {
    width: 22.928176794%;
    *width: 22.874985304638297%;
  }

  .row-fluid .span2 {
    width: 14.364640883%;
    *width: 14.311449393638298%;
  }

  .row-fluid .span1 {
    width: 5.801104972%;
    *width: 5.747913482638298%;
  }

  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }

  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 714px;
  }

  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 652px;
  }

  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 590px;
  }

  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 528px;
  }

  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 466px;
  }

  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 404px;
  }

  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 342px;
  }

  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 280px;
  }

  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 218px;
  }

  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 156px;
  }

  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 94px;
  }

  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 32px;
  }
}

@media (max-width: 979px) {
  body {
    padding-top: 0;
  }

  .navbar-fixed-top {
    margin-bottom: 18px;
  }

  .navbar-fixed-bottom {
    margin-top: 18px;
  }

  .navbar-fixed-top .navbar-inner,
  .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }

  .navbar .container {
    width: auto;
    padding: 0;
  }

  .navbar .navbar-brand {
    padding-right: 10px;
    padding-left: 10px;
  }

  .nav-collapse {
    clear: both;
  }

  .nav-collapse .nav {
    float: none;
    margin: 0 0 9px;
  }

  .nav-collapse .nav > li {
    float: none;
  }

  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }

  .nav-collapse .nav > .divider-vertical {
    display: none;
  }

  .nav-collapse .nav .nav-header {
    color: #999;
    text-shadow: none;
  }

  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    padding: 6px 15px;
    font-weight: bold;
    color: #999;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  .nav-collapse .btn {
    padding: 4px 10px 4px;
    font-weight: normal;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }

  .nav-collapse .nav > li > a:hover,
  .nav-collapse .dropdown-menu a:hover {
    background-color: #222;
  }

  .nav-collapse.in .btn-group {
    padding: 0;
    margin-top: 5px;
  }

  .nav-collapse .dropdown-menu {
    position: static;
    top: auto;
    left: auto;
    display: block;
    float: none;
    max-width: none;
    padding: 0;
    margin: 0 15px;
    background-color: transparent;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .nav-collapse .dropdown-menu::before,
  .nav-collapse .dropdown-menu::after {
    display: none;
  }

  .nav-collapse .dropdown-menu .divider {
    display: none;
  }

  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    float: none;
    padding: 9px 15px;
    margin: 9px 0;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }

  .nav-collapse,
  .nav-collapse.collapse {
    height: 0;
    overflow: hidden;
  }

  .navbar .btn-navbar {
    display: block;
  }

  .navbar-static .navbar-inner {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 980px) {
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}
