
.quotes .btn-success {
  box-shadow: none;
}

#hazards {
  margin-bottom: 20px;
}

.table-policy-pack {
  width: auto;
}

.table-policy-pack th,
.table-policy-pack td {
  padding: 5px 10px;
}

.table-policy-pack .icon-move {
  cursor: move;
}

.table-policy-pack [type=checkbox] {
  cursor: pointer;
}

.table-policy-pack thead,
.table-policy-pack tbody {
  display: block;
  width: 100%;
}

.table-policy-pack tbody {
  overflow: auto;
  max-height: 300px;
}

.table-policy-pack thead tr {
  position: relative;
}

.table-policy-pack thead th,
.table-policy-pack tbody td {
  width: 100px;
}

.table-policy-pack thead th + th,
.table-policy-pack tbody td + td {
  width: 200px;
}

.table-policy-pack thead th + th + th,
.table-policy-pack tbody td + td + td {
  width: 500px;
}

/* ==================== V3 ======================= */
.selected-program-container ul,
.program-selector-container ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.selected-program-container li:not(.no-results),
.program-selector-container li:not(.no-results) {
  width: 100%;
}

.selected-program-container .quote-template__label,
.program-selector-container .quote-template__label {
  border: 1px var(--light-gray) solid;
  border-radius: var(--radius);
  padding: var(--mid-spacing);
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  transition: border-color 0.1s ease-in-out;
}

.selected-program-container .quote-template__label:hover,
.program-selector-container .quote-template__label:hover,
.selected-program-container .quote-template__label:has(input:checked),
.program-selector-container .quote-template__label:has(input:checked) {
  border-color: var(--primary-color);
}

.selected-program-container .quote-template__text,
.program-selector-container .quote-template__text {
  padding-left: var(--thin-spacing);
}

.selected-program-container li h4,
.program-selector-container li h4 {
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight-bold);
  margin-bottom: 0;
}

.selected-program-container li p,
.program-selector-container li p {
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight);
}

/* .new-ui .program-selector-container label {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
} */

.program-selector-container li.no-results {
  border: none;
  background: var(--lightblue-gray);
  border-radius: var(--radius);
  padding: var(--lg-spacing);
}

.add-quote-heading {
  padding: var(--lg-spacing) !important;
  border-bottom: 1px solid var(--light-gray);
  background-color: var(--light-gray-bg);
}

.add-quote-heading h2 {
  margin: 0 !important;
}

#quotes {
  position: relative;
}

#quotes .intab-cancel-wrapper {
  position: absolute;
  top: var(--thin-spacing);
  right: 0;
  z-index: 1;
}

@media (min-width: 768px) {
  .selected-program-container li:not(.no-results),
  .program-selector-container li:not(.no-results) {
    width: calc(50% - var(--mid-spacing));
  }
}

.quote-ineligible-msg {
  font-size: 0.87rem;
}
