
.application .gmaps-streetview {
  display: none;
}

.application .row .term-autofill {
  font-size: 0.9em;
  margin-left: 180px;
}

.application .row-lob {
  display: none;
}

.application .row-lob-active {
  display: block;
}

.application .gmaps-view {
  float: right;
  color: #3bb2ed;
  margin-right: 12%;
}

/* ========= previous ========= */

.modal-previous .modal-body {
  padding: 0 20px 20px 0;
}

.modal-previous .modal-body h3 {
  font-size: 1.2em;
  border-width: 1px;
}

.modal-previous .modal-body h3 a {
  color: #000;
  text-decoration: none;
}

.modal-previous .modal-body ul {
  list-style-type: none;
}

/* ======== hazards ========= */

.application .table-header {
  font-weight: bold;
  padding-left: 0;
}

.application .table-header-section {
  color: silver;
}

.application .hazards .add-on {
  display: none;
}

.application .hazards .input-prepend {
  display: block;
}

.application .hazards .controls {
  margin-left: 0;
}

.application h2 {
  margin: 0 20px 10px 30px;
}

.application .hazards > .add {
  margin-left: 20px;
}

.application .criteria_property > .control-group,
.application .property_limit > .control-group {
  margin-bottom: 0;
}

.application .hazard {
  padding: 20px 0;
  margin-bottom: 20px;
}

.application .hazard:last-child,
.application .rating-basises .collection-item:last-child > .control-group,
.application .limits :last-child {
  margin-bottom: 0;
}

.application .row-lob {
  margin-top: 50px;
}

.application .delete-label a {
  color: #c33;
}

.application .limits > .row {
  margin-top: 20px;
}

.application .limits label.error {
  position: absolute;
}

.application .hazard-title {
  padding: 0 0;
  margin: 0 0 20px 0;
}

.application .hazard .criteria-professional-cyber label {
  width: 100%;
}

.application .hazard-product-definition h4 {
  margin: 0 0 20px 0;
}

.application .hazard-product-definition .align-right {
  float: right;
}

/* ============ quotes ============ */

.quotes .alert-warning {
  margin: 0;
  line-height: 1.4em;
}

.quotes .document-quote-header,
.quotes .document-quote,
.quotes .premium-finance,
.quotes .carrier-quote,
.quotes .agent-quote {
  text-align: center;
}

.quotes .document .mailpane-action {
  margin-top: 8px;
}

.quotes th.status {
  padding-right: 45px;
}

.quotes .hazard .row.rendered {
  margin-left: 20px;
}

/* ============ clearing ============= */

.clearing-application .cancel {
  margin-left: 10px;
  color: #eb5767;
  font-size: 0.9em;
}

.clearing-application .duplicates .title {
  padding: 0 20px 20px 20px;
}

.clearing-application .duplicates > li,
.clearing-application .duplicates > li:last-child {
  border: none;
}

.clearing-application .duplicates > li {
  border-bottom: 1px #eee solid;
  padding-bottom: 20px;
  margin: 0 20px 20px 0;
}

.clearing-application .see-all {
  text-align: right;
  padding: 0 20px 20px 0;
}

.clearing-application .scroller {
  top: 80px;
  background-color: #fff;
}

/* ========= bound quotes =========== */

.bound-quotes {
  list-style-type: none;
}

.bound-quote {
  border-top: 5px #eee solid;
}

.bound-quote:first-child {
  border-top: none;
}

/* ========= Collapse elements =========== */

.application .collapse-element {
  display: inline-block;
  color: rgb(59, 178, 237);
  font-weight: bold;
  letter-spacing: -1px;
  font-size: 10px;
}

.collapse-button {
  font-size: 14px !important;
  line-height: 14px;
  color: #000 !important;
}

/* =========== inactive hazard removal ======== */

.delete-hazards .explanation {
  min-height: 600px;
}

.delete-hazards .explanation .text {
  margin: 0 20px;
}

.delete-hazards .explanation .submit {
  margin: 20px;
}

.delete-hazards .cancel {
  margin-left: 10px;
  color: #eb5767;
  font-size: 0.9em;
}

.application .inactive-hazards {
  list-style-type: none;
}

.application .inactive-hazard {
  border-bottom: 1px #eee solid;
}

.application .inactive-hazard ul {
  padding: 0 0 20px 40px;
}

.application .inactive-hazard h3 {
  margin: 20px 20px 10px 20px;
  border: none;
}

.application .inactive-hazard .premium {
  font-weight: bold;
}

.application .inactive-hazard .subheading {
  margin: 0 20px 20px;
}

/* ========== restaurant hazard ============= */

.form-horizontal .hazard-restaurant .control-label {
  width: auto;
}

/* ========== new application =========== */

.app-form {
  position: relative;
  background: white;
}

.app-form h1 {
  font-size: 19px;
  font-family: "Gotham Black", sans-serif;
  font-weight: 800;
  letter-spacing: 0;
  color: #545e6a;
  margin: 35px 30px;
}

.app-form h2 {
  font-weight: bold;
  margin: 20px 20px 30px 20px;
}

.app-form h3 {
  border-bottom: none;
  font-size: 16px;
  font-weight: 800;
  margin: 30px 0 15px;
  color: #545f6b;
  opacity: 0.6;
}

.app-form h4 {
  border-bottom: none;
  color: #7b94bb;
  display: block;
  font-size: 16px;
  font-weight: 200;
  margin: 0 0 20px;
}

.app-form .control-group {
  padding-left: 0;
}

.app-form .control-label {
  font-size: 12px;
}

.app-form .visible-local {
  display: none;
}

.app-form.is-local .visible-local {
  display: block;
}

.app-form.is-summary .hidden-summary {
  display: none;
}

.app-form.is-summary .full-summary {
  width: 100%;
}

.app-form.is-summary .full-summary .product-forms {
  padding: 0 0 100px;
}

.app-form .product-forms {
  padding: 30px 30px 100px;
}

.app-form .select2 {
  font-size: 12px;
}

.app-form .nav-tabs {
  background: white;
  padding: 15px 20px 9px;
}

.app-form .nav-tabs li a {
  color: #4b5167;
  font-size: 14px;
  padding: 5px 0 20px;
  margin-right: 40px;
  background-color: transparent;
}

.app-form .nav-tabs .active a {
  color: #20b1ef;
  border-bottom: 3px solid #20b1ef;
}

.app-form .tab-content {
  border-top: 1px solid #eaeaea;
}

.app-form .navigation-area {
  padding: 30px;
}

.app-form .navigation-action {
  color: #3ab1eb;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}

.app-form .new-insured {
  font-size: 12px;
}

.app-form .checkbox-area {
  padding: 20px;
  margin: 10px 1%;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.06);
}

.app-form .checkbox-area span {
  margin-left: 10px;
}

.app-form .product-list .checkbox-area {
  width: 28%;
  float: left;
  margin: 10px 1%;
}

.app-form .bottom-navigation-area {
  position: absolute;
  width: 100%;
  height: 70px;
  bottom: 0;
  left: 0;
  background: white;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.07);
}

.app-form .bottom-navigation-area .navigation-button {
  padding: 17px 40px;
}

.app-form .bottom-navigation-area.backable-step .cancel-action {
  display: none;
}

.app-form .bottom-navigation-area.backable-step .close-edit-action {
  display: none;
}

.app-form .bottom-navigation-area .back-action {
  display: none;
}

.app-form .bottom-navigation-area.backable-step .back-action {
  display: block;
}

.app-form .bottom-navigation-area.last-step .continue-action {
  display: none;
}

.app-form .bottom-navigation-area:not(.last-step) .submit-action,
.app-form .bottom-navigation-area:not(.last-step) .go-application {
  display: none;
}

.app-form .control-label label {
  font-size: 13px;
}

.app-form input[type=text],
.app-form input[type=number],
.app-form textarea {
  min-width: 80%;
  max-width: 80%;
}

.app-form .add {
  margin-left: 0;
  margin-top: -10px;
}

.app-form .add a {
  color: #3ab1eb;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 1px;
}

.app-form .datepicker input[type=text] {
  min-width: 70%;
  max-width: 70%;
}

.app-form .select2-container {
  max-width: 88%;
  min-width: 88%;
}

.app-form-fields {
  padding-bottom: 140px;
}

.app-form-fields > div {
  padding: 0 30px 20px;
}

.app-form-fields .odd-section {
  background: #fbfbfb;
  border: 1px solid #f5f5f5;
}

.info-sidebar {
  background: #242b36;
  padding-bottom: 140px;
}

.info-sidebar .extra-info .info-title {
  margin: 0;
  padding: 20px;
}

.info-sidebar .extra-info .info-title a {
  font-size: 12px;
  color: #ffff;
  text-decoration: none;
  opacity: 0.8;
}

.info-sidebar .extra-info .info-title a:hover {
  opacity: 1;
}

.info-sidebar .extra-info > div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.info-sidebar .extra-info > div:nth-child(even) {
  background: #333944;
}

.info-sidebar .attachments-widget > div {
  border-top: 1px solid #333944;
}

.info-sidebar .extra-info h5,
.info-sidebar .attachments-widget h5 {
  font-size: 14px;
  color: white;
  font-weight: bold;
  padding: 20px 20px 14px;
  display: block;
}

.info-sidebar .extra-info h6,
.info-sidebar .attachments-widget h6 {
  color: #3ab1eb;
  padding: 0 20px;
  font-size: 12px;
}

.info-sidebar .extra-info p,
.info-sidebar .attachments-widget p {
  margin: 10px 20px;
  font-size: 12px;
  color: #fff;
  opacity: 0.75;
}

.info-sidebar .extra-info p.relevant {
  font-weight: 500;
  opacity: 1;
}

.info-sidebar .extra-info span.info {
  font-weight: bold;
  color: #3ab1eb;
}

.info-sidebar .extra-info span.success {
  font-weight: bold;
  color: #5eb95e;
}

.info-sidebar .extra-info span.danger {
  font-weight: bold;
  color: #ee5f5b;
}

.info-sidebar .progressor-wrapper {
  padding: 0 20px;
}

.info-sidebar .progressor {
  height: 10px;
  background: transparent;
  border: 1px solid #484e58;
}

.app-form-sidebar {
  border-right: 1px solid #eaeaea;
  padding-bottom: 140px;
}

.app-form-sidebar ul {
  list-style-type: none;
}

.app-form-sidebar ul li {
  padding: 15px 30px;
  position: relative;
}

.app-form-sidebar ul li.active {
  color: #3ab1eb;
  border: 1px solid #eaeaea;
  border-right: 1px solid white;
  border-left: 4px solid #3ab1eb;
  margin-right: -1px;
}

.app-form-sidebar ul li a {
  color: #545f6b;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}

.app-form-sidebar ul li.active a {
  color: #3ab1eb;
}

.app-form .row-flex,
.app-form .row-flex .tab-pane {
  height: 100%;
}

.app-form .file-inputs .file-input:last-child {
  border: none;
}

.app-form .file-inputs .file-input {
  background: transparent;
  border-top: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px 0;
  margin: 0 20px;
}

.app-form .file-inputs .file-input input {
  background-color: transparent;
  color: white;
  padding: 0;
}

.app-form .btn-add-file-wrap {
  text-align: left;
  padding: 10px 20px 0;
}

.app-form .btn-new-insured-wrap > div {
  float: left;
  margin-left: -40px;
}

.app-form .documents li.loading,
.app-form .documents li.tag {
  list-style: none;
}

.app-form .documents li.loading {
  margin: 20px;
}

.app-form .files li.file {
  margin: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  list-style: none;
}

.app-form .files li.file:last-child {
  border-bottom: none;
}

.app-form .dnd-dropzone-wrapper,
.app-form .dnd-dropzone {
  min-height: 0;
  -webkit-transition: min-height 0.4s;
  -moz-transition: min-height 0.4s;
  -ms-transition: min-height 0.4s;
  -o-transition: min-height 0.4s;
  transition: min-height 0.4s;
}

.dnd-hover .app-form .dnd-dropzone {
  min-height: 150px;
}

.app-form .btn-attach-wrap {
  background-color: transparent;
  margin: 0;
}

.app-form .btn-attach {
  border: none;
  text-transform: none;
  color: #3ab1eb;
  letter-spacing: 1px;
  font-size: 12px;
  text-align: left;
  padding: 5px 20px;
}

/* ========== application type selection =========== */
.application-backdrop {
  width: 100%;
  min-height: 100%;
}

form.application.new-ui > div {
  background-color: #fff;
  width: 100%;
}

.application-backdrop .container-center {
  max-width: 900px !important;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px 0;
  margin: 12px;
  border-radius: 3px;
  overflow: auto;
}

.application-backdrop .container-center .application-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.container-center .application-card {
  padding: 16px 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.container-center .application-card:hover {
  background-color: #f9f9f9;
}

.container-center a.card-link {
  text-decoration: none;
}

.container-center .application-card:not(.auto-height) {
  height: 290px;
}

.container-center a + .btn-link {
  margin-top: 12px;
  display: block;
}

.application-card .application-title {
  font-weight: 600;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.application-card .application-title input {
  margin-right: 8px;
}

.application-card .application-title label {
  max-width: 100%;
}

.application-card .application-description {
  display: block;
  margin-bottom: 20px;
  flex: 1;
}

.application-card .application-checklist {
  justify-content: flex-end;
}

.application-card .application-checklist .fa-times-circle {
  color: #eb5767;
}

.application-card .application-checklist .fa-check-circle {
  color: #92e439;
}

.application-card .application-checklist p {
  margin-bottom: 0.5rem;
}

.status-application .ts-wrapper {
  width: 250px;
}

.status-application-change {
  display: flex;
}
