
.renewals-manager .expires-between {
  margin-bottom: 0;
}

.renewals-manager .expires-between .datepicker .controls {
  margin-left: 0;
}

.renewals-manager h3 {
  border: none;
  margin-bottom: 0;
}

.renewals-manager h3 .avatar {
  border-radius: 3px;
}

.renewals-manager .table-listing-container {
  background: #fff;
}

.renewals-manager h3 .avatar-wrap,
.renewals-manager h3 .user-wrap {
  display: inline-block;
  vertical-align: middle;
}

/* ============== renewals table expanded ========== */

.table-renewals .sub-row td {
  padding: 10px 5px;
  vertical-align: middle;
}

/* ============== renewals table ========== */

.table-renewals {
  margin-top: 10px;
  font-size: 13px;
}

.table-renewals th {
  white-space: nowrap;
}

.table-renewals td:nth-child(2) {
  padding-left: 15px;
}

.table-renewals thead td:first-of-type {
  background-color: var(--light-gray-bg);
}

.table-renewals tfoot tr:last-of-type td {
  border-bottom: none;
}

.table-renewals .producer .avatar {
  border-radius: 2px;
}

.table-renewals .policy,
.table-renewals .premium {
  font-weight: bold;
}

.table-renewals .producer {
  white-space: nowrap;
}

.table-renewals .producer,
.table-renewals .producer .avatar,
.table-renewals .producer .name {
  display: inline-block;
  vertical-align: middle;
}

.table-renewals .revenue,
.table-renewals .premium {
  text-align: right;
}

.table-renewals .quote-status {
  white-space: nowrap;
}

.table-renewals .revenue-total,
.table-renewals .premium-total {
  color: rgb(59, 178, 237);
  font-weight: bold;
}

.table-renewals .premium-total-label,
.table-renewals .status-description {
  text-align: center;
}

/* ========= status solicited ============= */

.table-renewals .renewal-solicited td .status-indicator,
.table-renewals .renewal-solicited:nth-child(even) td .status-indicator {
  background-color: rgba(209, 120, 237, 0.08);
}

.table-renewals .renewal-quote.renewal-solicited td .status-indicator,
.table-renewals .renewal-quote.renewal-solicited:nth-child(even) td .status-indicator {
  background-color: rgba(209, 120, 237, 0.03);
}

.table-renewals .renewal-quote.renewal-solicited td .status-indicator,
.table-renewals .renewal-solicited td .status-indicator {
  background-color: rgb(209, 120, 237);
}

/* ========= status submitted ============= */

.table-renewals .renewal-submitted td .status-indicator,
.table-renewals .renewal-submitted:nth-child(even) td .status-indicator {
  background-color: rgba(251, 181, 32, 0.08);
}

.table-renewals .renewal-quote.renewal-submitted td .status-indicator,
.table-renewals .renewal-quote.renewal-submitted:nth-child(even) td .status-indicator {
  background-color: rgba(251, 181, 32, 0.03);
}

.table-renewals .renewal-quote.renewal-submitted td .status-indicator,
.table-renewals .renewal-submitted td .status-indicator {
  background-color: rgb(251, 181, 32);
}

/* ========= status bound ============= */

.table-renewals .renewal-bound td .status-indicator,
.table-renewals .renewal-bound:nth-child(even) td .status-indicator {
  background-color: rgba(59, 178, 237, 0.08);
}

.table-renewals .renewal-bound td .status-indicator {
  background-color: rgb(59, 178, 237);
}

/* ========= cancelled policy ============= */

.table-renewals .renewal-cancelled td .status-indicator,
.table-renewals .renewal-cancelled:nth-child(even) td .status-indicator {
  background-color: rgba(255, 0, 0, 0.13);
}

.table-renewals .renewal-cancelled td .status-indicator {
  background-color: rgb(255, 0, 0);
}

/* ========= status quoted ============= */

.table-renewals .renewal-quoted td .status-indicator,
.table-renewals .renewal-quoted:nth-child(even) td .status-indicator {
  background-color: rgba(78, 202, 171, 0.08);
}

.table-renewals .renewal-quote.renewal-quoted td .status-indicator,
.table-renewals .renewal-quote.renewal-quoted:nth-child(even) td .status-indicator {
  background-color: rgba(78, 202, 171, 0.03);
}

.table-renewals .renewal-quote.renewal-quoted td .status-indicator,
.table-renewals .renewal-quoted td .status-indicator {
  background-color: rgb(78, 202, 171);
}

/* ========= status out to market ============= */

.table-renewals .renewal-out-to-market td .status-indicator,
.table-renewals .renewal-out-to-market:nth-child(even) td .status-indicator {
  background-color: rgba(251, 181, 32, 0.08);
}

.table-renewals .renewal-quote.renewal-out-to-market td .status-indicator,
.table-renewals .renewal-quote.renewal-out-to-market:nth-child(even) td .status-indicator {
  background-color: rgba(251, 181, 32, 0.03);
}

.table-renewals .renewal-quote.renewal-out-to-market td .status-indicator,
.table-renewals .renewal-out-to-market td .status-indicator {
  background-color: rgb(251, 181, 32);
}

/* ========= status closed ============= */

.table-renewals .renewal-closed td .status-indicator,
.table-renewals .renewal-closed:nth-child(even) td .status-indicator {
  background-color: rgba(232, 103, 114, 0.08);
}

.table-renewals .renewal-quote.renewal-closed td .status-indicator,
.table-renewals .renewal-quote.renewal-closed:nth-child(even) td .status-indicator {
  background-color: rgba(232, 103, 114, 0.03);
}

.table-renewals .renewal-quote.renewal-closed td .status-indicator,
.table-renewals .renewal-closed td .status-indicator {
  background-color: rgb(232, 103, 114);
}

/* ========= status renewal ============= */

.table-renewals .renewal-renewal td .status-indicator {
  background-color: #000;
}

/* ========= status new ============= */

.table-renewals .renewal-new td .status-indicator {
  background-color: #a8b0bb;
}

/* ============== renewal notes ================ */

.renewals-manager .renewal-notes-empty i {
  opacity: 0.2;
}

.renewals-manager .renewal-notes-empty a:hover i {
  opacity: 1;
}

/* ============== multiple renewal modal ================ */

.renewal .renewal-error-indicator {
  vertical-align: super;
}

.renewals-modal .modal-body {
  padding: 20px;
}

.renewals-modal ul.items li {
  padding-top: 10px;
  list-style-type: none;
}

.renewals-modal .renewal-notes {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 200px;
  box-sizing: border-box;
}

.popover-title { display: none; }

.multi-select .btn-send-email,
.multi-select .btn-renew,
.multi-select .btn-renew-and-send-email {
  display: none;
}

.multi-select.can-email .btn-send-email,
.multi-select.can-renew .btn-renew,
.multi-select.can-email.can-renew .btn-renew-and-send-email {
  display: inline-block;
}

/* ============= renewal status selector ============= */

.renewal-status-btn {
  display: inline-block;
}

.table-renewals tr.renewal-submitted .renewal-status-solicited a,
.table-renewals tr.renewal-submitted .renewal-status-renewal a {
  display: none;
}

.table-renewals tr.renewal-solicited .renewal-status-submitted a,
.table-renewals tr.renewal-solicited .renewal-status-renewal a {
  display: none;
}

.table-renewals tr.renewal-renewal .renewal-status-submitted a,
.table-renewals tr.renewal-renewal .renewal-status-solicited a {
  display: none;
}

/* ================== renewal actions td ================= */

.table-renewals .actions {
  padding: 0 10px 0 0;
}

/* ================= overview report ==================== */

.report-renewals-overview .row-filter .span12 {
  padding: 20px;
}

.report-renewals-overview .row-filter label {
  font-weight: bold;
}

.report-renewals-overview .select2-container {
  width: auto;
}

table.table-renewals .align-center {
  text-align: center;
}

table.table-renewals .align-left,
table.table-renewals td:first-child,
table.table-renewals th:first-child {
  text-align: left;
}

table.table-renewals-overview th,
table.table-renewals-overview td {
  width: 6.5%;
}

table.table-renewals-overview .col-name {
  width: 17%;
  vertical-align: top;
  white-space: nowrap;
}

table.table-renewals-overview td {
  font-size: 0.8em;
}

table.table-renewals-overview tr:first-child td:first-child {
  font-size: 1em;
}

.table-renewals-overview .row-accounts,
.table-renewals-overview.filter-income .row-accounts,
.table-renewals-overview.filter-accounts .row-income {
  display: none;
}

.table-renewals-overview.filter-income .row-income,
.table-renewals-overview.filter-accounts .row-accounts {
  display: table-row;
}

.table-renewals-overview .type {
  font-size: 0.8em;
  white-space: nowrap;
  width: 5%;
}

.table-renewals-overview .avatar-wrap {
  vertical-align: middle;
  margin-right: 8px;
}

.table-renewals-overview .avatar {
  border-radius: 3px;
}

.table-renewals .border-right {
  border-right: 1px #ddd solid;
}

.btn-business-plan-years .current a {
  font-size: 1.2em;
  background-color: #eee;
}

.renewal-email-modal {
  max-width: 800px;
}

.renewal-email-modal-body-preview p {
  margin: 0 !important;
}

.renewal-email-modal-body .control-group {
  padding: 0 20px;
}

.form-bulk-renew-policies .tab-content-dynamic {
  padding: 5px;
}

.renewal-email-modal .drag-area {
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  font-size: 3em;
  cursor: copy;
  z-index: 100;
}

#bulk_email_and_renew_documents {
  max-height: 200px;
  overflow-y: scroll;
}
