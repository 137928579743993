
.panel {
  width: 100%;
}

.panel-inner {
  margin-left: 0;
  transition: margin 0.3s;
}

.panel-slide {
  overflow: hidden;
}

.panel-slide .panel-inner {
  width: 200%;
}

.panel-slide .panel-pane {
  float: left;
  width: 50%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.panel-slid .panel-inner {
  margin-left: -100%;
  transition: margin 0.3s;
}
