
.gmaps-map {
  height: 400px;
}

.map-container {
  position: relative;
  height: 100%;
}

.map img {
  max-width: none; /* bootstrap fix */
}

.map-fullscreen-container {
  margin-top: 0;
}

.map-fullscreen-container > .content {
  height: calc(100% - 200px);
  padding-top: 90px;
}

.map-fullscreen-container .container,
.map-fullscreen-container .main-column {
  height: 100%;
}

.map-fullscreen-container .main-column > .nav {
  margin-bottom: 15px;
}

.map-fullscreen {
  width: 100%;
  height: 100%;
}

.map-carrier,
.map-agency {
  width: 100%;
  height: 300px;
}

.map-agencies {
  width: 100%;
  height: 600px;
}

.map-info-content .link {
  margin-top: 10px;
  display: block;
}

.map-info-content h5 {
  clear: both;
}

.map-info-content .address-view .streetview {
  float: right;
}

.map-info-content .address-view .streetview img {
  border-radius: 10px;
}

/* ======== map loader =========== */

.map-data-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 30px;
  pointer-events: none;
}

.map-data-loader-content {
  text-align: center;
  margin-top: -30px;
  transition: 0.5s margin-top;
}

.map-data-loading .map-data-loader-content {
  margin-top: 0;
  transition: 0.5s margin-top;
}

.map-data-loader-content-inner {
  display: inline;
  background-color: #ff7;
  padding: 4px 8px 4px 8px;
  border: 1px #884 solid;
  border-top: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-weight: bold;
}

.map-not-loaded {
  background-color: #ddd;
}

.map-not-loaded .map-loader {
  padding: 2%;
  font-size: 2em;
  color: #666;
}

/* ========== map menu ============= */

.map-menu {
  position: fixed;
  background-color: #ffc;
  left: 30px;
  bottom: 30px;
  border-radius: 10px;
  border: 2px #555 solid;
  width: 40px;
  height: 40px;
  opacity: 0.5;
  transition: 0.5s opacity;
}

.map-menu.map-menu-open,
.map-menu:hover {
  opacity: 1;
  cursor: pointer;
  transition: 0.5s opacity;
}

.map-menu.map-menu-open {
  width: 250px;
  height: auto;
  transition: 1s width;
  transition: 1s height;
}

.map-menu-content {
  display: none;
}

.map-menu-open .map-menu-content {
  display: block;
}

.map-menu-content h3 {
  background-color: #a33;
  color: #fff;
  text-align: center;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.map-menu-content input,
.map-menu-content label {
  float: left;
}

.map-menu-content label {
  float: left;
  padding: 0 0 8px 8px;
}

.map-menu-content ul {
  margin: 0 0 0 10px;
  list-style-type: none;
}

.map-menu-content li {
  clear: left;
}
