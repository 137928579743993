.installment-container {
  /* For Firefox */
  scrollbar-width: thin;
  overflow-x: scroll;

  > div,
  ol {
    width: 200vw;

    &:has(> :last-child:nth-child(2)) li .delete > button {
      display: none;
    }
  }

  .delete {
    position: sticky;
    min-width: 25px;
    max-width: 25px;
    right: 0;
  }

  .add {
    position: sticky;
    left: 2.6rem;
  }

  & .installment-header {
    label {
      font-size: 12px;
      line-height: 1.5;
    }
  }

  & .installment-header,
  .installment-footer {
    padding-left: 2.5rem;
    margin-bottom: 0.25rem;
  }

  & .installment-footer,
  .installment-footer p {
    & span {
      font-weight: bold;
    }

    font-size: 0.7rem !important;
  }

  & .installment-footer {
    display: none;
  }

  & .installment-notice {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-right: 1rem;
  }

  & .collection-item {
    border: none;
    padding: 0 0 0 0.5rem;
    margin: 0 0 0 1rem;
  }

  &:has(#installment_plan_invoices_control_group li) .installment-footer {
    display: flex !important;
  }

  &:has(#installment_plan_invoices_control_group li) .installment-footer {
    display: flex !important;
  }

  #balanceDuePeriodFooter {
    .show-error {
      display: none !important;
      color: var(--danger-dark);
      font-size: 80%;
      font-weight: normal;
    }

    .no-error {
      display: block !important;
    }
  }

  .help-success {
    color: var(--success-dark);
    font-size: 14px;
  }

  &:has(input[id*="balanceDuePeriod"].error) #balanceDuePeriodFooter {
    .show-error {
      display: block !important;
    }

    .no-error {
      display: none !important;
    }
  }

  & .input-group:has(input[id*="balanceDuePeriod"].error) .input-group-text,
  & .input-group:has(input[id*="premium"].error) .input-group-text,
  & .input-group:has(input[id*="carrierFee"].error) .input-group-text,
  & .input-group:has(input[id*="carrierInspectionFee"].error) .input-group-text,
  & .input-group:has(input[id*="inspectionFee"].error) .input-group-text,
  & .input-group:has(input[id*="mgaFee"].error) .input-group-text,
  & .input-group:has(input[id*="retailAgencyFee"].error) .input-group-text,
  & .input-group:has(input[id*="willComplyFee"].error) .input-group-text,
  & .input-group:has(input[id*="stampingFee"].error) .input-group-text,
  & .input-group:has(input[id*="stateTax"].error) .input-group-text,
  & .input-group:has(input[id*="riskManagementFee"].error) .input-group-text,
  & .input-group:has(input[id*="technologyFee"].error) .input-group-text,
  & .input-group:has(input[id*="empa"].error) .input-group-text,
  & .input-group:has(input[id*="windpoolFee"].error) .input-group-text,
  & .input-group:has(input[id*="fireMarshallTax"].error) .input-group-text,
  & .input-group:has(input[id*="clearingHouse"].error) .input-group-text,
  & .input-group:has(input[id*="healthyHomes"].error) .input-group-text,
  & .input-group:has(input[id*="municipalTax"].error) .input-group-text,
  & .input-group:has(input[id*="optins"].error) .input-group-text,
  & .input-group:has(input[id*="surcharge"].error) .input-group-text {
    border-color: var(--danger-dark);
  }

  &:has(input[id*="premium"].error) #premiumTotal,
  &:has(input[id*="carrierFee"].error) #carrierFeeTotal,
  &:has(input[id*="carrierInspectionFee"].error) #carrierInspectionFeeTotal,
  &:has(input[id*="inspectionFee"].error) #inspectionFeeTotal,
  &:has(input[id*="mgaFee"].error) #mgaFeeTotal,
  &:has(input[id*="retailAgencyFee"].error) #retailAgencyFeeTotal,
  &:has(input[id*="willComplyFee"].error) #willComplyFeeTotal,
  &:has(input[id*="stampingFee"].error) #stampingFeeTotal,
  &:has(input[id*="stateTax"].error) #stateTaxTotal,
  &:has(input[id*="riskManagementFee"].error) #riskManagementFeeTotal,
  &:has(input[id*="technologyFee"].error) #technologyFeeTotal,
  &:has(input[id*="empa"].error) #empaTotal,
  &:has(input[id*="windpoolFee"].error) #windpoolFeeTotal,
  &:has(input[id*="fireMarshallTax"].error) #fireMarshallTaxTotal,
  &:has(input[id*="clearingHouse"].error) #clearingHouseTotal,
  &:has(input[id*="healthyHomes"].error) #healthyHomesTotal,
  &:has(input[id*="municipalTax"].error) #municipalTaxTotal,
  &:has(input[id*="optins"].error) #optinsTotal,
  &:has(input[id*="surcharge"].error) #surchargeTotal {
    border-color: var(--danger-dark);
  }

  &:has(input[id*="premium"].error) #premiumTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="carrierFee"].error) #carrierFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="carrierInspectionFee"].error) #carrierInspectionFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="inspectionFee"].error) #inspectionFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="mgaFee"].error) #mgaFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="retailAgencyFee"].error) #retailAgencyFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="willComplyFee"].error) #willComplyFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="stampingFee"].error) #stampingFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="stateTax"].error) #stateTaxTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="riskManagementFee"].error) #riskManagementFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="technologyFee"].error) #technologyFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="empa"].error) #empaTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="windpoolFee"].error) #windpoolFeeTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="fireMarshallTax"].error) #fireMarshallTaxTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="clearingHouse"].error) #clearingHouseTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="healthyHomes"].error) #healthyHomesTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="municipalTax"].error) #municipalTaxTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="optins"].error) #optinsTotal {
    color: var(--danger-dark);
  }

  &:has(input[id*="surcharge"].error) #optinsTotal {
    color: var(--danger-dark);
  }

  & .collection-item .invalid-feedback {
    display: none !important;
  }

  ::-moz-scrollbar-thumb {
    border-radius: 10px;
  }

  /* For WebKit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}

.quote-installment-plan-table {
  overflow-x: scroll;
  scrollbar-width: thin;
}

.quote-installment-plan-line-item-cell {
  min-width: 6rem;
  max-width: 6rem;
}

#installment-plans:has(#quote_details_installmentPlanApplied:disabled),
#premium-financing:has(#quote_details_financed:disabled) {
  .select-installment-plan {
    align-items: center !important;
  }

  & .premium-finance-or-installment-plan-info {
    display: inline-block !important;
  }
}
