.steps .title {
  padding-top: 20px;
  border-bottom: 1px #eee solid;
}

.steps .title .content {
  padding-right: 20px;
}

.steps .title ul {
  list-style-type: none;
}

.steps .title li {
  display: inline-block;
}

.steps .title li a {
  padding: 15px 30px;
  text-decoration: none;
  font-family: Gotham Book, sans-serif;
  font-size: 0.92em;
  color: #ccc;
  display: inline-block;
}

.steps .title .done,
.steps .title .prev a {
  background-color: #4ecaab;
}

.steps .title .prev,
.steps .title .on a {
  background-color: #2a3342;
}

.steps .title .done a,
.steps .title .prev a,
.steps .title .on a {
  color: #fff;
}

.steps .title .done a,
.steps .title .prev a,
.steps .title .on a {
  border-top-right-radius: 10px;
}

.steps .title .done a {
  border-right: 1px #35b192 solid;
}

.steps .step {
  display: none;
}

.steps .title .buttons {
  float: right;
}

.steps .footer .buttons {
  text-align: right;
  padding: 0 20px 20px 0;
}

.steps.final .buttons .next,
.steps .buttons .confirm {
  display: none;
}

.steps.final .buttons .confirm {
  display: inline-block;
}
