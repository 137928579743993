body.splash {
  margin: 0;
  background: #fff;
}

body.splash .splash-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 55%;
  left: 45%;
  background-image: url(../img/login-bg.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 70%;
  background-color: #fff;
}

body.splash .button {
  padding: 15px 20px 15px 25px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  background-color: #fff;
  color: #6c7884;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  border-radius: 2px;
  text-transform: uppercase;
}

body.splash .button-google {
  background: url(../img/google-favicon.png) no-repeat 10px;
  padding-left: 50px;
}

body.splash a {
  text-decoration: none;
}

body.splash .wrap {
  top: 50%;
  position: absolute;
  margin-top: -50px;
  margin-left: 150px;
}

body.splash .alert {
  max-width: 500px;
  margin-top: 50px;
  color: #fff;
}

body.splash h1.logo {
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 50px 70px;
}

body.splash h1 {
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 40px;
}

body.splash h1 span {
  font-weight: 800;
}

body.splash h2 {
  font-size: 24px;
  font-weight: 300;
  line-height: 26px;
  border: none;
  margin: 0 0 5px;
  color: #7b94bb;
}

body.splash .alt-login {
  position: fixed;
  left: 10px;
  bottom: 10px;
}

body.splash .alt-login,
body.splash .alt-login a {
  color: #dcdcdc;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

body.splash .alt-login a:hover {
  color: #7c7c7c;
}

body.splash .btn-login {
  padding: 15px 20px 15px 25px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  background-color: #fff;
  color: #6c7884;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 28%);
  border-radius: 2px;
  text-transform: uppercase;
  text-decoration: none;
  border: 0;
}

body.splash .btn-login-google {
  padding-left: 50px;
  background: url(../../../../../../../assets/img/provider-google.png) no-repeat 10px;
}

body.splash .btn-login-microsoft {
  padding-left: 50px;
  background: url(../../../../../../../assets/img/provider-microsoft.png) no-repeat 10px;
}

body.splash .login-link .alert {
  margin-top: 0;
  max-width: auto;
}

body.splash .login-link .control-group {
  padding-left: 0;
  margin-bottom: 20px;
}

body.splash .login-link-email {
  width: 100%;
}

@media (max-width: 767px) {
  body.splash .splash-bg {
    background-image: none;
  }
}
