ul#marketing-company-contact-selections,
ul#marketing-company-contact-selections ul {
  margin-left: 20px;
}

tr.disabled {
  color: gainsboro;
}

#brokerage-markets-and-contacts tbody tr {
  display: none;
}

#brokerage-markets-and-contacts tbody tr.visible {
  display: table-row;
}

#brokerage-markets-and-contacts .notes {
  word-break: break-all;
}

#brokerage-markets-and-contacts .checkbox,
#brokerage-markets-and-contacts .email {
  align-items: center;
}

#brokerage-markets-and-contacts .contact-name {
  white-space: nowrap;
}
