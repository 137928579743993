
.box {
  border-radius: 5px;
  margin-bottom: 30px;
  background-color: #fbfcfd;
}

.box.box-nobg {
  background-color: transparent;
}

.box-filter {
  background-color: transparent;
}

.box .top {
  padding: 15px;
  background-color: #fff;
}

.box .top.accordion-toggle {
  border-radius: var(--radius) var(--radius) 0 0;
  border-bottom: 1px var(--light-gray) solid;
}

.box .top.accordion-toggle.collapsed {
  border-radius: var(--radius);
  border: none;
  border-bottom: 1px #fff solid;
}

.box .top h2,
.box .top h3 {
  font-size: 1.5em;
  font-family: Gotham Book, sans-serif;
  color: #000;
  letter-spacing: 0;
  margin: 0;
  border: none;
  padding: 0;
}

.box .top h2 .circle {
  margin: -1px 5px 0 0;
}

.box .top .breakable {
  word-break: break-word;
}

.box .top .subheading,
.box-body label {
  font-size: var(--font-size-heading);
  color: var(--font-light);
  font-weight: bold;
}

.box .top .subheading a {
  color: var(--primary-color);
}

.box .top .for {
  font-size: 0.8em;
}

.box .top .avatar-wrap {
  vertical-align: top;
}

.box .top.accordion-heading {
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box .top .side-title {
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight-bold);
  color: var(--font-light);
}

.box-standalone .top h2,
.box-standalone .top h3 {
  color: #000;
  border: none;
  padding: 0;
}

.box-checklist .items li:last-child,
.box .name-val li:last-child {
  border-bottom: none;
}

.box .link {
  float: right;
  text-align: right;
  font-size: 0.9em;
  letter-spacing: 0;
}

.box-standalone .link {
  line-height: 1.4em;
}

.box .link a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}

.box-no-top .body {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.box .footer {
  background-color: #fafbfc;
  border: 1px #fff solid;
  overflow: hidden;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.box .footer .content {
  padding: 18px;
}

.box-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 0.5;
}

.box-heading .policy-status-description,
.box .top .status {
  display: flex;
  align-items: center;
}

.box-body {
  padding: 15px;
  font-size: 14px;
  line-height: 0.5;
  font-weight: var(--font-weight);
}

.box-checklist .box-body {
  padding-top: 1px;
}

.box-body label {
  display: block;
  color: var(--font-light);
}

.box-app-num {
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  word-break: break-word;
  padding: 10px 0;
  display: inline-block;
}

.box-users-title {
  border-bottom: 1px solid var(--light-gray);
  padding: var(--mid-spacing) 0;
  margin-bottom: var(--mid-spacing);
  font-size: 14px;
  display: block;
}

.box-body .assignees {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: var(--lg-spacing);
}

.box-body .assign-user {
  float: right;
}

.box-body .bound-quote {
  border-bottom: 1px solid var(--light-gray);
  border-top: none;
  padding-top: calc(var(--lg-spacing) * 1.5);
}

.box-body .bound-quote:first-of-type {
  padding-top: 0;
}

.box-body .bound-quote:last-of-type {
  border-bottom: none;
}

/* ======== filters ========== */

.box-filter .body {
  padding: 0 15px;
}

.box-filter .body .control-group {
  padding-left: 0;
}

/* ======== name/val lists =========== */

.box .name-val {
  list-style-type: none;
}

.box .name-val li {
  padding: 15px;
}

.box .name-val label,
.box .name-val span {
  display: inline-block;
  vertical-align: top;
}

.box .name-val label {
  font-weight: bold;
  width: calc(50% - 5px);
  margin-bottom: 0;
}

.box .name-val span {
  width: calc(50% - 5px);
  margin-left: 5px;
  color: #a3a3a3;
  font-weight: 800;
}

.box .assignee .avatar {
  border-radius: 100px;
}

/* ========== box grid ============= */

.box-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
}

.box-grid-item {
  flex: 0 49%;
  line-height: 1.3;
  margin-bottom: calc(var(--lg-spacing) * 1.5);
}

.box-grid-title {
  color: var(--font-color);
  font-weight: var(--font-weight-bold);
  display: block;
}

/* ========== check lists ============= */

.checklist .items {
  list-style-type: none;
}

.checklist .checklist-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.checklist-actions {
  width: 130px;
}

.checklist .checklist-name {
  width: calc(100% - 140px);
  font-weight: var(--font-weight);
  color: var(--font-color);
  margin-bottom: 0;
  line-height: 1.1;
  font-size: var(--font-size-heading);
}

.checklist .checklist-button {
  font-size: var(--small-font-size);
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 300;
  background: var(--light-gray);
  border-radius: 12px;
  color: var(--font-light);
  width: 100%;
  display: flex;
  align-items: stretch;
  padding-left: 10px;
}

.checklist .checklist-button:hover {
  background: var(--light-gray);
  text-decoration: none;
}

.checklist .checklist-button .checklist-button-icon {
  padding: 7px 0;
}

.checklist .checklist-button > * {
  display: flex;
  align-items: center;
}

.checklist .checklist-button .checklist-button-desc {
  padding: 7px 5px;
}

.checklist-button-arrow {
  background: var(--lightmid-gray);
  color: var(--font-color);
  margin-left: auto;
  border-radius: 0 12px 12px 0;
  padding: 0 10px 0 8px;
}

.status-na .checklist-name {
  color: var(--lightmid-gray);
  text-decoration: line-through;
}

.checklist-button-icon,
.checklist-dropdown-item .dropdown-icon {
  color: var(--font-color);
}

.status-complete .checklist-button-icon,
.checklist-dropdown-item.status-complete .dropdown-icon {
  color: var(--success-color);
}

.status-incomplete .checklist-button-icon,
.checklist-dropdown-item.status-incomplete .dropdown-icon {
  color: var(--danger-color);
}

.btn-group .checklist-dropdown {
  border: none;
  width: 130px;
  min-width: 130px;
  padding: 0 !important;
}

.checklist-dropdown .dropdown-icon {
  width: 10px;
}

.checklist-dropdown li a {
  font-size: var(--small-font-size);
  text-transform: uppercase;
  font-weight: 300;
  padding: 7px 5px 7px 10px;
}

/* ============ collapse arrow ============== */
.accordion-toggle {
  padding-bottom: 10px;
}

.accordion-toggle .collapse-button-func {
  transform: rotate(0);
  transition: all 0.1s ease-in;
  font-size: 24px !important;
  padding: 5px 20px;
}

.accordion-toggle.collapsed .collapse-button-func {
  transform: rotate(180deg);
}

.accordion-toggle .collapse-button-func {
  color: var(--primary-color) !important;
}

/* ============ clippy ========== */
.box-clippy .clippy {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}

.box-clippy {
  position: relative;
}

/* ========== Responsive ============= */

@media (max-width: 1200px) {
  .box .top h2,
  .box .top h3 {
    font-size: 1.5em;
  }

  .box .name-val li {
    padding: 15px;
  }

  .box .name-val label,
  .box .name-val span {
    display: inline;
  }

  .box .link {
    font-size: 0.73em;
  }

  .box .top.accordion-heading .link {
    float: left;
    text-align: left;
  }

  .box .top.accordion-heading .link-button {
    float: right;
    text-align: right;
  }

  .box .top.accordion-heading .link-button input {
    margin-bottom: 39px;
    margin-right: 0;
  }
}
