.dropzone-container {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  border: 1px dashed var(--disabled-dark-color);
  border-radius: var(--radius);
  align-items: center;
  padding: 20px;
  font-size: var(--font-size-heading);
}

.dropzone-container:has(input.error) {
  border-color: var(--danger-color) !important;
}

.dropzone-container:hover {
  border-color: var(--primary-color);
}

.dropzone-container:has(input:disabled) {
  background: var(--disabled-color);
  border: 1px dashed var(--disabled-dark-color);
  color: var(--disabled-dark-color);
}

.dropzone-container:has(input:disabled) .dropzone-input:hover {
  cursor: default;
}

.dropzone-container:has(input:disabled) .btn-primary {
  background-color: var(--disabled-dark-color);
  color: #fff;
  border-color: var(--disabled-dark-color);
}

.dropzone-container:has(input.dragover) {
  border-color: var(--primary-color);
  background-color: var(--blue-lighter);
}

.dropzone-input {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.dropzone-placeholder {
  background: url('data:image/svg+xml,<svg width="27" height="34" viewBox="0 0 27 34" fill="%234B5167" xmlns="http://www.w3.org/2000/svg"><path d="M16.8332 0.333496H3.49984C1.6665 0.333496 0.166504 1.8335 0.166504 3.66683V30.3335C0.166504 32.1668 1.6665 33.6668 3.49984 33.6668H23.4998C25.3332 33.6668 26.8332 32.1668 26.8332 30.3335V10.3335L16.8332 0.333496ZM20.1665 27.0002H6.83317V23.6668H20.1665V27.0002ZM20.1665 20.3335H6.83317V17.0002H20.1665V20.3335ZM15.1665 12.0002V2.8335L24.3332 12.0002H15.1665Z"/></svg>') no-repeat center top;
  padding: 50px 0 10px 0;
  flex-grow: 1;
  text-align: center;
}

.dropzone-container:has(input:disabled) .dropzone-placeholder {
  background-image: url('data:image/svg+xml,<svg width="27" height="34" viewBox="0 0 27 34" fill="%23a6aeb7" xmlns="http://www.w3.org/2000/svg"><path d="M16.8332 0.333496H3.49984C1.6665 0.333496 0.166504 1.8335 0.166504 3.66683V30.3335C0.166504 32.1668 1.6665 33.6668 3.49984 33.6668H23.4998C25.3332 33.6668 26.8332 32.1668 26.8332 30.3335V10.3335L16.8332 0.333496ZM20.1665 27.0002H6.83317V23.6668H20.1665V27.0002ZM20.1665 20.3335H6.83317V17.0002H20.1665V20.3335ZM15.1665 12.0002V2.8335L24.3332 12.0002H15.1665Z"/></svg>');
}

.dropzone-preview {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
}

.dropzone-preview li {
  margin-bottom: 0 !important;
}

[data-controller="form--file-upload"] {
  margin-bottom: 0.5rem;
}

[data-controller="form--file-upload"]:has(.dropzone-preview:empty) {
  margin-bottom: 1rem;
}

.dropzone-preview li > .dropzone-preview-details {
  background: url('data:image/svg+xml,<svg width="27" height="34" viewBox="0 0 27 34" fill="%234B5167" xmlns="http://www.w3.org/2000/svg"><path d="M16.8332 0.333496H3.49984C1.6665 0.333496 0.166504 1.8335 0.166504 3.66683V30.3335C0.166504 32.1668 1.6665 33.6668 3.49984 33.6668H23.4998C25.3332 33.6668 26.8332 32.1668 26.8332 30.3335V10.3335L16.8332 0.333496ZM20.1665 27.0002H6.83317V23.6668H20.1665V27.0002ZM20.1665 20.3335H6.83317V17.0002H20.1665V20.3335ZM15.1665 12.0002V2.8335L24.3332 12.0002H15.1665Z"/></svg>') no-repeat left center;
  background-size: 16px 20px;
  line-height: 20px;
  padding: 10px 0 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light-gray);
}

.dropzone-preview li:last-of-type > .dropzone-preview-details,
.file-upload__list li:last-of-type {
  border-bottom: none;
}

.dropzone-preview-details span {
  margin-bottom: 0 !important;
}

.dropzone-preview-file:not(:empty) {
  border: 1px solid var(--light-gray);
  text-align: center;
  border-radius: var(--radius);
}

.dropzone-preview--has-list:has(li) + .file-upload__list {
  border-top: 1px solid var(--light-gray);
}

.file-upload__list .file {
  padding-left: 0 !important;
  border-bottom: 1px solid var(--light-gray);
}

.file-upload__list .actions,
.file-upload__list .actions .action-delete a {
  padding-right: 0;
}

.file-upload__list.files-list .title {
  color: var(--font-color);
  background: url('data:image/svg+xml,<svg width="27" height="34" viewBox="0 0 27 34" fill="%234B5167" xmlns="http://www.w3.org/2000/svg"><path d="M16.8332 0.333496H3.49984C1.6665 0.333496 0.166504 1.8335 0.166504 3.66683V30.3335C0.166504 32.1668 1.6665 33.6668 3.49984 33.6668H23.4998C25.3332 33.6668 26.8332 32.1668 26.8332 30.3335V10.3335L16.8332 0.333496ZM20.1665 27.0002H6.83317V23.6668H20.1665V27.0002ZM20.1665 20.3335H6.83317V17.0002H20.1665V20.3335ZM15.1665 12.0002V2.8335L24.3332 12.0002H15.1665Z"/></svg>') no-repeat left center;
  background-size: 16px 20px;
  line-height: 20px;
  padding: 0 0 0 30px;
}

.file-upload__list.files-list .title svg {
  display: none;
}

.signature-image .dropzone-preview-file:not(:empty) {
  border: none;
}

.upload-small-preview .dropzone-preview-file {
  height: 80px;
}

.dropzone-preview-file img {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}

.dropzone-preview-filename {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  color: var(--font-color);
  text-decoration: none;
}

.dropzone-delete-button {
  display: block;
  flex-shrink: 0;
  cursor: pointer;
  color: var(--danger-color) !important;
  opacity: 1;
  font-size: 1rem;
  margin-bottom: -2px;
}

.dropzone-delete-button:hover {
  color: var(--danger-color);
  opacity: 0.75;
}

.fileupload-container {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.fileupload-container .dropzone-input[disabled] + .btn {
  border-color: var(--disabled-dark-color);
  color: var(--disabled-dark-color);
}

.fileupload-container .dropzone-input[disabled]:hover {
  cursor: default;
}

.fileupload-container .dropzone-input {
  padding-left: 100%;
}

.fileupload-container:has(.btn-browse.d-none) {
  display: none;
}

.fileupload-filename {
  max-width: 90%;
  display: inline-block;
}
