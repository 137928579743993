.conversations-box {
  display: flex;
}

.conversations-box .conversations-list {
  border-right: 1px solid #eee;
  height: 100%;
  background-color: #f9fafb;
}

.conversations-box .conversations-list .form-conversation .mode {
  width: 100%;
}

.conversations-box .conversations-list ul {
  list-style: none;
  padding: 0;
}

.conversations-box .conversations-list .conversation-list-item .item-date {
  font-size: 12px;
  margin-top: 8px;
}

.conversations-box .conversations-list .conversation-list-item {
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.conversations-box .conversations-list .conversation-list-item button {
  width: 100%;
}

.conversations-box .conversations-list .conversation-list-item button.progress {
  text-align: center;
}

.conversations-box .conversations-content .intab-cancel-wrapper {
  display: none;
}

.conversations-box .conversations-content {
  position: relative;
  padding-bottom: 140px;
}

.conversations-box .conversations-content .conversation-header {
  background-color: #fff;
  padding: 7px;
  border-bottom: 1px solid #eee;
}

.conversations-box .conversations-content .conversation-open .action-open,
.conversations-box .conversations-content .conversation-closed .action-close {
  display: none;
}

.conversations-box .conversations-content .conversation-header input[type=text] {
  font-size: 15px;
  font-weight: bold;
  background: transparent;
  border: none;
}

.conversations-box .conversations-content .conversation-header.conversation-new input[type=text] {
  font-weight: normal;
}

.conversations-box .conversations-content .conversation-message {
  margin-top: 20px;
}

.conversations-box .conversations-content .conversation-message .avatar {
  border-radius: 50%;
  opacity: 0.8;
}

.conversations-box .conversations-content .conversation-message .message-user {
  padding: 10px;
  text-align: center;
}

.conversations-box .conversations-content .conversation-message .conversation-bubble {
  position: relative;
  background-color: #f9fafb;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #eff2f4;
}

.conversations-box .conversations-content .conversation-message .conversation-bubble .bubble-heading {
  padding: 10px 12px 0;
}

.conversations-box .conversations-content .conversation-message .conversation-bubble .bubble-body {
  padding: 10px 15px 15px;
}

.conversations-content .conversation-message .conversation-bubble::after,
.conversations-content .conversation-message .conversation-bubble::before {
  top: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.conversations-content .conversation-message .conversation-bubble::after {
  border-color: rgba(249, 250, 251, 0);
  border-width: 12px;
  margin-top: -12px;
}

.conversations-content .conversation-message .conversation-bubble::before {
  border-color: rgba(239, 242, 244, 0);
  border-width: 13px;
  margin-top: -13px;
}

.conversations-content .conversation-message .conversation-bubble.left::before {
  right: 100%;
  border-right-color: #eff2f4;
}

.conversations-content .conversation-message .conversation-bubble.left::after {
  right: 100%;
  border-right-color: #f9fafb;
}

.conversations-content .conversation-message .conversation-bubble.right::before {
  left: 100%;
  border-left-color: #eff2f4;
}

.conversations-content .conversation-message .conversation-bubble.right::after {
  left: 100%;
  border-left-color: #f9fafb;
}

.conversations-box .conversations-content .conversation-message .conversation-bubble .footer {
  border: none;
}

.conversations-box .conversations-content .conversation-message .conversation-bubble .footer .files-list .file {
  border: none;
  background-color: transparent;
}

.conversations-box .conversations-content .conversation-tools .form-assignees .name {
  font-size: 12px;
}

.conversations-box .conversations-content .conversation-tools .control-group {
  padding-left: 0;
}

.conversations-box .conversations-content .conversation-tools .tools-header {
  text-align: right;
  padding: 9px;
  margin-left: 0;
  border-bottom: 1px solid #eee;
}

.conversations-box .conversations-content .conversation-tools .conversation-tools-tags .controls {
  margin: 10px 0 0 10px;
}

.conversations-box .conversation-new-message {
  background: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.05);
}

.conversations-box .conversation-new-message > div {
  padding: 0 10px;
}

.conversations-box .conversation-new-message .new-message-input {
  padding: 15px 10px;
}

.conversations-box .conversation-new-message .new-message-input textarea {
  height: 103px;
  width: 100%;
  max-width: none;
}

.conversations-box .conversation-new-message .new-message-buttons {
  padding-top: 15px;
  padding-bottom: 15px;
}

.conversations-box .conversation-new-message .new-message-buttons .btn-add-file-wrap {
  padding: 0;
  text-align: left;
}

.conversations-box .conversation-new-message .new-message-buttons button,
.conversations-box .conversation-new-message .new-message-buttons a {
  width: 100%;
  margin-bottom: 5px;
  box-sizing: border-box;
  text-align: center;
}

.conversations-box .item-title {
  color: #211f1f;
  font-size: 12px;
}

.conversations-box .item-date {
  color: #737373;
  font-size: 10px;
}

.conversations-box .item-content {
  color: #211f1f;
  font-size: 12px;
  font-weight: 200;
}

.conversations-box .conversation-actions {
  text-align: right;
  padding: 6px;
}
