
.invoice .fees li,
.invoice .fees label,
.invoice .fees span {
  display: inline-block;
}

.invoice a {
  text-decoration: none;
}

.invoice .sec {
  overflow: hidden;
}

.invoice .sec > div {
  padding: 25px 30px;
  box-sizing: border-box;
  float: left;
}

.invoice .sec4 {
  width: 80%;
  border-left: 1px solid #eee;
}

.invoice .sec4 .fees li {
  width: 22%;
  margin-left: 3%;
  border-top: none;
}

.invoice .sec4 .fees > li:nth-child(4n+1) {
  margin-left: 0;
}

.invoice .sec4 .fees > li:nth-child(1n+5) {
  border-top: 1px solid #eee;
}

.invoice .sec3 {
  width: 20%;
  border-left: 1px solid #eee;
}

.invoice .sec3 .fees li {
  width: 100%;
  margin-left: 0%;
}

.invoice .sec3 .fees li:nth-child(2) {
  border-top: 1px solid #eee;
}

.invoice .fees-first {
  margin: 37px 0;
}

.invoice .fees-first > p {
  font-size: 25px;
  margin-top: 10px;
}

.invoice .fees label {
  font-weight: bold;
  width: 56%;
}

.invoice .fees > li > span {
  width: 40%;
  text-align: right;
}

.invoice .documents {
  max-width: 60%;
}

.invoice .documents .btn-group {
  display: inline-block;
  margin-bottom: 3px;
}

.invoice .documents a.btn {
  color: white;
}

.invoice-unavailable {
  cursor: auto;
  opacity: 0.5;
  pointer-events: none;
}

/* ============== name/val =================== */

.invoice .name-val li {
  display: inline-block;
  vertical-align: top;
  width: 47%;
  padding: 23px 0;
  border-top: 1px solid #eee;
  position: relative;
}

.invoice .name-val-less {
  border-top: none;
}

.invoice .name-val-less li {
  padding: 15px 0;
}

.invoice .name-val li:nth-child(1),
.invoice .sec2 .name-val li:nth-child(2) {
  border-top: none;
}

/* ============== policy invoice =============== */

.invoice-policy {
  padding: 20px;
}

.invoice-policy .fees {
  clear: both;
  padding: 20px 0;
}

.invoice-policy .fees li {
  margin-bottom: 8px;
}

/* ========= invoice finance overview ========== */

.invoice .finance {
  padding: 10px 0 10px 20px;
  border-top: 1px solid #eee;
}

.invoice .finance label {
  font-size: 1.1em;
  font-weight: bold;
  text-align: right;
  padding: 5px 10px 5px 0;
}

.invoice .finance .total label {
  color: #3bb2ed;
}

.invoice .finance .paid label {
  color: #4ecaab;
}

.invoice .finance .outstanding label {
  color: #eb5767;
}

.invoice .finance li {
  width: 24%;
}

.invoice .finance.name-val li {
  border-top: none;
}

/* =========== payments ============ */

.table-payments .avatar {
  border-radius: 3px;
}

/* ============== invoice detail in modal =============== */

.modal-body .invoice .fees label {
  width: 100%;
}

.modal-body .invoice .fees > li > span {
  width: 100%;
  text-align: left;
}

.modal-body .invoice .sec > div {
  padding: 0 10px;
}

.modal-body .summary .name-val > li > label,
.modal-body .summary .name-val > li > span {
  margin-bottom: 0;
}

.modal-body .invoice .name-val-less > li > label,
.modal-body .invoice .name-val-less > li > span {
  margin-bottom: 10px;
}

.modal-body .invoice .sec3 {
  width: 25%;
}

.modal-body .invoice .sec4 {
  width: 74%;
  border-left: none;
}

.modal-body .invoice .sec4 .fees li {
  width: 30%;
  margin-left: 3%;
}

.modal-body .invoice .sec4 .fees > li:nth-child(3n+1) {
  margin-left: 0;
}

.modal-body .invoice .sec4 .fees > li:nth-child(1n+4) {
  border-top: 1px solid #eee;
}

/* ================= line items ======================== */

.rates .collection-item > .row > .control-group {
  margin-bottom: 0;
}
