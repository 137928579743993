
.history .row-inset > [class*=col-8]:first-child,
.history .row-inset > [class*=span8]:first-child {
  background-color: #f4f6f9;
}

.history .items {
  margin-left: 40px;
  border-left: 2px #fff solid;
  padding: 30px 0 20px 30px;
  list-style-type: none;
}

.history .item {
  background-color: #fff;
  margin: 0 20px 20px 0;
  position: relative;
  border-radius: 5px;
}

.history .item h3 {
  border: none;
  font-size: 1em;
  margin: 0;
  padding: 0 0 5px 0;
  font-weight: bold;
}

.history .desc {
  font-size: 0.9em;
}

.history .avatar {
  border-radius: 2px;
}

.history .pointer {
  position: absolute;
  left: -10px;
  top: 20px;
}

.history .item > .title {
  padding: 10px;
}

.history .item > .title .avatar-wrap,
.history .item > .title .inner {
  display: inline-block;
  vertical-align: top;
}

.history .item > .title .inner {
  margin-left: 8px;
  max-width: calc(100% - 60px);
}

.history .body {
  min-height: 14px;
}

.history .body .inner,
.history .toggle-wrap,
.history .view-wrap {
  padding: 10px;
}

.history .inner.collapsed {
  display: none;
}

.history .body,
.history .view-wrap,
.history .toggled .toggle-wrap-more {
  border-top: 1px #eee solid;
}

.history .toggle-wrap-less {
  display: none;
  border-bottom: 1px #eee solid;
}

.history .toggled .toggle-wrap-less {
  display: block;
}

.history .toggle-text-less,
.history .toggled .toggle-text-more {
  display: none;
}

.history .toggled .toggle-text-less {
  display: inline;
}

.history .toggle-wrap,
.history .view-wrap {
  font-size: 0.9em;
}

.history .view-wrap {
  min-height: 1em;
}

.history .view-files {
  float: right;
}

.history .toggle {
  text-decoration: underline;
  cursor: pointer;
}

.history .toggle:hover {
  text-decoration: none;
}

.history .files .file {
  background-color: transparent;
}

.history .files .file:last-child {
  border-bottom: none;
}

.history .email-addresses {
  margin-bottom: 15px;
  line-height: 1.4em;
}

.history .email-addresses .name-val {
  list-style-type: none;
}

.history .email-addresses .name-val label {
  font-weight: bold;
}

/* ============ history file collapse ============== */

.history .spacer {
  border-top: 1px #eee solid;
  background-color: #f4f6f9;
}

.history .spacer .inner {
  border-left: 2px #eee solid;
  margin-left: 27px;
  padding: 15px;
  background-color: #f4f6f9;
}

.history .spacer .expand {
  border: 3px #eee solid;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: -35px;
  background-color: #fff;
  line-height: 30px;
  text-align: center;
  font-size: 0.8em;
  cursor: pointer;
}

.history .spacer .expand:hover {
  border-color: #3bb2ed;
}

/* ========== icons =========== */

.history .circle-icon {
  position: absolute;
  left: -47px;
  top: 15px;
}

.history .toggle-edit {
  display: none;
  float: right;
  margin-left: 6px;
  color: #3bb2ed;
}

.history .title h3 a {
  text-decoration: none;
}

.history .title h3:hover .toggle-edit {
  display: block;
}

.history .circle-icon.toggle-delete:hover,
.history .deleted .circle-icon {
  background-color: #eb5767;
}

.history .circle-icon.toggle-delete:hover i,
.history .deleted .circle-icon i {
  background-position: -312px 0;
}

.history .deleted .circle-icon.toggle-delete:hover {
  background-color: #4ecaab;
}

.history .deleted .circle-icon.toggle-delete:hover i {
  background-position: -288px 0;
}

.history .deleted .title,
.history .deleted .body,
.history .deleted .footer {
  opacity: 0.5;
}

/* ========== history by type ========== */

.modal-history-by-type .control-group input,
.modal-history-by-type .control-group textarea {
  width: 90%;
}

.modal-history-by-type ul {
  list-style-type: none;
}

.modal-history-by-type [class*=span].controls {
  margin-left: 0;
}

.modal-history-by-type .files-grid .actions {
  display: none;
}

.form-history-by-type .row-submit {
  margin: 20px;
}

.form-history-by-type .alert-placeholder .alert {
  margin: 0;
  margin-left: -10px;
  width: calc(100% - 30px);
}

.modal-history .collection-item {
  margin: 0 1rem 1rem 1rem;
}

.modal-history .collection-item > button.close {
  top: 10px;
  right: 10px;
}

/* =============== name/val in fragments ================= */

.history .fragment .name-val {
  list-style-type: none;
}

.history .fragment .name-val label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 10px;
}
