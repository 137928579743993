.dashboard > .row,
.key-indicators > .row,
.admin-section > .row {
  margin-right: 0;
}

.widget {
  border-radius: var(--radius);
  border: 1px solid var(--light-gray);
  display: table;
  width: 100%;
  background-color: #fff;
  margin-bottom: 30px;
  overflow: hidden;
}

.widget a {
  text-decoration: none;
  font-weight: bold;
}

.widget .title .content {
  padding: 20px 0 15px 20px;
  border-bottom: 1px solid var(--light-gray);
}

.widget .title .inner {
  font-size: 16px;
  font-weight: bold;
}

.widget .title a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
}

.widget .title .subtitle {
  color: gray;
  font-weight: var(--font-weight-thin);
  font-size: small;
}

.widget.fixed-height > .body > .content {
  max-height: 590px;
  overflow: auto;
}

.widget .text-content {
  padding: 20px;
}

.widget .view-all {
  margin-right: 3%;
}

/* =========== top widget ========== */

.widget-top {
  border-radius: 5px;
  display: table;
  width: 100%;
  background-color: #545e75;
  margin-bottom: 30px;
  overflow: hidden;
}

.widget-top .title .content {
  padding: 15px 0 0 25px;
}

.widget-top .title .content .inner {
  color: #fff;
  opacity: 0.7;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.widget-top .body .content {
  padding: 20px 25px;
}

.widget-top .body .content .inner {
  color: #fff;
  font-size: 28px;
}

.widget-top .footer .content {
  padding: 9px 25px;
  border-top: 1px solid #b7b7b78a;
}

.widget-top .footer .content .inner {
  color: white;
  font-size: 12px;
}

.pulse-spinner {
  display: none;
  padding: 0 12px;
}

.content-loading .pulse-spinner {
  display: block;
}

/* =========== top widget Goal progress bar ========== */

.progress-pie {
  width: 65px;
  height: 64px;
  float: left;
  position: relative;
  margin: 15px;
}

.progressbar-text {
  position: absolute;
  left: 15px;
  top: 29px;
  padding: 0;
  margin: 0;
  transform: translate(-15%, -30%);
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 10px;
  width: 50px;
}

.progressbar-text span {
  font-size: 13px;
  font-weight: bold;
  line-height: 14px;
}

.progressbar-text a {
  color: #12aaeb;
  font-weight: bold;
  text-decoration: none;
  line-height: 14px;
}

/* =============== top widget Pulse spinner =============== */

.pulse-spinner span {
  color: #a7a7a7;
  font-size: 24px;
  line-height: 9px;
  font-family: serif;
  animation-name: blink-spinner;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.pulse-spinner span:nth-child(2) {
  animation-delay: 0.2s;
}

.pulse-spinner span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink-spinner {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

/* =========== visits widget ========== */

.widget-visits .body .inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.widget-visits .avatar-name {
  display: inline-block;
  vertical-align: top;
  margin: 7px 0 0 6px;
}

/* =========== performance widget ========== */

.widget-performance {
  background: white;
  margin: 0 0 25px 0;
  border-radius: 3px;
  padding-top: 5px;
  border: 1px solid var(--light-gray);
}

.tab-content .widget-performance {
  border: none;
  background: var(--light-gray-bg);
}

.widget-performance .tab-content {
  background-color: var(--light-gray-bg);
  min-height: 500px;
  border: none;
}

.widget-performance .body .content {
  max-height: none;
}

.widget-performance .widget-top {
  color: #fff;
}

.widget-performance .span6:nth-child(2) .widget-top,
.widget-performance .col-6:nth-child(2) .widget-top,
.widget-performance .col-lg-3:nth-child(2) .widget-top,
.widget-performance .span3:nth-child(2) .widget-top {
  background-color: #5b8ebf;
}

.widget-performance .col-lg-3:nth-child(3) .widget-top,
.widget-performance .span3:nth-child(3) .widget-top {
  background-color: #304d6d;
}

.widget-performance .col-lg-3:nth-child(4) .widget-top {
  background-color: #7792ab;
}

.widget-performance .key-indicators {
  border-top: 1px solid var(--light-gray);
}

.widget-performance .performance-date-indicator {
  float: right;
  margin: -45px 20px;
  font-weight: 800;
  opacity: 0.5;
  font-size: 13px;
}

.widget-performance .performance-date-indicator i {
  font-size: 20px;
  opacity: 0.4;
}

.widget-performance .filter-tabs {
  display: inline-block;
}

.widget-performance .filter-selector {
  display: flex;
  min-width: 250px;
  max-width: 400px;
  align-items: center;
  color: var(--font-color);
}

.performance-filters {
  display: flex;
  margin: 15px 20px 20px 20px;
  align-items: center;
}

.performance-filters .nav-tabs {
  margin: 0 40px 0 0;
}

.widget-performance .filter-selector .select2-clearer {
  vertical-align: middle;
}

.widget-performance .sidebar-tabs ul li {
  min-height: 64px;
}

.widget-performance .chart-container {
  padding: 10px;
}

.widget-performance .chart-title {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 600;
  padding: var(--mid-spacing);
}

.widget-performance .sidebar-tabs .tab-extra-content > div:first-child {
  font-size: 14px;
  margin-bottom: 10px;
}

.widget-performance .sidebar-tabs .tab-extra-content > div:last-child {
  font-weight: 200;
  line-height: 20px;
}

.widget-performance .sidebar-tabs .tab-extra-content .positive {
  color: #4ed038;
  font-weight: bold;
}

.widget-performance .sidebar-tabs .tab-extra-content .negative {
  color: #f13a30;
  font-weight: bold;
}

.widget-performance .sidebar-tabs .tab-extra-list {
  list-style-type: disc;
  margin: 10px 0 0 15px;
}

.widget-performance .sidebar-tabs .tab-extra-list li {
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  line-height: 17px;
}

/* =========== social widget ========== */

.widget-social .body {
  background: var(--light-gray);
}

.social-card {
  list-style-type: none;
  background: white;
  border-radius: 3px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #e6eaee;
}

.social-card a {
  text-decoration: none;
}

.social-card .avatar-wrap {
  vertical-align: top;
}

.social-card .avatar-wrap .avatar {
  border-radius: 40px;
}

.social-card .social-card-content {
  width: calc(100% - 85px);
  display: inline-block;
  margin: 15px 0 0 15px;
}

.social-card .social-card-title {
  font-size: 15px;
  font-weight: 500;
  color: #4b5167;
}

.social-card .social-card-subtitle {
  margin-top: 10px;
  font-size: 10px;
}

.social-card .social-card-description {
  font-size: 12px;
  padding: 15px 0;
  overflow: auto;
}

.social-card .social-card-description .fragment > p {
  margin: 10px 0;
}

.social-card .social-card-description img {
  border-radius: 3px;
  margin-right: 10px;
  max-width: 200px;
}

.social-card .social-card-description iframe {
  max-width: 200px;
  height: auto;
}

.social-card .likes,
.social-card .comments-count {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  color: #aaa;
}

/* =========== activities widget ========== */

.widget-activity .body {
  background: var(--light-gray-bg);
}

.activity-list li {
  list-style-type: none;
  margin: 10px;
}

.activity-list .widget {
  border: 1px solid var(--light-gray);
  margin-bottom: 0;
}

.activity-list .widget .title .content {
  padding: 15px 0 10px 20px;
}

.activity-list .widget .title .inner {
  font-size: 12px;
}

.activity-list .widget .title .padding-top {
  padding-top: 8px;
}

.activity-list .widget .title .view-all {
  padding-top: 5px;
}

.activity-list .widget .title .inner .avatar-wrap {
  margin-right: 9px;
}

.activity-list .widget .title .inner .avatar-wrap img {
  border-radius: 100px;
}

.activity-list .widget .title .inner .activity-user {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.activity-list .widget .table-listing tr:last-of-type td {
  border-bottom: none;
}

/* =========== tasks widget ========== */

.widget-tasks .body {
  background: var(--light-gray-bg);
}

.task-card {
  list-style-type: none;
  background: white;
  border-radius: 3px;
  margin: 10px;
  padding: 10px;
}

.task-card .task-card-header {
  display: flex;
  align-items: center;
}

.task-card .task-card-header .task-header-left {
  display: flex;
}

.task-card .task-card-header .task-header-left > div {
  margin-right: 10px;
}

.task-card .task-card-header .task-date {
  font-size: 12px;
  font-weight: 700;
  color: #4b5167;
}

.task-card .task-card-header .task-status .dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
}

.task-card .task-card-header .task-status {
  font-size: 10px;
  line-height: 18px;
}

.task-card.task-status-overdue {
  border-left: 4px solid #ee4d4d;
}

.task-card.task-status-active {
  border-left: 4px solid #3cba27;
}

.task-card.task-status-overdue .task-card-header .task-status {
  color: #ee4d4d;
}

.task-card.task-status-active .task-card-header .task-status {
  color: #3cba27;
}

.task-card.task-status-overdue .task-card-header .task-status .dot {
  background-color: #ee4d4d;
}

.task-card.task-status-active .task-card-header .task-status .dot {
  background-color: #3cba27;
}

.task-card .task-card-header a {
  float: right;
  color: #597c94;
  font-size: 10px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 3px;
  text-decoration: none;
  background-color: rgba(211, 223, 235, 0.9);
}

.task-card .task-card-description {
  font-size: 12px;
  padding: 10px 0;
}

.task-card .task-card-footer {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.task-card .task-card-footer .avatar-wrap {
  margin-right: 10px;
}

/* ============ responsive ============= */

@media (max-width: 767px) {
  .dashboard .divider {
    margin-left: 0;
    width: 100%;
  }

  .dashboard .boxes > li {
    width: 100%;
    margin: 0;
  }

  .widget-alerts {
    padding: 0 0 0 4px;
    width: calc(100% - 4px);
  }

  .widget-visits .table-listing {
    zoom: 100%;
  }

  .widget-visits .table-listing tr td {
    background-color: inherit;
  }
}

@media (max-width: 1200px) {
  .dashboard .widget-top .body .content .inner {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .dashboard .boxes > li {
    width: 30%;
  }
}

@media (min-width: 1200px) {
  .dashboard .admin-section {
    padding: 0 20px;
  }
}

@media (max-width: 979px) and (min-width: 768px) {
  .dashboard .row {
    margin-left: 0;
  }
}

@media (max-width: 979px) {
  .performance-filters {
    align-items: flex-start;
  }
}
