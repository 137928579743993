
.listing {
  list-style-type: none;
  clear: both;
}

.listing > li {
  border-top: 1px var(--light-gray) solid;
  position: relative;
}

.listing > li:nth-child(even) {
  background-color: #fafbfc;
}

.listing-headerless > li:first-child {
  border-top: none;
}

.listing > li:last-child {
  border-bottom: 1px var(--light-gray) solid;
}

.listing .title-action {
  float: right;
}

.listing .avatar {
  display: inline-block;
  border-radius: 2px;
  margin-right: 6px;
}

.listing .inner {
  display: inline-block;
  vertical-align: top;
  max-width: 70%;
}

.listing .title,
.listing .body {
  padding: 20px;
}

.listing .body {
  padding-top: 0;
}

.listing h3,
.listing h3 a {
  margin: 0;
  padding: 0;
  border: none;
  letter-spacing: 0;
  font-size: 1.2em;
  text-decoration: none;
  margin-bottom: 5px;
  word-break: break-all;
  font-weight: 600;
}

.listing .title {
  color: #4b5167;
}

.listing .subheading {
  font-size: 0.92em;
}

/* ========= name/val =========== */

.listing .name-val {
  list-style-type: none;
  border-top: 1px solid var(--light-gray);
}

.listing .name-val li {
  display: inline-block;
  vertical-align: top;
  width: 47%;
  padding: 23px 0;
  border-top: 1px solid var(--light-gray);
  position: relative;
}

.listing .name-val li:nth-child(1),
.listing .name-val li:nth-child(2) {
  border-top: none;
}

.listing .name-val > li:nth-child(even) {
  margin-left: 4%;
}

.listing .name-val .name-quotes li {
  border: none;
  padding: 3px;
  width: 100%;
}

.listing .name-val label,
.listing .name-val span {
  display: inline-block;
  vertical-align: top;
}

.listing .name-val label {
  font-weight: bold;
  width: 25%;
}

.listing .name-val span {
  width: 70%;
}

.listing .name-val .avatar-wrap {
  position: absolute;
  top: 50%;
  margin-top: -20px;
}

.listing .name-val span.name-val-side {
  margin-left: 50px;
}

.listing .name-val-less {
  border-top: none;
}

.listing .name-val-less li {
  padding: 15px 0;
}
