.listing-endorsements ul {
  list-style-type: none;
}

.listing-endorsements .body {
  padding-bottom: 0;
  clear: both;
}

.listing-endorsements .sections {
  margin: 0 -20px;
}

.listing-endorsements .sections > li {
  border-top: 1px #eee solid;
}

.listing-endorsements > li:nth-child(even) {
  background-color: white;
}

.listing-endorsements .sections .finance,
.listing-endorsements .sections .itcNoc,
.listing-endorsements .sections .actions {
  padding: 10px 30px;
}

.listing-endorsements .sections .actions {
  background-color: #fafbfc;
  overflow: hidden;
  padding: 0;
}

.listing-endorsements .sections .actions .action-item {
  display: inline-flex;
  padding: 10px 10px 10px 15px;
}

.listing-endorsements .sections .collapsed {
  padding: 0;
}

.listing-endorsements .sections .collapsed,
.listing-endorsements .sections > li:last-child {
  border-bottom: none;
}

.listing-endorsements table {
  margin-bottom: 0;
}

.listing-endorsements table td {
  vertical-align: middle;
}

.listing-endorsements .actions {
  text-align: right;
}

.listing-endorsements .billing-type {
  color: #aaa;
  font-size: 0.8em;
}

.listing-endorsements .finance li {
  width: 24%;
  border-top: none;
}

.listing-endorsements .finance .total label {
  color: #3bb2ed;
}

.listing-endorsements .finance .paid label {
  color: #4ecaab;
}

.listing-endorsements .finance .outstanding label {
  color: #eb5767;
}

/* ========== backout ========== */

.modal-endorsement-backout p {
  line-height: 1.4em;
}

.modal-endorsement-backout .alert {
  margin: 20px 0 0 0;
}

/* ============= form ============ */

.form-endorsements .user-toggle {
  page-break-before: always;
  display: inline-block;
}

.form-endorsements .insured-address .controls .controls,
.form-endorsements .mailing-address .controls .controls {
  margin-left: 0;
}

.form-endorsements .insured-address .controls .control-group,
.form-endorsements .mailing-address .controls .control-group {
  padding-left: 0;
}

.form-endorsements .cancellation-rates-wrap {
  display: block;
  position: relative;
}

/* ============ itc/noc =========== */

.itcNoc .deleted {
  text-decoration: line-through;
}

.itcNoc .circle-icon-tab {
  padding: 5px 6px;
}

.itcNoc td.document {
  white-space: nowrap;
}

/* =============== v3 ================== */

.table-endorsements-v3 .invoice-items li {
  clear: right;
  list-style-type: none;
}

.table-endorsements-v3 .invoice-items label {
  font-weight: normal;
}

.table-endorsements-v3 .invoice-items span {
  float: right;
}

#endorsements {
  position: relative;
}

#endorsements .intab-cancel-wrapper {
  position: absolute;
  top: var(--thin-spacing);
  right: 0;
  z-index: 1;
}
