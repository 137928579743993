
.sidebar textarea::-webkit-input-placeholder,
.sidebar input::-webkit-input-placeholder {
  color: #000;
}

/* =============== sidebar =============== */

.sidebar-toggler .btn {
  height: 20px;
}

.sidebar-toggler .btn i {
  width: 15px;
  margin-top: 3px;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  background-color: #1b212a;
  padding: 10px;
  box-shadow: -2px 0 3px 1px rgba(200, 200, 200, 0.1);
  z-index: 1041;
  transition: 0.5s width;
}

.sidebar.sidebar-not-active {
  display: none;
}

.sidebar.sidebar-hidden {
  width: 0;
  opacity: 0.5;
}

.sidebar.sidebar-hidden:hover {
  width: 5px;
  opacity: 0.8;
}

.sidebar-open {
  transition: margin 0.5s;
}

.sidebar-open .sidebar {
  display: block;
}

.sidebar .panel-pane {
  width: 48%;
  padding: 1%;
  height: auto;
}

.nav-tabs-sidebar {
  list-style-type: none;
  display: inline-block;
  padding: 15px 0 0 10px;
  margin-bottom: 10px;
}

.nav-tabs-sidebar li {
  display: inline-block;
}

.nav-tabs-sidebar li a {
  padding: 10px;
  color: #fff;
  text-decoration: none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  font-size: 0.9em;
}

.nav-tabs-sidebar li.active a,
.nav-tabs-sidebar li a:hover,
.sidebar .tab-content {
  background-color: #2a3342;
}

.sidebar .tab-content {
  border-radius: 5px;
}

.sidebar input[type=text],
.sidebar select,
.sidebar textarea {
  width: 93%;
}

.sidebar .select2-container {
  width: 100%;
}

.sidebar input[type=text],
.sidebar select,
.sidebar .select2-container,
.sidebar textarea {
  margin: 0 0 2% 0;
}

.sidebar .iscroll-loader {
  background-color: #fff;
  height: 25px;
  padding: 0;
  border-radius: 15px;
  margin: 0 auto;
  width: 25px;
  background-position: 0 0;
  opacity: 0.7;
}

.sidebar .tab-content-loading {
  color: #fff;
  background-image: none;
  padding-left: 8px;
  font-weight: normal;
}

.sidebar-action {
  white-space: nowrap;
  display: block;
  font-size: 0.9em;
}

.sidebar-toggle {
  cursor: pointer;
  padding: 10px 6px 10px 9px;
  color: #ababab;
  font-size: 10px;
  background-color: #1b212a;
  position: absolute;
  bottom: 50px;
  left: -25px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.sidebar,
.sidebar .tab-content,
.sidebar .tab-content .tab-pane {
  height: 100%;
}
