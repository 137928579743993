.modal-large {
  width: 940px;
  margin-left: -470px;
}

.modal-extra-large {
  width: 90%;
  margin-left: -46%;
}

.modal-header {
  padding: 1rem;
}

.modal-no-header .modal-header {
  display: none;
}

.modal-header h2,
.modal-header h3 {
  border-bottom: none;
  padding: 0;
  margin: 0;
  max-width: 90%;
}

.modal-header button {
  border: none;
  background: none;
}

.modal-body h2,
.modal-body h3 {
  font-size: 1.2em;
  border-width: 1px;
}

.modal-application-status .modal-body,
.modal-checklist-history .modal-body,
.modal-policy-backout .modal-body,
.modal-activity-new .modal-body {
  margin: 20px 20px 0 20px;
}

.modal-claims .modal-body {
  padding: 10px 20px 20px 0;
}

.modal-transfer-user .modal-body,
.modal-carrier-templates .modal-body,
.modal-itc-noc-issue .modal-body,
.modal-insured-new .modal-body,
.modal-task-new .modal-body,
.modal-tasks .panel-pane-task .form-modal {
  margin: 20px 0 20px 0;
}

.modal-agency-activity-details .modal-body {
  padding-left: 20px;
}

.modal-campaign-created .modal-body,
.modal-top-producer .modal-body {
  text-align: center;
  margin: 0 0 20px 0;
}

.modal-campaign-created p,
.modal-top-producer p {
  margin: 20px auto;
}

.modal-carrier-pre-bind .modal-body,
.modal-send-files-to-carrier .modal-body,
.modal-quote-commission .modal-body,
.modal-endorsement-backout .modal-body {
  margin: 20px;
}

.modal-history-edit .modal-body,
.modal-invoice-payment-new .modal-body,
.modal-task-show .modal-body,
.modal-insured-address .modal-body,
.modal-add-hazard-tip .modal-body {
  margin: 20px 0 0 0;
}

.modal-body-padded .modal-body p {
  padding: 20px 20px 0 20px;
}

.modal-body-padded .modal-body li {
  padding: 10px 20px;
}

.modal-body-padded .modal-body {
  padding-bottom: 20px;
}

.modal-send-files-to-carrier .control-group {
  margin-top: 20px;
}

.modal-application-status p {
  margin: 20px;
}

.modal-reinstate p {
  margin: 20px 0 0 20px;
}

.modal-open {
  height: 100%;
  overflow: hidden;
}

/* ======== modal panels ======== */

.modal-panel .modal-body {
  overflow: hidden;
}

.modal-panel .panel,
.modal-panel .panel-inner {
  height: 100%;
}

/* ====== file inputs ========= */

.modal .file-inputs .file {
  border-top: 1px #eee solid;
  padding: 10px;
}

.modal .file-inputs .file input {
  background: none;
}

.modal .file-inputs .file:last-child {
  border-bottom: 1px #eee solid;
  margin-bottom: 20px;
}

/* ========== sweetalert ============ */

.sweet-alert {
  padding: 0;
}

.sweet-alert p {
  padding: 10px 20px 20px;
}

.sweet-alert h2 {
  margin: 0;
  border-bottom: none;
  padding: 10px;
  font-size: 1.5em;
  line-height: 1.3em;
}

.sa-button-container {
  background-color: #f5f5f5;
  text-align: right;
}

.sa-button-container button.confirm {
  background-color: #3ab1eb !important;
}

.sa-button-container button {
  margin: 15px;
  padding: 5px 15px;
  font-size: 1em;
}

.sa-button-container button:hover {
  background: #2d99cf !important;
}

.sa-input-error {
  display: none;
}

/* ====== modal lists ========= */

.modal .modal-list {
  padding: 20px;
}

/* ======== modal name-val ========== */

.modal-body .name-val {
  padding: 0 30px;
  list-style-type: none;
}

.modal-body .name-val > li > label,
.modal-body .name-val > li > span {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
  width: calc(100% - 85px);
}

.modal-body .name-val > li:last-child > label,
.modal-body .name-val > li:last-child > span {
  margin-bottom: 0;
}

.modal-body .name-val > li > label {
  font-weight: bold;
  width: 80px;
}

/* =============== send to market ================ */

.modal-send-files-to-market .form-inline {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-send-files-to-market .row-headers {
  font-weight: bold;
}

.modal-send-files-to-market .collection-item {
  margin-bottom: 15px;
}

.modal-send-files-to-market .collection-item:last-child {
  margin-bottom: 0;
}

.modal-send-files-to-market .email-template-list,
.modal-send-files-to-market .subject,
.modal-send-files-to-market .message {
  margin: 0 0 20px 20px;
}

.modal-send-files-to-market .message {
  height: 200px;
}

.modal-send-files-to-market p {
  margin: 20px;
}

/* =============== Tasks Modal ================ */
.form-task.form-modal .col-md-6 {
  max-width: 100%;
  flex: 100%;
}

.form-task.form-modal .col-md-3,
.form-task.form-modal .col-xl-3 {
  max-width: 50%;
  flex: 50%;
}

.form-task.form-modal .control-group {
  padding-left: 0 !important;
}
