
.report .downloads {
  float: right;
  margin: 30px 20px;
}

.report .downloads a {
  color: #000;
}

/* ============ production report ======== */

.monthly-production form,
.production form {
  float: right;
  text-align: right;
  margin: 20px 30px;
  white-space: nowrap;
}

.monthly-production .control-group,
.production .control-group {
  margin-bottom: 0;
}

.monthly-production form input[type=submit],
.production form input[type=submit] {
  margin-left: 5px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.monthly-production tbody tr:hover,
.monthly-production tbody tr:hover td,
.production tbody tr:hover,
.production tbody tr:hover td {
  background-color: #fbf5e8;
}

.production-fullscreen {
  background-color: #fff;
  background-image: none;
  padding: 20px;
  margin-top: 0;
}

.production-fullscreen-link {
  text-align: center;
  font-size: 0.8em;
}

.production .weekly {
  text-align: right;
  font-weight: normal;
  font-style: italic;
}

.production th,
.production .label {
  font-weight: bold;
}

.production .header {
  white-space: nowrap;
}

.production .label {
  text-align: right;
}

.production .apps,
.production .quotes,
.production .binders,
.production .new-ren,
.production .day,
.production .total {
  text-align: center;
}

.production .day {
  width: 6%;
}

.production .day,
.production .total {
  text-align: right;
}

.production tbody .mon,
.production tbody .wed,
.production tbody .fri {
  background-color: #eee;
}

.production .months {
  font-size: 1.5em;
  padding: 15px 20px 25px 20px;
}

.production .months .current {
  float: right;
  text-align: right;
}

.production .mon {
  border-left: 1px #ccc solid;
}

.production .producer {
  white-space: nowrap;
  overflow: hidden;
  -webkit-mask-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
}

.production .header.producer {
  padding-left: 15px;
}

.production .producer .avatar {
  margin-right: 3px;
}

.production .producer,
.production .friday {
  border-right: 1px #ccc solid;
}

.production .average {
  margin-top: 10px;
  font-size: 0.8em;
}

.production th.new-ren {
  font-size: 0.8em;
  min-width: 20px;
}

/* =========== custom report index view =========== */

.production-report-list li {
  list-style: none;
}

.production-report-list li form {
  display: inline-block;
}

/* =========== organisation overview =========== */

.production-overview-filter .imported-data-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
}

.production-overview-filter .imported-data-description {
  font-size: 0.8em;
}

.production-overview-filter hr {
  margin: 30px 0 20px 0;
}

.production-overview .division-toggle {
  text-align: right;
  padding: 0 20px 20px;
}

.production-overview .division-toggle label {
  vertical-align: bottom;
}

.production-overview .division-toggle span {
  display: inline-block;
  margin-left: 10px;
}

.production-overview .report-csv-link {
  margin: 15px;
}

.production-overview {
  margin-top: 10px;
  background-color: #fff;
  padding: 5px;
}

.production-overview th,
.production-overview td {
  font-size: 0.8em;
}

.production-overview thead {
  margin-bottom: 10px;
}

.production-overview thead th,
.production-overview thead td {
  border: none;
  padding: 5px;
  font-weight: bold;
  text-align: right;
}

.production-overview thead .group {
  text-align: center;
}

.production-overview tbody {
  margin-bottom: 20px;
}

.production-overview tbody:last-child {
  border-bottom: none;
}

.production-overview tbody td,
.production-overview tfoot td {
  text-align: right;
}

.production-overview tbody .running-totals td,
.production-overview tfoot .row-totals td {
  font-weight: bold;
  text-align: right;
}

.production-overview tbody .running-totals td:first-child,
.production-overview tfoot .row-totals td:first-child {
  text-align: left;
}

.production-overview .row-group td,
.production-overview .row-totals td,
.production-overview .col-month,
.production-overview .col-recruiting {
  background-color: #f9f9f9;
}

.production-overview .group-name {
  text-align: left;
}

.production-overview .group-item .producer-name {
  padding-left: 30px;
}

.production-overview .group-item .producer-name::after {
  content: "◀";
  color: #bbb;
}

.production-overview [data-toggle] {
  cursor: pointer;
}

.production-overview .col-hidden {
  display: none !important;
}

.production-overview .special-row {
  border-top: 2px #aaa solid;
  border-bottom: 2px #aaa solid;
}

.production-overview .separation-row {
  border-right: 2px solid #aaa;
}

td .currency {
  text-align: right;
}

td .producer-name {
  text-align: left;
}

.production-overview .scroller-table {
  background-color: #fff;
}

.production-overview .scroller-table td {
  padding-top: 10px;
}

.production-overview .scroller-table tr:last-child td {
  padding-top: 14px;
  padding-bottom: 10px;
  border-bottom: 1px #ddd solid;
}

/* =========== producer revenue report ============== */

.table-producer-revenue thead th,
.table-producer-revenue tbody th {
  font-weight: bold;
}

.table-producer-revenue-by-user {
  margin-left: -30px;
}

.table-producer-revenue-by-user table thead th {
  color: #fff;
  border-color: #fff;
  text-transform: none;
}

.table-producer-revenue-by-user table tbody {
  visibility: hidden;
}

.table-producer-revenue-by-user td,
.table-producer-revenue-by-user th {
  padding-left: 0;
  padding-right: 0;
}

.table-producer-revenue td.no-padded {
  padding: 0;
}

.table-producer-revenue-by-user th {
  padding-right: 15px;
  font-weight: bold;
}

.table-producer-revenue-by-user .legend {
  list-style-type: none;
}

.table-producer-revenue-by-user .legend label {
  vertical-align: top;
  margin: 5px 0 0 5px;
  display: inline-block;
}

.table-producer-revenue-by-user {
  height: 300px;
}

.producer-revenue-filter .standard .control-group,
.producer-revenue-filter .standard .controls,
.information-return-filter .standard .control-group,
.information-return-filter .standard .controls,
.agency-commission-filter .standard .control-group,
.agency-commission-filter .standard .controls {
  display: inline-block;
}

.table-producer-revenue .inactive a {
  text-decoration: line-through;
}

/* ================== Information return ================ */

.information-return-filter .entity-type,
.information-return-filter .year {
  min-width: 150px;
}

/* ================== policy audit ================ */

.policy-audit .missing-item {
  margin-bottom: 5px;
}

.policy-audit .avatar {
  border-radius: 2px;
}

/* ============== issuance report ============= */

.issuance-report .select2-container {
  width: 70%;
}

.report-issuance .policy-users {
  list-style-type: none;
}

.report-issuance .policy-user {
  white-space: nowrap;
  margin-bottom: 5px;
}

.report-issuance .policy-user:last-child {
  margin-bottom: 0;
}

.report-issuance .policy-users .avatar-wrap,
.report-issuance .policy-users .name {
  display: inline-block;
  vertical-align: middle;
}

.report-issuance .policy-users .avatar {
  border-radius: 2px;
}

.report-issuance td {
  vertical-align: top;
}

/* ============== campaign report ============= */

.campaign-chart canvas {
  width: 180px;
  height: 90px;
}

/* ============ business plans =========== */

.report-business-plan h3 {
  margin: 0 0 20px 0;
}

.report-business-plan .freetext {
  margin-bottom: 20px;
}

.report-business-plan .alert {
  margin-top: 0 !important;
}

.report-business-plan .action-drag {
  display: none;
}

.report-business-plan .no-results {
  padding: 0;
}

.report-business-plan h2,
.report-business-plans h2 {
  margin-left: 20px;
}

.table-business-plans .details-container {
  padding: 0;
}

.table-business-plans .details {
  height: 0;
  overflow: hidden;
}

.table-business-plans .details-expanded .icon-chevron-right {
  transform: rotate(90deg);
}

.table-business-plans .details-expanded + tr .details {
  height: auto;
  margin-bottom: 15px;
}

.table-business-plans h3 {
  border: none;
  font-weight: bold;
  margin: 10px 0;
  font-size: 1em;
}

.table-business-plans .icon-chevron-right {
  margin-right: 8px;
}

.table-business-plans-monthly {
  width: 100%;
  margin-bottom: 5px;
}

.report-business-plan th,
.report-business-plan thead th,
.report-business-plan td {
  text-align: right;
}

.report-business-plan th:first-child,
.report-business-plan thead th:first-child,
.report-business-plan td:first-child {
  text-align: left;
}

.report-business-plans-summary td {
  font-size: 0.8em;
}

.report-business-plans-summary td:first-child {
  font-size: 1em;
}

.report-business-plan .conversation-messages {
  margin: 0 20px;
}

.report-business-plan .form-business-plan-conversation-message .no-results-conversation {
  padding: 20px 40px;
  line-height: 1.4em;
}

.report-business-plan .form-business-plan-conversation-message h3 {
  border: none;
  font-size: 1.3em;
  margin: 0 0 20px 0;
  padding: 0;
}

.report-business-plan .form-business-plan-conversation-message textarea {
  width: 100%;
  max-width: calc(100% - 20px);
  margin-bottom: 20px;
}

.report-business-plans .business-plan-sub-row-data .table-business-plans-monthly td {
  padding: 10px;
}

.report-business-plans .business-plan-sub-row-data .table-business-plans-monthly tr:last-child {
  border-bottom: none;
}

.report-business-plans .business-plan-sub-row-data .col-4,
.report-business-plans .business-plan-sub-row-data .span4 {
  padding: 10px 15px;
}

/* ============ carrier ranking ============ */

.table-carrier-ranking thead th,
.table-carrier-ranking td {
  text-align: right;
}

.table-carrier-ranking thead tr.pre-header th {
  text-align: left;
}

.table-carrier-ranking thead th:first-child,
.table-carrier-ranking td:first-child {
  text-align: left;
}

.table-carrier-ranking .avatar-wrap {
  vertical-align: middle;
  margin-right: 10px;
}

.table-carrier-ranking .avatar {
  border-radius: 3px;
}

/* ================= expense reports ===================== */

.form-policy-dumper .text-error {
  color: #eb5767;
}

/* ================ check register =================== */

.cheque-register-report .red-tick {
  color: #c33;
}

/* ================ sla by state =================== */

.table-sla-by-state .totals {
  font-weight: bold;
}

/* ================ sla batch ================== */

.form-sla-batch-edit .datepicker input {
  min-width: 30%;
  padding: 2px 8px;
}

.form-sla-batch-edit .datepicker .btn {
  padding: 5px;
}

.form-sla-batch-edit .datepicker .clear {
  top: -2px;
}

/* ============ audit report ======== */

td.endorsement-item-header {
  padding: 20px;
}

/* ============ line of business report ======== */

.lob-report-table a .fa,
.lob-report-table a .fa + span {
  color: #3ab1eb;
}

/* ========== Responsive ============= */

@media (max-width: 767px) {
  .production-overview {
    overflow: scroll;
  }

  .monthly-production form,
  .production form {
    float: left;
  }
}

/* ============== agency activity overview ============= */

.report-agency-activity-overview .avatar-wrap + a {
  margin-top: -10px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
