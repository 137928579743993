
.alert {
  padding: 15px;
  margin-bottom: 25px;
  border-radius: 5px;
  color: #fff;
  line-height: 1.4em;
}

.alert a,
.alert button.text {
  color: currentColor;
  text-decoration: underline;
  line-height: 1.4em;
  font-weight: var(--font-weight);
  font-size: var(--font-size);
}

.alert button.text {
  padding: 0;
  margin: 0;
}

.alert a:hover {
  color: currentColor;
  text-decoration: none;
}

.alert button {
  border: none;
  background: none;
  margin-top: -4px;
}

.alert .sr-only {
  display: none;
}

.alert-success {
  background: #4ec9ab;
}

.alert-success-soft {
  color: var(--font-color);
  background: #c3eaab;
  border: 1px solid var(--success-color);
}

.alert-error {
  background: var(--danger-dark);
}

.alert-danger {
  color: var(--font-color);
  border: 1px solid var(--danger-dark);
  background: var(--danger-light);
}

.alert-pre-bind,
.alert-info {
  background: var(--primary-color);
}

.callout {
  padding: 15px;
}

.callout-info {
  background-color: #f0f8fc;
  border: 1px #bee5eb solid;
  border-radius: 5px;
  color: #000;
}

.callout-code {
  background-color: #f9fafb;
  border: 1px #d0d5d9 solid;
  border-radius: 5px;
  color: #000;
}

.alert-info-soft {
  color: var(--font-color);
  background-color: var(--blue-lighter);
  border: 1px solid var(--primary-color);
}

.alert-warning {
  background-color: #e69208;
}

.alert-pre-bind {
  margin: 0 20px 20px 20px;
}

.alert-warning-soft {
  color: var(--font-color);
  background-color: #fff3cd;
  border: 1px solid #e69208;
}

.alert-error .text-error {
  color: #fff;
}

.alert.fade {
  display: none;
}

.alert.fade.in {
  display: block;
}

.alert-placeholder {
  display: inline-block;
}

.modal .alert-placeholder {
  float: left;
}

.alert-placeholder .alert {
  padding: 12px;
}

.modal .alert-placeholder .alert {
  margin-bottom: 0;
}

.alert-dismissible {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  border-radius: 0;
}

.alert ul li {
  margin-left: 20px;
  margin-bottom: 3px;
}

.alert-cg {
  margin: 0 0 0 75px !important;
}
