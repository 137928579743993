
.underwriting {
  padding: 0 20px 0 20px;
  width: calc(100% - 42px);
}

.underwriting .widget {
  border-radius: 0;
}

.underwriting .widget .alert {
  margin: 0;
}

.underwriting .widget .no-results {
  padding: 0;
  font-size: 1em;
}

.underwriting .widget .title .content {
  padding: 0 0 10px;
  border-bottom: 1px #eee solid;
}

.underwriting .widget .title .inner {
  border-left: 3px #eb5767 solid;
  padding-left: 10px;
}

.underwriting .widget .body .content {
  height: auto;
}

/* ======= map ========= */

.underwriting .alert-location {
  border: 1px #eb5767 solid;
  line-height: 400px;
  text-align: center;
  color: #000;
}

.underwriting .alert-location .address {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.4em;
}

.underwriting .widget-map {
  overflow: hidden;
  position: relative;
}

.underwriting .widget-map .title {
  display: none;
}

.underwriting .widget-map .map {
  width: 100%;
  height: 400px;
  z-index: 0;
}

.underwriting .widget-map .btns {
  border-top: 1px #e69208 solid;
}

.underwriting .widget-map .btn {
  border-radius: 0;
  width: calc(50% - 30px);
  text-align: center;
}

.underwriting .widget-map .btn-selected { background: #ffab21; }
.underwriting .widget-map .btn-selected:hover { background: #e69208; }

.underwriting .widget-map .edit a {
  font-weight: normal;
}

/* ========== documents ============= */

.underwriting .widget-documents {
  position: relative;
}

.underwriting .widget-documents .loader .inner-partial {
  margin-top: 60px;
}

.underwriting .widget-documents .viewer-wrap {
  width: 100%;
  height: 440px;
}

.underwriting .widget-documents .viewer-wrap-hidden {
  display: none;
}

.underwriting .widget-documents .viewer {
  width: 100%;
  height: 100%;
}

.underwriting .widget-documents .download {
  height: 100%;
  background-color: #eee;
}

.underwriting .widget-documents .download .filename {
  border: none;
  padding: 0;
  max-width: calc(100% - 300px);
  position: absolute;
  top: 0;
  left: 0;
  word-break: break-all;
}

.underwriting .widget-documents .no-results,
.underwriting .widget-documents .download .message {
  text-align: center;
}

.underwriting .widget-documents .download .message {
  line-height: 500px;
}

.underwriting .widget-documents .no-results {
  font-size: 1.2em;
  line-height: 400px;
}

.underwriting .widget-documents .selector {
  position: absolute;
  top: 10px;
  right: 10px;
}

.underwriting .widget-documents .selector-single {
  display: none;
}

.underwriting .widget-documents .select2-container {
  box-shadow: rgba(0, 0, 0, 0.4) 4px 4px 4px;
}

/* ========== suggestions ========== */

.underwriting .widget-suggestions .title .inner {
  border-color: #3bb2ed;
}

.underwriting .widget-suggestions .body .inner {
  padding: 10px 0;
}

.underwriting .widget-suggestions ul {
  display: table;
  width: 100%;
}

.underwriting .widget-suggestions li {
  display: table-row;
}

.underwriting .widget-suggestions .cell {
  display: table-cell;
  padding: 5px 0;
}

.underwriting .widget-suggestions .cell-inner {
  padding: 5px 0;
}

.underwriting .widget-suggestions li .cell:last-child {
  text-align: right;
}

.underwriting .widget-suggestions li:first-child .cell {
  padding-top: 0;
}

.underwriting .widget-suggestions li:last-child .cell {
  padding-bottom: 0;
}

.underwriting .widget-suggestions li a {
  font-weight: normal;
}

.underwriting .widget-suggestions .btn-rating-error {
  background: #eb5767;
}

/* ========== documents ========== */

.underwriting .widget-guidelines .title .inner {
  border-color: #b357b6;
}

.underwriting .widget-guidelines .body .inner {
  padding: 20px 0;
}

.underwriting .widget-guidelines .carriers,
.underwriting .widget-guidelines .carrier-files {
  list-style-type: none;
}

.underwriting .widget-guidelines .carrier {
  margin-bottom: 20px;
}

.underwriting .widget-guidelines .carrier:last-child {
  margin-bottom: 0;
}

.underwriting .widget-guidelines .carrier-files {
  margin: 20px 0 0 0;
}

.underwriting .widget-guidelines .carrier-file {
  margin-bottom: 10px;
  padding-left: 20px;
  background-image: url(../img/pdf-small.png);
  background-repeat: no-repeat;
}

.underwriting .widget-guidelines .carrier-file:last-child {
  margin-bottom: 0;
}

.underwriting .widget-guidelines .carrier-file a {
  white-space: nowrap;
  font-weight: normal;
}

/* ============= tips ============== */

.underwriting .widget-hazard-tips .no-results {
  margin: 20px;
}

.underwriting .widget-hazard-tips .tips {
  list-style-type: none;
}

.underwriting .widget-hazard-tips .tip {
  margin: 20px 0;
  border-bottom: 1px #eee solid;
  padding-bottom: 20px;
}

.underwriting .widget-hazard-tips .new-tip {
  color: #2c99d0;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}

.underwriting .widget-hazard-tips .tip-user-name {
  display: inline-block;
}

.underwriting .widget-hazard-tips .avatar-wrap {
  vertical-align: middle;
  line-height: 0px;
  margin-right: 8px;
  background-color: #ddd;
  min-width: 20px;
  min-height: 20px;
  border-radius: 2px;
}

.underwriting .widget-hazard-tips .avatar {
  border-radius: 2px;
}

.underwriting .widget-hazard-tips .tip-content {
  margin-top: 10px;
  font-style: italic;
  word-break: break-word;
}

/* ============== loss ratios ============ */

.underwriting .widget-loss-ratios .title .inner {
  border-color: #ccc;
}

.underwriting .widget-loss-ratios .body .content {
  padding: 20px 0;
  font-style: italic;
}
